import { FC, useMemo } from "react";
import { Box, useTheme, useMediaQuery, styled } from "@mui/material";
import { SignalGraph } from "./Graph";

import "../../i18n/i18n";
import Banner from "./components/Banner/Banner";
import HeaderControls from "./components/Controls/HeaderControls";
import useStore from "./store/store";


export interface ServerToken {
  userInfo?: any;
  setToken?: any;
}

export const Header = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderBottom: `1px solid ${theme.palette.divider}`,
  zIndex: theme.zIndex.drawer + 3,
  [theme.breakpoints.down("sm")]: {
    padding: theme.spacing(1),
  },
}));

//  userInfo, setToken unuse
export const SignalGraphWrapper: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const players = useStore((state) => state.players);
  const containerStyles = useMemo(
    () => ({
      height: isMobile ? "calc(100dvh - 40px)" : "calc(100vh - 40px)",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      overflow: "hidden",
      position: "relative",
    }),
    [isMobile]
  );

  const graphContainerHeight = useMemo(() => {
    const bannerHeight = 24;
    const headerHeight = isMobile ? 48 : 64;
    const totalOffset = bannerHeight + headerHeight;

    return isMobile
      ? `calc(100dvh - ${totalOffset + 40}px)`
      : `calc(100vh - ${totalOffset + 40}px)`;
  }, [isMobile]);

  return (
    <Box sx={containerStyles}>
      <Banner />

      <Header>
        <HeaderControls isMobile={isMobile} />
      </Header>

      <Box
        sx={{
          flex: 1,
          position: "relative",
          height: graphContainerHeight,
          overflow: "hidden",
        }}
      >
        {players && <SignalGraph />}
      </Box>
    </Box>
  );
};

export default SignalGraphWrapper;
