// store.ts
import { create } from "zustand";
import { TennisStore, VideoDataStructure } from "./Type";
import { API_BASE_URL } from "../config";
import axios from "axios";

const useStore = create<TennisStore>((set, get) => ({
  // Initial state
  categories: [],
  currentCategory: null,
  players: {},
  categoryData: {},
  firstPlayer: null,
  secondPlayer: null,
  thirdPlayer: null,
  nodeDepth: 1,
  isShortestPath: false,
  paths: [],
  reachablePlayers: {},
  isLoading: false,
  error: null,
  selectedGame: null,
  selectedGameInfo: null,
  isDebugMode: true, // Added debug mode flag

  gameInfoCache: {} as Record<string, any>,

  // Debug mode toggle
  setDebugMode: (enabled: boolean) => set({ isDebugMode: enabled }),

  // Fetch categories with debug
  fetchCategories: async () => {
    const { isDebugMode } = get();
    try {
      set({ isLoading: true, error: null });
      const response = await fetch(`${API_BASE_URL}/api/categories`);
      const data = await response.json();

      if (isDebugMode) {
        console.log("Fetched categories:", data);
      }

      set({
        categories: Object.values(data.categories),
        isLoading: false,
      });
    } catch (error) {
      set({
        error: "Failed to fetch categories",
        isLoading: false,
      });
      console.error("Failed to fetch categories:", error);
    }
  },

  // Set current category with debug
  setCurrentCategory: async (category: string) => {
    const { isDebugMode } = get();
    set({ currentCategory: category, isLoading: true, error: null });

    if (isDebugMode) {
      console.log("Setting current category:", category);
    }

    try {
      const response = await fetch(
        `${API_BASE_URL}/api/categories/${category}/players`
      );
      const data = await response.json();

      if (isDebugMode) {
        console.log("Fetched players for category:", data);
      }

      set((state) => ({
        players: {
          ...state.players,
          [category]: data.players,
        },
        firstPlayer: null,
        secondPlayer: null,
        thirdPlayer: null,
        isLoading: false,
      }));
    } catch (error) {
      set({
        error: "Failed to fetch players",
        isLoading: false,
      });
      console.error("Failed to fetch players:", error);
    }
  },

  // Player selection actions with debug and auto-fetch
  setFirstPlayer: (playerId) => {
    const { isDebugMode } = get();
    if (isDebugMode) {
      console.log("Setting first player:", playerId);
    }

    set({
      firstPlayer: playerId,
      isLoading: true, // Set loading when changing player
    });

    if (playerId) {
      get().fetchReachablePlayers();
    } else {
      // Reset relevant state when player is removed
      set({
        reachablePlayers: {},
        paths: [],
        isLoading: false,
      });
    }
  },

  setSecondPlayer: (playerId) => {
    const { isDebugMode } = get();
    if (isDebugMode) {
      console.log("Setting second player:", playerId);
    }
    set({ secondPlayer: playerId });
  },

  setThirdPlayer: (playerId) => {
    const { isDebugMode } = get();
    if (isDebugMode) {
      console.log("Setting third player:", playerId);
    }
    set({ thirdPlayer: playerId });
  },

  // Settings actions with debug
  // Update setNodeDepth to include loading:
  setNodeDepth: (depth) => {
    const { isDebugMode } = get();
    if (isDebugMode) {
      console.log("Setting node depth:", depth);
    }

    set({
      nodeDepth: depth,
      isLoading: true, // Start loading
    });

    // Get current state
    const { firstPlayer, currentCategory } = get();

    if (firstPlayer && currentCategory) {
      // Only update if we have required data
      get()
        .fetchReachablePlayers()
        .finally(() => {
          set({ isLoading: false }); // End loading after fetch completes
        });
    } else {
      // If no player/category selected, just end loading
      set({ isLoading: false });
    }
  },

  setIsShortestPath: (isShortestPath) => {
    const { isDebugMode } = get();
    if (isDebugMode) {
      console.log("Setting shortest path:", isShortestPath);
    }
    set({ isShortestPath });
  },

  // Modify setSelectedGame to use cached data
  setSelectedGame: async (gameId: number | null) => {
    const { isDebugMode, gameInfoCache } = get();
    if (isDebugMode) {
      console.log("Setting selected game:", gameId);
    }

    set({ selectedGame: gameId });

    if (gameId) {
      const gameInfo =
        gameInfoCache[gameId] ||
        (await get().fetchGameInfo?.(gameId.toString()));
      set({ selectedGameInfo: gameInfo });
    }
  },
  // Modify fetchGameInfo to use cache
  fetchGameInfo: async (gameId: string) => {
    const { isDebugMode, gameInfoCache } = get();

    // Check cache first
    if (gameInfoCache[gameId]) {
      if (isDebugMode) {
        console.log("Using cached game info for:", gameId);
      }
      return gameInfoCache[gameId];
    }

    if (isDebugMode) {
      console.log("Fetching game info for:", gameId);
    }

    try {
      set({ isLoading: true, error: null });
      const response = await axios.get(`/api/videodata/${gameId}`);
      const data: VideoDataStructure = response.data;

      const gameInfo = {
        gameId: gameId,
        metadata: {
          filename: data.metadata.filename,
          eventName: data.metadata.eventName,
          finalscore1: data.metadata.finalscore1,
          finalscore2: data.metadata.finalscore2,
          id: data.metadata.id,
          location: data.metadata.location,
          round: data.metadata.round,
          team1: data.metadata.team1,
          team2: data.metadata.team2,
          date: data.metadata.date,
        },
        gameScores: data.games.map((game) => ({
          gameNumber: game?.gameInfo?.gameNumber ?? -1,
          finalTeam1Score:
            game?.points?.[game.points.length - 1]?.team1Score ?? null,
          finalTeam2Score:
            game?.points?.[game.points.length - 1]?.team2Score ?? null,
        })),
        players: [],
      };

      // Update cache
      set((state) => ({
        gameInfoCache: {
          ...state.gameInfoCache,
          [gameId]: gameInfo,
        },
        isLoading: false,
      }));

      return gameInfo;
    } catch (error) {
      console.error("Failed to fetch game info:", error);

      if (isDebugMode) {
        console.log("Using dummy data due to fetch failure");
      }

      const dummyWithId = {
        ...dummyGameInfo,
        gameId: gameId,
        metadata: {
          ...dummyGameInfo.metadata,
          id: Number(gameId),
        },
      };

      // Cache dummy data too
      set((state) => ({
        gameInfoCache: {
          ...state.gameInfoCache,
          [gameId]: dummyWithId,
        },
        error: "Failed to fetch game info - using dummy data",
        isLoading: false,
      }));

      return dummyWithId;
    }
  },

  setSelectedGameInfo: (gameInfo: any) => {
    const { isDebugMode } = get();
    if (isDebugMode) {
      console.log("Setting game info:", gameInfo);
    }
    // ONLY update selectedGameInfo, don't trigger other updates
    set((state) => ({
      selectedGameInfo: gameInfo,
      // Don't update any other state!
    }));
  },

  clearSelectedGameInfo: () => {
    const { isDebugMode } = get();
    if (isDebugMode) {
      console.log("Clearing game info");
    }
    // Only clear game info related state
    set((state) => ({
      selectedGameInfo: null,
      selectedGame: null,
    }));
  },
  // Fetch reachable players with debug

  fetchReachablePlayers: async () => {
    const { currentCategory, firstPlayer, isDebugMode } = get();
    if (!currentCategory || !firstPlayer) return;

    if (isDebugMode) {
      console.log("Fetching reachable players for:", {
        currentCategory,
        firstPlayer,
      });
    }

    try {
      set({ isLoading: true, error: null });
      const response = await fetch(
        `${API_BASE_URL}/api/players/${currentCategory}/${firstPlayer}/reachable`
      );
      const data = await response.json();

      if (isDebugMode) {
        console.log("Fetched reachable players:", data);
      }

      set((state) => ({
        reachablePlayers: {
          ...state.reachablePlayers,
          [currentCategory]: {
            ...state.reachablePlayers[currentCategory],
            [firstPlayer]: data, // Store the complete response as is
          },
        },
        isLoading: false,
      }));
    } catch (error) {
      set({
        error: "Failed to fetch reachable players",
        isLoading: false,
      });
      console.error("Failed to fetch reachable players:", error);
    }
  },

  // Fetch paths with debug
  fetchPlayerPaths: async () => {
    const {
      currentCategory,
      firstPlayer,
      secondPlayer,
      isShortestPath,
      isDebugMode,
    } = get();
    if (!currentCategory || !firstPlayer || !secondPlayer) return;

    if (isDebugMode) {
      console.log("Fetching paths:", {
        currentCategory,
        firstPlayer,
        secondPlayer,
        isShortestPath,
      });
    }

    try {
      set({ isLoading: true, error: null });
      const endpoint = `${API_BASE_URL}/api/players/${currentCategory}/${firstPlayer}/reachable/${secondPlayer}`;

      const response = await fetch(endpoint);
      const data = await response.json();

      if (isDebugMode) {
        console.log("Fetched paths:", data);
      }

      set({
        paths: data.paths || [],
        isLoading: false,
      });
    } catch (error) {
      set({
        error: "Failed to fetch paths",
        isLoading: false,
      });
      console.error("Failed to fetch paths:", error);
    }
  },
}));

// Dummy data for fallback
const dummyGameInfo = {
  gameId: "0",
  metadata: {
    filename: "dummy_match",
    eventName: "Dummy Tournament",
    finalscore1: 3,
    finalscore2: 2,
    id: 0,
    location: "Unknown Location",
    round: "Sample Round",
    team1: "Team A",
    team2: "Team B",
    date: "2024-01-01",
  },
  gameScores: [
    {
      gameNumber: 1,
      finalTeam1Score: 11,
      finalTeam2Score: 9,
    },
    {
      gameNumber: 2,
      finalTeam1Score: 11,
      finalTeam2Score: 7,
    },
    {
      gameNumber: 3,
      finalTeam1Score: 9,
      finalTeam2Score: 11,
    },
    {
      gameNumber: 4,
      finalTeam1Score: 11,
      finalTeam2Score: 8,
    },
    {
      gameNumber: 5,
      finalTeam1Score: 11,
      finalTeam2Score: 6,
    },
  ],
  players: [],
};

export default useStore;
