// VideoScore.js
import { Box, Typography } from '@mui/material';

export const VideoScore = ({ homeScore, awayScore }) => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      flexShrink: 0,
      minWidth: '120px',
    }}
  >
    <Typography variant="h6" sx={{ fontWeight: 'bold', mr: 1 }}>
      {homeScore}
    </Typography>
    <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
      :
    </Typography>
    <Typography variant="h6" sx={{ fontWeight: 'bold', ml: 1 }}>
      {awayScore}
    </Typography>
  </Box>
);
