// VideoDetails.js
import { useState } from 'react';
import {
  Typography,
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const VideoDetails = ({ videoData }) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <Accordion
      expanded={expanded}
      onChange={(_, isExpanded) => setExpanded(isExpanded)}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
          overflow: 'hidden',
        }}
      >
        <Typography variant="body2">更多詳情</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Box sx={{ width: '100%', overflow: 'hidden' }}>
          <Typography variant="body2" sx={{ mb: 0.5 }}>
            影片: {videoData.homePlayer} vs {videoData.awayPlayer} (
            {videoData.date})
          </Typography>
          <Typography variant="body2" sx={{ mb: 0.5 }}>
            觀看次數: {videoData.viewCount}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
            }}
          >
            <Typography variant="body2">階段: {videoData.stage}</Typography>
            <Typography variant="body2">地點: {videoData.location}</Typography>
            <Typography variant="body2">賽事: {videoData.subEvent}</Typography>
            <Typography variant="body2">賽項: {videoData.category}</Typography>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
