import React, { useState } from 'react';
import {
    Box,
    Chip,
    Typography,
    IconButton,
    Paper,
    Stack,
    styled,
    Collapse
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

interface FilterCriteria {
    matchName: string;
    matchDate: string;
    stages: string[];
    location: string;
    events: string[];
    categories: string[];
    startDate: string;
    endDate: string;
    customTags: string[];
}

interface TranslationDictionary {
    [key: string]: string;
}

interface ActiveFiltersProps {
    filterCriteria: FilterCriteria;
    setFilterCriteria: React.Dispatch<React.SetStateAction<FilterCriteria>>;
}

const StyledChip = styled(Chip)(({ theme }) => ({
    margin: theme.spacing(0.5),
    '& .MuiChip-label': {
        display: 'flex',
        alignItems: 'center',
        gap: theme.spacing(0.5)
    }
}));

const HeaderBox = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1, 2),
    borderBottom: `1px solid ${theme.palette.divider}`,
    backgroundColor: theme.palette.background.paper,
    cursor: 'pointer'
}));

const ChipsContainer = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(1.5),
    gap: theme.spacing(0.5)
}));

const translationDict: TranslationDictionary = {
    Final: '決賽',
    'Semi-Final': '半決賽',
    'Quarter-Final': '4強',
    R16: '16強',
    R32: '32強',
    R64: '64強',
    R128: '128強',
    MSingles: '男單打',
    MDouble: '男雙打',
    FSingle: '女單打',
    FDouble: '女雙打',
    MixedDouble: '混合雙打',
};

const ActiveFilters: React.FC<ActiveFiltersProps> = ({
    filterCriteria,
    setFilterCriteria,
}) => {
    const [isExpanded, setIsExpanded] = useState(true);

    const toggleExpanded = () => {
        setIsExpanded(!isExpanded);
    };

    const removeFilter = (filterType: keyof FilterCriteria, value: string): void => {
        setFilterCriteria(prev => ({
            ...prev,
            [filterType]: Array.isArray(prev[filterType])
                ? (prev[filterType] as string[]).filter(item => item !== value)
                : '',
        }));
    };

    const translateValue = (value: string): string => {
        return translationDict[value] || value;
    };

    const renderFilterChip = (
        label: string,
        value: string,
        filterType: keyof FilterCriteria
    ): React.ReactNode => {
        if (!value) return null;

        return (
            <StyledChip
                label={
                    <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            {label}:
                        </Typography>
                        <Typography variant="body2">
                            {translateValue(value)}
                        </Typography>
                    </Box>
                }
                onDelete={() => removeFilter(filterType, value)}
                deleteIcon={<CloseIcon />}
                sx={{ borderRadius: 1.5 }}
            />
        );
    };

    const renderArrayFilterChips = (
        label: string,
        values: string[],
        filterType: keyof FilterCriteria
    ): React.ReactNode => {
        if (!values || values.length === 0) return null;

        return values.map((value, index) => (
            <StyledChip
                key={`${filterType}-${index}`}
                label={
                    <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                            {label}:
                        </Typography>
                        <Typography variant="body2">
                            {translateValue(value)}
                        </Typography>
                    </Box>
                }
                onDelete={() => removeFilter(filterType, value)}
                deleteIcon={<CloseIcon />}
                sx={{ borderRadius: 1.5 }}
            />
        ));
    };

    const hasActiveFilters = Object.values(filterCriteria).some(value =>
        (Array.isArray(value) && value.length > 0) ||
        (!Array.isArray(value) && value)
    );

    if (!hasActiveFilters) {
        return null;
    }

    const clearAllFilters = (): void => {
        setFilterCriteria({
            matchName: '',
            matchDate: '',
            stages: [],
            location: '',
            events: [],
            categories: [],
            startDate: '',
            endDate: '',
            customTags: [],
        });
    };

    return (
        <Paper
            elevation={1}
            sx={{
                borderRadius: 2,
                overflow: 'hidden'
            }}
        >
            <HeaderBox onClick={toggleExpanded}>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography variant="subtitle1" sx={{ fontWeight: 500 }}>
                        篩選條件
                    </Typography>
                </Stack>
                <Stack direction="row" spacing={1}>
                    <IconButton
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation();
                            clearAllFilters();
                        }}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                    <IconButton
                        size="small"
                        onClick={(e) => {
                            e.stopPropagation();
                            toggleExpanded();
                        }}
                    >
                        {isExpanded ?
                            <KeyboardArrowUpIcon fontSize="small" /> :
                            <ExpandMoreIcon fontSize="small" />
                        }
                    </IconButton>
                </Stack>
            </HeaderBox>

            <Collapse in={isExpanded}>
                <ChipsContainer>
                    {renderFilterChip('影片', filterCriteria.matchName, 'matchName')}
                    {renderFilterChip('日期', filterCriteria.matchDate, 'matchDate')}
                    {renderFilterChip('地點', filterCriteria.location, 'location')}
                    {renderFilterChip('開始日期', filterCriteria.startDate, 'startDate')}
                    {renderFilterChip('結束日期', filterCriteria.endDate, 'endDate')}
                    {renderArrayFilterChips('階段', filterCriteria.stages, 'stages')}
                    {renderArrayFilterChips('賽事', filterCriteria.events, 'events')}
                    {renderArrayFilterChips('類別', filterCriteria.categories, 'categories')}
                    {renderArrayFilterChips('標籤', filterCriteria.customTags, 'customTags')}
                </ChipsContainer>
            </Collapse>
        </Paper>
    );
};

export default ActiveFilters;