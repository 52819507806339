const Padding = () => {
  return (
    <div
      className="custom-padding-component"
      style={{ minHeight: '30vh', minWidth: '1px', background: 'transparent' }}
    ></div>
  );
};

export default Padding;
