import { useState } from 'react';
import { IconButton, Menu, MenuItem } from '@mui/material';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import FlagVideo from 'src/components/VideoControlPanel/VideoFlag';
import AddPlaylist from 'src/components/AddPlaylist/AddPlaylist';

import './VideoControlPanel.scss';

const VideoControlPanel = ({
  videoData,
  setVideoData,
  onZoomChange,
  setScale,
  userInfo,
  matchData,
  currentTransformation,
  setDpOL,
  dpOL,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const zoomLevels = [1, 1.5, 2, 2.5, 3, 4, 5];

  const toggleOverlay = () => {
    setDpOL(!dpOL);
  };

  const handleZoomChange = (newScale) => {
    const clampedScale = Math.max(1, Math.min(newScale, 10));
    setScale(clampedScale);
    onZoomChange(clampedScale);
    setAnchorEl(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {videoData.isFs !== null ? (
        <div className="control-panel">
          <div className="left-content">
            {userInfo.admin && (
              <IconButton
                onClick={toggleOverlay}
                className="toggle-overlay-button"
                aria-label={dpOL ? 'quit editing' : 'edit'}
              >
                {dpOL ? <ExitToAppOutlinedIcon /> : <ModeEditOutlinedIcon />}
              </IconButton>
            )}
            {videoData.isFs === false && (
              <>
                <AddPlaylist userInfo={userInfo} matchId={matchData.matchId} />
                <FlagVideo
                  matchId={matchData.matchId}
                  defaultOpen={false}
                  ocr={false}
                  setVideoData={setVideoData}
                />
              </>
            )}
          </div>
          <div className="zoom-controls">
            <IconButton
              className="zoom-button"
              onClick={handleClick}
              aria-controls="zoom-menu"
              aria-haspopup="true"
            >
              <span className="zoom-scale">
                {currentTransformation.scale.toFixed(1)}x
              </span>
              <KeyboardArrowDownIcon className="zoom-arrow" />
            </IconButton>
            <Menu
              id="zoom-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
              className="zoom-menu"
              PaperProps={{
                className: 'zoom-menu-paper',
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              {zoomLevels.map((level) => (
                <MenuItem
                  key={level}
                  onClick={() => handleZoomChange(level)}
                  className={`zoom-menu-item ${currentTransformation.scale === level ? 'active' : ''}`}
                >
                  {level.toFixed(1)}x
                </MenuItem>
              ))}
            </Menu>
          </div>
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};

export default VideoControlPanel;
