import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';

import axios from 'axios';
import Cookies from 'js-cookie';
import { fetchDropPointsAPI } from 'src/api/videoService';

import VideoPlayer from 'src/components/VideoPlayer/VideoPlayer';
import VideoDynamicPanel from './VideoDynamicPanel/VideoDynamicPanel';
import { transformTrajectoryData, groupByRound } from './utils';
import detectDevice from 'src/utils/utils';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import Padding from 'src/components/padding';

import './VideoInterface.scss';

import {
  matchIdIndex,
  videoNameIndex,
  matchDescriptionIndex,
  stageIndex,
  dateIndex,
  locationIndex,
  homePlayerIndex,
  awayPlayerIndex,
  urlIndex,
  thumbnailIndex,
  reportIndex,
  subEventIndex,
  parentEventIndex,
  categoryIndex,
  viewCountIndex,
  uploadUserIndex,
  ballDataIndex,
  ballStartTimeIndex,
  ballEndTimeIndex,
  homePlayerIdIndex,
  awayPlayerIdIndex,
  roundDataIndex,
  roundEndTimeIndex,
  homePlayerMatchScoreIndex,
  awayPlayerMatchScoreIndex,
} from 'src/constants';

import { fetchIndMatchDataAPI } from 'src/api/videoService';

const VideoInterface = ({ userInfo }) => {
  const [error, setError] = useState('');
  const [isMobile, setIsMobile] = useState(true);
  const [isMobileBrowser, setIsMobileBrowser] = useState(false);
  const videoRef = useRef(null);
  const token = Cookies.get('token');
  const { videoId } = useParams();

  const [matchData, setMatchData] = useState({
    matchId: '',
    videoName: '',
    videoDescription: '',
    stage: '',
    matchDate: '',
    location: '',
    homePlayer: '',
    awayPlayer: '',
    homePlayerId: '',
    awayPlayerId: '',
    fileUrl: '',
    thumbnailUrl: '',
    startEndTimeUrl: '',
    reportUrl: '',
    matchEvent: '',
    matchParentEvent: '',
    matchCategory: '',
    viewCount: '',
    uploadUser: '',
    homePlayerScore: 0,
    awayPlayerScore: 0,
  });
  const [roundResults, setRoundResults] = useState(null);
  const [rawDpResults, setRawDpResults] = useState(null);
  const [dpResults, setDpResults] = useState(null);
  const [topPlayerId, setTopPlayerId] = useState('');
  const [topPlayer, setTopPlayer] = useState('');

  const [videoData, setVideoData] = useState({
    filename: '',
    currentRound: 0,
    currentBall: 0,
    currentTotalScore: 0,
    isPlaying: false,
    isCoachPOV: true,
    isFs: false,
    tabIndex: 0,
    isHovered: false,
    skipped: false,
    refresh: true,
    numberOfRound: 0,
    played: false,
  });

  const [commentaryTimes, setCommentaryTimes] = useState({
    firstCommentaryStartTime: 0,
    lastCommentaryStartTime: 0,
    firstCommentaryEndTime: 0,
    lastCommentaryEndTime: 0,
  });

  const [replayData, setReplayData] = useState({
    ballReplayTimes: 1,
    highlightReplayTimes: 1,
    replayDeuce: false,
    replayCriticalPoint: false,
  });

  const [currentTransformation, setCurrentTransformation] = useState({
    positionX: 0,
    positionY: 0,
    scale: 1,
  });

  const updateVideoViews = async () => {
    const response = await axios.put(`/api/updateVideoViews/${videoId}`, null, {
      params: {
        primary_attribute: 'watched video : ' + matchData.videoName,
      },
      headers: {
        Authorization: `${token}`,
      },
    });
  };

  useEffect(() => {
    const handleResize = () => {
      const { checkedMobileBrowser, checkedMobile } = detectDevice();
      setIsMobileBrowser(checkedMobileBrowser);
      setIsMobile(checkedMobile);
    };

    // Initial check on mount
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile]);

  useEffect(() => {
    if (videoData.played === true) {
      // 5 minutes
      setTimeout(function () {
        updateVideoViews();
      }, 30000);
    }
  }, [videoData.played]);

  useEffect(() => {
    if (
      matchData.homePlayerId !== '' &&
      matchData.awayPlayerId !== '' &&
      topPlayerId !== ''
    ) {
      if (topPlayerId === matchData.homePlayerId) {
        setTopPlayer(matchData.homePlayer);
      } else {
        setTopPlayer(matchData.awayPlayer);
      }
    }
  }, [matchData.homePlayerId, matchData.awayPlayerId, topPlayerId]);

  // load data
  useEffect(() => {
    const fetchVideoData = async () => {
      try {
        const responseData = await fetchIndMatchDataAPI(videoId, token);
        // Destructure the response data
        const [MatchData, rawRoundResults] = responseData;

        // change date and url
        let transformedList = MatchData.map((videoData) => {
          const fileUrl = videoData[urlIndex];
          setVideoData((prevState) => ({
            ...prevState,
            filename: fileUrl,
          }));
          const urlWithoutSuffix = fileUrl.substring(
            0,
            fileUrl.lastIndexOf('.'),
          );
          const reportUrl = videoData[reportIndex];
          const url = `${process.env.PUBLIC_URL}/videos/${urlWithoutSuffix}/${fileUrl}`;
          const publicReportUrl = `${process.env.PUBLIC_URL}/videos/${urlWithoutSuffix}/report/${reportUrl}`;
          const fetchedMatchDate = videoData[dateIndex];
          const dateWithoutTime = fetchedMatchDate.replace(
            /\s\d{2}:\d{2}:\d{2}\s\w{3}$/,
            '',
          );

          return {
            matchId: videoData[matchIdIndex],
            videoName: videoData[videoNameIndex],
            videoDescription: videoData[matchDescriptionIndex],
            stage: videoData[stageIndex],
            matchDate: dateWithoutTime,
            location: videoData[locationIndex],
            homePlayer: videoData[homePlayerIndex],
            awayPlayer: videoData[awayPlayerIndex],
            homePlayerId: videoData[homePlayerIdIndex],
            awayPlayerId: videoData[awayPlayerIdIndex],
            fileUrl: url,
            thumbnailUrl: videoData[thumbnailIndex],
            reportUrl: publicReportUrl,
            matchEvent: videoData[subEventIndex],
            matchParentEvent: videoData[parentEventIndex],
            matchCategory: videoData[categoryIndex],
            viewCount: videoData[viewCountIndex],
            uploadUser: videoData[uploadUserIndex],
            homePlayerScore: videoData[homePlayerMatchScoreIndex],
            awayPlayerScore: videoData[awayPlayerMatchScoreIndex],
          };
        });

        // if there is round result
        if (
          rawRoundResults[0] &&
          rawRoundResults[0][roundDataIndex][roundEndTimeIndex] !== 9999
        ) {
          const firstBall = rawRoundResults[0][ballDataIndex][0];
          const lastBallArray =
            rawRoundResults[rawRoundResults.length - 1][ballDataIndex];
          const lastBall = lastBallArray[lastBallArray.length - 1];
          setCommentaryTimes((prevTimes) => ({
            ...prevTimes,
            firstCommentaryStartTime:
              !firstBall || firstBall.length === 0
                ? 0
                : firstBall[ballStartTimeIndex],
            firstCommentaryEndTime:
              !firstBall || firstBall.length === 0
                ? 9999
                : firstBall[ballEndTimeIndex],
            lastCommentaryStartTime:
              !lastBall || lastBall.length === 0
                ? 99999
                : lastBall[ballStartTimeIndex],
            lastCommentaryEndTime:
              !lastBall || lastBall.length === 0
                ? 99999
                : lastBall[ballEndTimeIndex],
          }));
        }
        // no ocr data
        else {
          setCommentaryTimes((prevTimes) => ({
            ...prevTimes,
            firstCommentaryStartTime: 0,
            firstCommentaryEndTime: 9999,
            lastCommentaryStartTime: 99999,
            lastCommentaryEndTime: 99999,
          }));
        }

        setMatchData(transformedList[0]);
        setRoundResults(rawRoundResults);
        setVideoData((prevState) => ({
          ...prevState,
          numberOfRound: rawRoundResults.length,
        }));
      } catch (error) {
        setError(error.message);
      }
    };

    const fetchDpData = async () => {
      try {
        const responseData = await fetchDropPointsAPI(
          {
            videoId: videoId,
          },
          token,
        );

        setTopPlayerId(responseData[1]);

        const transformedData = transformTrajectoryData(responseData[0]);
        const groupedRaw = groupByRound(responseData[0]);
        const grouped = groupByRound(transformedData);

        setRawDpResults(groupedRaw);
        setDpResults(grouped);
      } catch (error) {
        setError(error.message);
      }
    };

    setVideoData((prevData) => ({
      ...prevData,
      isMobile: isMobile,
    }));

    if (videoData.refresh === true) {
      setMatchData((prevState) => ({
        ...prevState,
        matchId: 0,
      }));
      fetchVideoData();
      fetchDpData();
      setVideoData((prevState) => ({
        ...prevState,
        refresh: false,
      }));
    }
  }, [videoData.refresh]);

  return (
    <>
      {matchData.fileUrl && videoRef ? (
        <div className="video-interface-main">
          <div
            className={
              isMobile === true ? 'video-Minterface' : 'video-interface'
            }
          >
            <VideoPlayer
              source={matchData.fileUrl}
              videoRef={videoRef}
              replayData={replayData}
              commentaryTimes={commentaryTimes}
              videoData={videoData}
              setVideoData={setVideoData}
              roundResults={roundResults}
              userInfo={userInfo}
              currentTransformation={currentTransformation}
              setCurrentTransformation={setCurrentTransformation}
              matchData={matchData}
              isMobileBrowser={isMobileBrowser}
            />
            <VideoDynamicPanel
              roundResults={roundResults}
              dpResults={dpResults}
              rawDpResults={rawDpResults}
              videoRef={videoRef}
              token={token}
              userInfo={userInfo}
              matchData={matchData}
              videoData={videoData}
              setVideoData={setVideoData}
              currentTransformation={currentTransformation}
              replayData={replayData}
              setReplayData={setReplayData}
              topPlayer={topPlayer}
            />
          </div>
          <Padding />
        </div>
      ) : (
        <div style={{ minHeight: '70vh', display: 'flex' }}>
          <LoadingScreen option={2} />
        </div>
      )}
    </>
  );
};

export default VideoInterface;
