// VideoThumbnail.js
import { useRef, useState } from 'react';
import { Box, CardMedia } from '@mui/material';
import PushPinIcon from '@mui/icons-material/PushPin';

export const VideoThumbnail = ({ videoUrl, thumbnailUrl, isPin }) => {
  const [isHovered, setIsHovered] = useState(false);
  const videoRef = useRef(null);

  return (
    <Box
      sx={{ position: 'relative' }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      {isPin && (
        <PushPinIcon
          sx={{
            position: 'absolute',
            top: 2,
            right: 2,
            color: 'yellow',
            zIndex: 2,
          }}
        />
      )}
      <CardMedia
        component={isHovered ? 'video' : 'img'}
        ref={videoRef}
        image={!isHovered && thumbnailUrl ? thumbnailUrl : undefined}
        src={isHovered ? videoUrl : undefined}
        muted={isHovered}
        autoPlay={isHovered}
        preload="none"
        sx={{
          width: '100%',
          height: '200px',
          objectFit: 'cover',
          '@media (min-width: 600px)': {
            width: '300px',
            height: '170px',
          },
        }}
      />
    </Box>
  );
};
