import React, { useMemo, useState } from "react";
import {
  Avatar,
  Box,
  Divider,
  InputAdornment,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popover,
  TextField,
  Typography,
  Button,
  Chip,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Person, Search } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import useStore from "../../store/store";
import { FirstPlayerInfo, ReachablePlayerInfo } from "../../store/Type";

interface Props {
  label: string;
  value: (FirstPlayerInfo | ReachablePlayerInfo) | null;
  onChange: (
    event: React.SyntheticEvent,
    value: (FirstPlayerInfo | ReachablePlayerInfo) | null
  ) => void;
  options: (FirstPlayerInfo | ReachablePlayerInfo)[];
  disabled?: boolean;
  placeholderKey: string;
  mode: "selectFirstPlayer" | "selectSecondPlayer";
}
export const NEWSearchablePlayerList: React.FC<Props> = ({
  label,
  value,
  onChange,
  options,
  disabled = false,
  placeholderKey,
  mode,
}) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = useState("");
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Get necessary store values
  const { currentCategory, nodeDepth } = useStore();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setSearchQuery("");
  };

  const filteredPlayers = useMemo(() => {
    return options
      .filter((player) => {
        const nameMatch = player.name
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
        const depthMatch =
          mode === "selectSecondPlayer"
            ? (player.depth ?? Infinity) <= nodeDepth
            : true;
        return nameMatch && depthMatch;
      })
      .sort((a, b) => {
        // Sort by path length first, then name
        const connectionsA = a.path?.length || 0;
        const connectionsB = b.path?.length || 0;
        if (connectionsA !== connectionsB) return connectionsB - connectionsA;
        return a.name.localeCompare(b.name);
      });
  }, [options, searchQuery, mode, nodeDepth]);

  const handleSelectPlayer = (player: ReachablePlayerInfo | null) => {
    const syntheticEvent = { type: "click" } as React.SyntheticEvent;
    onChange(syntheticEvent, player);
    handleClose();
  };
  const open = Boolean(anchorEl);

  return (
    <Box>
      <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 1 }}>
        {label}
      </Typography>

      {value ? (
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
            {value.head_shot ? (
              <Avatar
                src={value.head_shot}
                alt={value.name}
                sx={{
                  width: 32,
                  height: 32,
                  "& img": {
                    objectPosition: "top",
                    objectFit: "cover",
                  },
                }}
              />
            ) : (
              <Person />
            )}
            <Typography>{value.name}</Typography>
          </Box>
          <Button
            size="small"
            onClick={() => handleSelectPlayer(null)}
            sx={{ ml: 1 }}
          >
            清除
          </Button>
        </Box>
      ) : (
        <Button
          onClick={handleClick}
          variant="outlined"
          fullWidth
          disabled={disabled}
          sx={{ justifyContent: "flex-start", py: 1 }}
        >
          {t(placeholderKey)}
        </Button>
      )}

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          sx: {
            width: anchorEl?.offsetWidth,
            maxHeight: "70vh",
          },
        }}
      >
        <Paper
          elevation={0}
          sx={{
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
          }}
        >
          <Box sx={{ p: 2, borderBottom: 1, borderColor: "divider" }}>
            <TextField
              fullWidth
              size={isMobile ? "small" : "medium"}
              placeholder={t("搜尋球員...")}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              disabled={disabled}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search color="action" />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <List
            sx={{
              maxHeight: "50vh",
              overflow: "auto",
              "& .MuiListItemButton-root:hover": {
                bgcolor: "action.hover",
              },
            }}
          >
            {filteredPlayers.map((player) => (
              <React.Fragment key={player.node}>
                <ListItem disablePadding>
                  <ListItemButton
                    selected={value?.node === player.node}
                    onClick={() =>
                      handleSelectPlayer(player as ReachablePlayerInfo)
                    }
                    sx={{
                      px: 2,
                      py: 1.5,
                      "&.Mui-selected": {
                        backgroundColor: "primary.light",
                        "&:hover": {
                          backgroundColor: "primary.light",
                        },
                      },
                    }}
                  >
                    <ListItemIcon sx={{ minWidth: 40 }}>
                      {player.head_shot ? (
                        <Avatar
                          src={player.head_shot}
                          alt={player.name}
                          sx={{
                            width: 32,
                            height: 32,
                            "& img": {
                              objectPosition: "top",
                              objectFit: "cover",
                            },
                          }}
                        />
                      ) : (
                        <Person />
                      )}
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            flexWrap: "wrap",
                          }}
                        >
                          <Typography
                            component="span"
                            variant="body1"
                            sx={{
                              fontWeight:
                                value?.node === player.node ? 500 : 400,
                            }}
                          >
                            {player.name}
                          </Typography>
                          {player.country_code && (
                            <Chip
                              label={player.country_code}
                              size="small"
                              sx={{
                                height: 20,
                                "& .MuiChip-label": {
                                  px: 1,
                                  py: 0.5,
                                  fontSize: "0.75rem",
                                },
                              }}
                            />
                          )}
                        </Box>
                      }
                      secondary={
                        <Box
                          sx={{ display: "flex", alignItems: "center", gap: 1 }}
                        >
                          {player.path.length !== undefined && (
                            <Typography variant="body2" color="text.secondary">
                              {player.path.length} connections
                            </Typography>
                          )}
                          {player.depth !== undefined && (
                            <>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                •
                              </Typography>
                              <Typography
                                variant="body2"
                                color="text.secondary"
                              >
                                Depth: {player.depth}
                              </Typography>
                            </>
                          )}
                        </Box>
                      }
                    />
                  </ListItemButton>
                </ListItem>
                <Divider component="li" />
              </React.Fragment>
            ))}
          </List>
        </Paper>
      </Popover>
    </Box>
  );
};
