import { useState, useEffect, useCallback } from 'react';

import Button from '@mui/material/Button';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import ExpandLessOutlinedIcon from '@mui/icons-material/ExpandLessOutlined';
import ExpandMoreOutlinedIcon from '@mui/icons-material/ExpandMoreOutlined';
import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';

import { fetchPlayerDataAPI } from 'src/api/playerService';
import Cookies from 'js-cookie';

import PlayerCard from './PlayerPanel/PlayerCard/PlayerCard';
import PlayerSearchBar from 'src/components/PlayerSearchBar/PlayerSearchBar';
import LoadingScreen from 'src/components/loading/LoadingScreen';

import { countryToCodeMapping } from 'src/constants';

import Flag from 'react-world-flags';

import './PlayerHome.scss';

import { countryCodeMapping, countries } from 'src/constants';

const translationDict = {
  男單打: 'm',
  男雙打: 'mm',
  女單打: 'f',
  女雙打: 'ff',
  混合雙打: 'mf',
};

const translationRDict = {
  m: '男單打',
  mm: '男雙打',
  f: '女單打',
  ff: '女雙打',
  mf: '混合雙打',
};

// Cache utility functions
const cacheWithExpiry = {
  set: (key, value, ttl) => {
    const item = {
      value,
      expiry: Date.now() + ttl,
    };
    localStorage.setItem(key, JSON.stringify(item));
  },

  get: (key) => {
    const itemStr = localStorage.getItem(key);
    if (!itemStr) return null;

    const item = JSON.parse(itemStr);
    if (Date.now() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  },
};

// Constants
const CACHE_TTL = 1 * 60 * 60 * 1000; // 1 hours in milliseconds

// Custom hook for cached categories with translation
const useCachedCategories = (ttl = CACHE_TTL) => {
  const [selectedCats, setSelectedCats] = useState(() => {
    const cachedData = cacheWithExpiry.get('playerFilterCriteria');
    if (!cachedData) return [];

    return cachedData.category.map((item) => translationRDict[item]);
  });

  // Wrapper for setSelectedCats that handles caching
  const setSelectedCatsWithCache = useCallback(
    (newCategories) => {
      setSelectedCats(newCategories);

      // When setting new categories, we need to cache the complete filterCriteria
      const existingCache = cacheWithExpiry.get('playerFilterCriteria') || {
        playerName: '',
        country: countries,
        category: [],
        existingMatch: false,
      };

      // Update the cache with the new categories
      cacheWithExpiry.set(
        'playerFilterCriteria',
        {
          ...existingCache,
          category: Array.isArray(newCategories)
            ? newCategories.map(
                (cat) =>
                  Object.entries(translationRDict).find(
                    ([k, v]) => v === cat,
                  )?.[0] || cat,
              )
            : [],
        },
        ttl,
      );
    },
    [ttl],
  );

  return [selectedCats, setSelectedCatsWithCache];
};

// Custom hook for cached filter criteria
const useCachedFilterCriteria = (ttl = CACHE_TTL) => {
  const [filterCriteria, setFilterCriteria] = useState(() => {
    const cachedCriteria = cacheWithExpiry.get('playerFilterCriteria');
    return (
      cachedCriteria || {
        playerName: '',
        country: ['Hong Kong, China'],
        category: [],
        existingMatch: false,
      }
    );
  });

  const setFilterCriteriaWithCache = useCallback(
    (newCriteria) => {
      setFilterCriteria(newCriteria);
      cacheWithExpiry.set('playerFilterCriteria', newCriteria, ttl);
    },
    [ttl],
  );

  return [filterCriteria, setFilterCriteriaWithCache];
};

// Custom hook for cached sort criteria
const useCachedSortCriteria = (ttl = CACHE_TTL) => {
  const [sortCriteria, setSortCriteria] = useState(() => {
    return cacheWithExpiry.get('playerSortCriteria') || 4;
  });

  const setSortCriteriaWithCache = useCallback(
    (newCriteria) => {
      setSortCriteria(newCriteria);
      cacheWithExpiry.set('playerSortCriteria', newCriteria, ttl);
    },
    [ttl],
  );

  return [sortCriteria, setSortCriteriaWithCache];
};
const PlayerHome = ({ userInfo }) => {
  const token = Cookies.get('token');
  const [error, setError] = useState('');
  const [expanded, setExpanded] = useState(true);

  const [selectedCats, setSelectedCats] = useCachedCategories();
  const [filterCriteria, setFilterCriteria] = useCachedFilterCriteria();
  const [sortCriteria, setSortCriteria] = useCachedSortCriteria();
  const [playerList, setPlayerList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const handleClick = (item) => {
    const translatedItem = translationDict[item];

    if (selectedCats.includes(item)) {
      setSelectedCats(
        selectedCats.filter((selectedCat) => selectedCat !== item),
      );
      setFilterCriteria({
        ...filterCriteria,
        category: filterCriteria.category.filter(
          (cat) => cat !== translatedItem,
        ),
      });
    } else {
      setSelectedCats([...selectedCats, item]);
      setFilterCriteria({
        ...filterCriteria,
        category: [...filterCriteria.category, translatedItem],
      });
    }
  };

  const handleHongKong = () => {
    const updatedFilterCriteria = {
      playerName: '',
      country: ['Hong Kong, China'],
      category: filterCriteria.category,
      existingMatch: false,
    };

    setFilterCriteria(updatedFilterCriteria);
  };

  const handleExpand = () => {
    setExpanded(!expanded);
  };

  const isCatSelected = (item) => selectedCats.includes(item);

  const handleClearFilter = (key, value) => {
    setFilterCriteria((prevCriteria) => {
      const updatedCriteria = { ...prevCriteria };
      if (value === 'all') {
        updatedCriteria['country'] = countries;
      } else if (value === 'clear') {
        updatedCriteria['country'] = [];
      } else if (Array.isArray(updatedCriteria[key])) {
        updatedCriteria[key] = updatedCriteria[key].filter(
          (item) => item !== value,
        );

        if (key === 'category') {
          setSelectedCats(
            selectedCats.filter(
              (selectedCat) => selectedCat !== translationRDict[value],
            ),
          );
        }
      } else {
        updatedCriteria[key] = [''];
      }
      if (updatedCriteria['country'].length === 0) {
        updatedCriteria['country'] = countries;
      }
      return updatedCriteria;
    });
  };

  const handleWorld = () => {
    const updatedFilterCriteria = {
      playerName: '',
      country: countries,
      category: filterCriteria.category,
      existingMatch: true,
    };

    setFilterCriteria(updatedFilterCriteria);
  };

  const fetchPlayersData = async (filterCriteria, sortCriteria) => {
    const { playerName, category, existingMatch, country } = filterCriteria;
    setIsLoading(true);

    try {
      const response = await fetchPlayerDataAPI(
        {
          player_name: playerName,
          categories: category.join(','),
          countries: country.join(','),
          existingMatch: existingMatch,
          sortCriteria: sortCriteria,
          accountType: userInfo.accountType,
        },
        token,
      );
      const responseData = response.data;

      if (responseData === undefined) {
        setPlayerList([]);
        return;
      }

      const playerDict = responseData.map((playerData) => {
        const birthDate = playerData.dob
          ? new Date(playerData.dob)
          : new Date('2000-01-01');
        const currentDate = new Date();
        let actual_age = currentDate.getFullYear() - birthDate.getFullYear();

        const currentMonth = currentDate.getMonth();
        const birthMonth = birthDate.getMonth();
        const currentDay = currentDate.getDate();
        const birthDay = birthDate.getDate();
        const countryCode = countryCodeMapping[playerData.country_code] || '';

        if (
          currentMonth < birthMonth ||
          (currentMonth === birthMonth && currentDay < birthDay)
        ) {
          actual_age--;
        }

        let head_shot_path = playerData.head_shot;
        let player2_head_shot_path = playerData.player2_head_shot;

        if (head_shot_path && !head_shot_path.startsWith('https')) {
          head_shot_path = `${process.env.PUBLIC_URL}/players/headshots/${head_shot_path}`;
        }

        if (
          player2_head_shot_path &&
          !player2_head_shot_path.startsWith('https')
        ) {
          player2_head_shot_path = `${process.env.PUBLIC_URL}/players/headshots/${player2_head_shot_path}`;
        }

        const playerDict = {
          ...playerData,
          countryCode: countryCode,
          actual_age: actual_age,
          head_shot: head_shot_path,
          player2_head_shot: player2_head_shot_path,
        };

        return playerDict;
      });
      setPlayerList(playerDict);
      setIsLoading(false);
    } catch (error) {
      setError(error.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchPlayersData(filterCriteria, sortCriteria);
  }, [filterCriteria, sortCriteria]);

  return (
    <div className="players-home-container">
      {error && <p style={{ color: 'white' }}>Error: {error}</p>}
      <div className="player-home-main">
        {expanded && (
          <div className="player-home-option-panel">
            <PlayerSearchBar
              setFilterCriteria={setFilterCriteria}
              appliedFilterCriteria={filterCriteria}
              setSortCriteria={setSortCriteria}
              appliedSortCriteria={sortCriteria}
              searchName={filterCriteria.playerName}
            />
            <div className="player-home-filter-container">
              <div className="player-home-filter-panel">
                {Object.keys(filterCriteria).map((key) => {
                  const arrayValue = filterCriteria[key];

                  // Check if the value is an array
                  if (Array.isArray(arrayValue) && key !== 'category') {
                    const displayItems = arrayValue.slice(0, 5); // Get up to 5 elements

                    return (
                      <>
                        {arrayValue.length === 0 ? (
                          <Button
                            className="filter-button"
                            variant="contained"
                            disabled
                          >
                            未選擇國家
                          </Button>
                        ) : arrayValue.length > 5 ? (
                          <Button
                            key="5+"
                            className="filter-button"
                            variant="contained"
                            endIcon={<CloseOutlinedIcon />}
                            onClick={() => handleClearFilter(key, 'clear')}
                          >
                            5+
                          </Button>
                        ) : (
                          displayItems.map((value, index) => {
                            const code = countryToCodeMapping[value];
                            return (
                              <Button
                                key={index}
                                className="filter-button"
                                variant="contained"
                                endIcon={<CloseOutlinedIcon />}
                                onClick={() => handleClearFilter(key, value)}
                              >
                                {code === 'TPE' ? (
                                  <img
                                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSmBxo_grwIppKabQW2G7J0zwWZwvqWC8DWQg&s"
                                    className="player-smallflag"
                                  />
                                ) : code ? (
                                  <Flag
                                    code={code}
                                    className="player-smallflag"
                                  />
                                ) : (
                                  <p>{value}</p>
                                )}
                              </Button>
                            );
                          })
                        )}
                      </>
                    );
                  }

                  return null; // Render nothing if the value is not an array
                })}
              </div>

              <div className="filter-buttons-group">
                <Button
                  className="clear-filter-button hk-filter-button"
                  variant="outlined"
                  onClick={() => handleHongKong()}
                >
                  香港
                </Button>
                <Button
                  className="clear-filter-button"
                  variant="outlined"
                  onClick={() => handleWorld()}
                >
                  <PublicOutlinedIcon />
                </Button>
              </div>
            </div>
          </div>
        )}
        <div className="players-category">
          <button
            className={isCatSelected('男單打') ? 'selected' : ''}
            onClick={() => handleClick('男單打')}
          >
            男單打
          </button>
          <button
            className={isCatSelected('男雙打') ? 'selected' : ''}
            onClick={() => handleClick('男雙打')}
          >
            男雙打
          </button>
          <button
            className={isCatSelected('女單打') ? 'selected' : ''}
            onClick={() => handleClick('女單打')}
          >
            女單打
          </button>
          <button
            className={isCatSelected('女雙打') ? 'selected' : ''}
            onClick={() => handleClick('女雙打')}
          >
            女雙打
          </button>
          <button
            className={isCatSelected('混合雙打') ? 'selected' : ''}
            onClick={() => handleClick('混合雙打')}
          >
            混合雙打
          </button>
          <Button
            className="expand-filter-button"
            variant="outlined"
            endIcon={
              expanded ? <ExpandLessOutlinedIcon /> : <ExpandMoreOutlinedIcon />
            }
            onClick={() => handleExpand()}
          ></Button>
        </div>
        <div className="player-grid">
          {isLoading === false && playerList.length > 0 ? (
            playerList.map((playerData, index) => (
              <PlayerCard playerData={playerData} />
            ))
          ) : (
            <LoadingScreen option={2} color={2} />
          )}
          <div className="padding"></div>
        </div>
      </div>
    </div>
  );
};

export default PlayerHome;
