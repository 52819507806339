import { useState } from 'react';
import UserManagement from './UserManagement';
import './Users.scss';

const Users = ({ userData, setRefreshData, accountType }) => {
  return (
    <main className="users-container">
      <div className="content-wrapper">
        <UserManagement
          userData={userData}
          setRefreshData={setRefreshData}
          accountType={accountType}
        />
      </div>
    </main>
  );
};

export default Users;
