import React from "react";
import { Dialog, styled, Box } from "@mui/material";
import GameInfoRenderer from "./GameInfo/renderGameInfo";
import { NEWSelectionContent } from "./NEWSelectionContent";
import useStore from "../../store/store";

interface SharedGameInfoProps {
  gameInfo: any;
  onClose: () => void;
  variant: "drawer" | "paper" | "dialog";
  fullScreenDialog?: boolean;
}

// Keep MUI styling
const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

export const SharedGameInfo: React.FC<SharedGameInfoProps> = ({
  gameInfo,
  onClose,
  variant,
  fullScreenDialog,
}) =>
  fullScreenDialog ? (
    <Dialog
      open={true}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: {
          width: "80%",
          maxWidth: "none",
          m: 2,
        },
      }}
    >
      <StyledBox
        sx={{
          p: 3,
          height: "80vh",
          overflow: "auto",
        }}
      >
        <GameInfoRenderer
          gameInfo={gameInfo}
          // onClose={onClose}
          variant={variant}
        />
      </StyledBox>
    </Dialog>
  ) : (
    <GameInfoRenderer
      gameInfo={gameInfo}
      // onClose={onClose}
      variant={variant}
    />
  );

interface SharedContentProps {
  openDetails: any[];
  setOpenDetails: React.Dispatch<React.SetStateAction<any[]>>;
  variant: "drawer" | "paper" | "dialog";
}

export const SharedContent: React.FC<SharedContentProps> = ({
  openDetails,
  setOpenDetails,
  variant,
}) => {
  // Get values from store
  const { selectedGameInfo, setFirstPlayer, setSecondPlayer } = useStore();

  const handleClose = () => {
    setFirstPlayer(null);
    setSecondPlayer(null);
  };

  return (
    <>
      {selectedGameInfo ? (
        <SharedGameInfo
          gameInfo={selectedGameInfo}
          onClose={handleClose}
          variant={variant}
        />
      ) : (
        <NEWSelectionContent />
      )}
    </>
  );
};

export default SharedContent;
