
import React from 'react';
import { lastWttPlayerId } from 'src/constants';
import { countryCodeMapping } from 'src/constants';
import { translateToChinese } from 'src/utils/utils';
import Flag from 'react-world-flags';

import './PlayerProfileCard.scss';


interface PlayerProfileProps {
  playerData: Record<string, string>;
  accountType: string;
  opponent: boolean;
}

const PlayerProfileCard: React.FC<PlayerProfileProps> = ({
  playerData,
  accountType,
  opponent = false,
}) => {
  const code =
    countryCodeMapping[
    playerData.country_code as keyof typeof countryCodeMapping
    ] || '';

  const handleClick = () => {
    window.location.href = `/player-analysis/${playerData.player_id}`;
  };

  return (
    <div className="player-profile-container" onClick={handleClick}>
      <div className="player-photo-container">
        {playerData.gender !== null &&
          (playerData.gender === 'F' || playerData.gender === 'M') && (
            <p className="age" style={opponent ? { right: 0 } : { left: 0 }}>
              年齡 : {playerData.actual_age}
            </p>
          )}
        {playerData.gender === 'MM' ||
          playerData.gender === 'FF' ||
          playerData.gender === 'MF' ? (
          <div className="double-img-container">
            <img
              className="player1-img"
              src={
                playerData.head_shot ||
                'https://uploads.concordia.net/2017/08/30180948/headshot-placeholder.jpg'
              }
              alt="Player Headshot"
              onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                if (
                  e.currentTarget &&
                  e.currentTarget.src !==
                  'https://uploads.concordia.net/2017/08/30180948/headshot-placeholder.jpg'
                ) {
                  e.currentTarget.src =
                    'https://uploads.concordia.net/2017/08/30180948/headshot-placeholder.jpg';
                }
              }}
            />
            <img
              className="player2-img"
              src={
                playerData.player2_head_shot ||
                'https://uploads.concordia.net/2017/08/30180948/headshot-placeholder.jpg'
              }
              alt="Player Headshot"
              onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                if (
                  e.currentTarget &&
                  e.currentTarget.src !==
                  'https://uploads.concordia.net/2017/08/30180948/headshot-placeholder.jpg'
                ) {
                  e.currentTarget.src =
                    'https://uploads.concordia.net/2017/08/30180948/headshot-placeholder.jpg';
                }
              }}
            />
          </div>
        ) : (
          <img
            className="player-img"
            src={
              playerData.head_shot ||
              'https://uploads.concordia.net/2017/08/30180948/headshot-placeholder.jpg'
            }
            alt="Player Headshot"
            onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
              if (
                e.currentTarget &&
                e.currentTarget.src !==
                'https://uploads.concordia.net/2017/08/30180948/headshot-placeholder.jpg'
              ) {
                e.currentTarget.src =
                  'https://uploads.concordia.net/2017/08/30180948/headshot-placeholder.jpg';
              }
            }}
          />
        )}

        <div className="player-profile-main-info">
          <div className="player-profile-main-info-bot">
            <div className="player-profile-name-panel">
              <span>
                {playerData.player_name}
                {code === 'TPE' ? (
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSmBxo_grwIppKabQW2G7J0zwWZwvqWC8DWQg&s"
                    className="player-smallflag"
                  />
                ) : code ? (
                  <Flag code={code} className="player-smallflag" />
                ) : (
                  <img
                    src="https://t4.ftcdn.net/jpg/05/38/56/33/360_F_538563343_1Yb2J773QHkZSdWnIVhVxW4xjfOB8Rhi.jpg"
                    alt="No image"
                    className="player-smallflag"
                  />
                )}
              </span>
              <p className="country">
                {playerData.country ? playerData.country : '_'}
              </p>
            </div>
            <span className="player-last-updated">
              {playerData.last_updated !== 'Invalid Date'
                ? `最後更新 : ${playerData.last_updated}`
                : ''}
            </span>
          </div>
          <div className="player-info-container">
            <div className="player-additional-info-panel">
              {playerData.handedness && (
                <span className="hand">
                  {translateToChinese(playerData.handedness)}</span>
              )}
              {playerData.grip && (
                <span className="grip">
                  {translateToChinese(playerData.grip)}

                </span>
              )}
              {playerData.gender && (
                <span className="gender">
                  {translateToChinese(playerData.gender)}

                </span>
              )}
              {accountType === 'm' && (
                <div className="plastic">
                  <span>正手膠: {playerData.rubber_type}</span>
                  <span>反手膠: {playerData.flipped_rubber_type}</span>
                </div>
              )}
            </div>
            <div className="wtt-link">
              {accountType === 'a' &&
                parseInt(playerData.player_id) <= lastWttPlayerId && (
                  <a
                    href={`https://worldtabletennis.com/playerDescription?playerId=${playerData.player_id}`}
                    target="_blank"
                  >
                    球員檔案(WTT)
                  </a>
                )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlayerProfileCard;
