// VideoActions.js
import { CardActions, Typography } from '@mui/material';

export const VideoActions = ({ uploader, tags }) => (
  <CardActions
    sx={{
      display: 'flex',
      justifyContent: 'space-between',
      '@media (min-width: 600px)': {
        flexDirection: 'column',
        alignItems: 'flex-start',
      },
    }}
  >
    <Typography variant="body2">上傳者: {uploader}</Typography>
    <Typography
      variant="body2"
      sx={{
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      標籤: {tags}
    </Typography>
  </CardActions>
);
