// GraphControls.tsx
import React from "react";
import { Box, Button, Switch, FormControlLabel } from "@mui/material";
import { CalculateOutlined } from "@mui/icons-material";
import useGraphStore from "./graphStore";

const GraphControls: React.FC = () => {
  const { showGames, setShowGames, updateNodeVisibility } = useGraphStore();

  const handleToggleGames = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowGames(event.target.checked);
    updateNodeVisibility();
  };

  return (
    <Box sx={{}}>
      <FormControlLabel
        control={
          <Switch
            checked={showGames}
            onChange={handleToggleGames}
            size="small"
          />
        }
        label="Show Games"
      />
    </Box>
  );
};

export default GraphControls;
