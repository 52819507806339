import { useState, useEffect } from 'react';
import { fetchPlayerDataAPI } from 'src/api/playerService';
import PlayerVideoBlock from 'src/playerPages/PlayerVideos/PlayerVideoBlock';
import VideoAnalysisControl from 'src/components/VideoAnalysis/VideoAnalysisControl';
import SimpleHeatmap from 'src/components/Heatmap/SimpleHeatmap';
import MatchDescriptionPanel from './MatchDescriptionPanel';
import Cookies from 'js-cookie';

import {
  ballDataIndex,
  ballHomePlayerScoreIndex,
  ballAwayPlayerScoreIndex,
} from 'src/constants';

import './VideoBasicInfoPanel.scss';

const hmOptions_dict = {
  1: 'all',
  2: '1',
  3: '2',
  4: '3',
};

const subOptions_dict = {
  0: 'all',
  1: 'winning',
  2: 'ballWinning',
  3: 'losing',
  4: 'ballLosing',
};

const VideoBasicInfoPanel = ({
  matchData,
  roundResults,
  accountType,
  videoRef,
  userInfo,
  setVideoData,
}) => {
  const [matchDetails, setMatchDetails] = useState({
    matchId: '',
    videoName: '',
    matchDescription: '',
    stage: '',
    transformedMatchDate: '',
    location: '',
    opponentName: '',
    opponentCountry: '',
    opponentCountryCode: '',
    opponentAge: '',
    opponentHand: '',
    opponentStyle: '',
    opponentImg: false,
    opponentScore: 0,
    playerScore: 0,
    roundData: [{ _: 0, _: 0 }],
  });
  const [error, setError] = useState('');
  const [awayPlayerData, setAwayPlayerData] = useState({});
  const [homePlayerData, setHomePlayerData] = useState({});
  const [mainAnalysisOption, setMainAnalysisOption] = useState(0);
  const [subAnalysisOption, setSubAnalysisOption] = useState(0);
  const [selectedPlayer, setSelectedPlayer] = useState(
    matchData.homePlayer ? matchData.homePlayer : '',
  );
  const heatmapClass = 'main-hm-simple-plot-container';

  const token = Cookies.get('token');

  // get list of video
  useEffect(() => {
    const fetchPlayersData = async (playerId, setPlayerData) => {
      try {
        if (!playerId) {
          setPlayerData({ player_name: 'empty' });
          return;
        }
        const response = await fetchPlayerDataAPI(
          {
            player_id: playerId,
            accountType: accountType,
          },
          token,
        );

        if (!response.data) {
          setPlayerData({ player_name: 'empty' });
          return;
        }
        const responseData = response.data[0];

        const birthDate = responseData.dob
          ? new Date(responseData.dob)
          : new Date('2000-01-01');
        const currentDate = new Date();
        let actual_age = currentDate.getFullYear() - birthDate.getFullYear();

        const currentMonth = currentDate.getMonth();
        const birthMonth = birthDate.getMonth();
        const currentDay = currentDate.getDate();
        const birthDay = birthDate.getDate();
        const last_updated_date = new Date(
          responseData.last_updated,
        ).toLocaleDateString('en-GB');

        if (
          currentMonth < birthMonth ||
          (currentMonth === birthMonth && currentDay < birthDay)
        ) {
          actual_age--;
        }

        let head_shot_path = responseData.head_shot;
        let player2_head_shot_path = responseData.player2_head_shot;

        if (head_shot_path && !head_shot_path.startsWith('https')) {
          head_shot_path = `${process.env.PUBLIC_URL}/players/headshots/${head_shot_path}`;
        }

        if (
          player2_head_shot_path &&
          !player2_head_shot_path.startsWith('https')
        ) {
          player2_head_shot_path = `${process.env.PUBLIC_URL}/players/headshots/${player2_head_shot_path}`;
        }

        const updatedResponseData = {
          ...responseData,
          last_updated: last_updated_date,
          actual_age: actual_age,
          head_shot: head_shot_path,
          player2_head_shot: player2_head_shot_path,
        };
        setPlayerData(updatedResponseData); // Set playerData state
      } catch (error) {
        console.log(error);
        setError(error.message);
      }
    };

    fetchPlayersData(matchData['awayPlayerId'], setAwayPlayerData);
    fetchPlayersData(matchData['homePlayerId'], setHomePlayerData);
  }, [matchData]);

  useEffect(() => {
    const getmatchDetails = () => {
      const scores = [];
      for (const roundResult of roundResults) {
        const ballData = roundResult[ballDataIndex];
        if (ballData && ballData.length > 0) {
          const lastBallData = ballData[ballData.length - 1];
          const homePlayerRoundScore = lastBallData[ballHomePlayerScoreIndex];
          const awayPlayerRoundScore = lastBallData[ballAwayPlayerScoreIndex];
          scores.push({
            [matchData.homePlayer]: homePlayerRoundScore,
            [matchData.awayPlayer]: awayPlayerRoundScore,
          });
        }
      }

      setMatchDetails({
        matchId: matchData.matchId,
        videoName: matchData.videoName,
        matchDescription: matchData.videoDescription,
        stage: matchData.stage,
        transformedMatchDate: matchData.matchDate,
        location: matchData.location,
        opponentGender: awayPlayerData.gender,
        opponentId: awayPlayerData.player_id ?? -1,
        opponentName: awayPlayerData.player_name ?? '_',
        opponentCountry: awayPlayerData.country ?? '_',
        opponentCountryCode: awayPlayerData.country_code ?? '_',
        opponentAge: awayPlayerData.actual_age ?? '_',
        opponentHand: awayPlayerData.handedness ?? '_',
        opponentStyle: awayPlayerData.grip ?? '_',
        opponentImg: awayPlayerData.head_shot ?? false,
        opponentPlayer2Img: awayPlayerData.player2_head_shot ?? false,
        opponentScore: matchData.awayPlayerScore,
        playerScore: matchData.homePlayerScore,
        roundData:
          scores.length > 0
            ? scores
            : [{ [matchData.homePlayer]: 0, [matchData.awayPlayer]: 0 }],
      });
    };
    getmatchDetails();
  }, [awayPlayerData, homePlayerData]);

  return (
    <div className="video-info-panel">
      <PlayerVideoBlock
        matchDetails={matchDetails}
        playerData={homePlayerData}
        setVideoData={setVideoData}
        isAdmin={userInfo.admin}
      />
      <VideoAnalysisControl
        option={mainAnalysisOption}
        setOption={setMainAnalysisOption}
        subOption={subAnalysisOption}
        setSubOption={setSubAnalysisOption}
        selectedPlayer={selectedPlayer}
        setSelectedPlayer={setSelectedPlayer}
        homePlayer={matchData.homePlayer}
        awayPlayer={matchData.awayPlayer}
        coachComment={true}
      />
      {matchDetails.matchId !== '' && (
        <div className="video-info-analysis-container">
          {mainAnalysisOption === 0 ? (
            <MatchDescriptionPanel
              match_id={matchDetails.matchId}
              userComment={matchDetails.matchDescription}
              videoRef={videoRef}
              permission={userInfo.isCoach || userInfo.admin}
              setVideoData={setVideoData}
            />
          ) : (
            <SimpleHeatmap
              videoId={matchDetails.matchId}
              playerName={[selectedPlayer]}
              opponentName={
                selectedPlayer === matchData.homePlayer
                  ? [matchData.awayPlayer]
                  : [matchData.homePlayer]
              }
              hmOptions={hmOptions_dict[mainAnalysisOption]}
              subOptions={subOptions_dict[subAnalysisOption]}
              heatmapClass={heatmapClass}
              giant={true}
              roundResults={roundResults}
              videoRef={videoRef}
              setVideoData={setVideoData}
            />
          )}
        </div>
      )}
      <p className="padding"></p>
    </div>
  );
};

export default VideoBasicInfoPanel;
