import React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

const PlaylistContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  maxWidth: '100%',
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(1),
  },
}));

const PlaylistInfo = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.grey[100],
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
}));

const InfoGroup = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '&:last-child': {
    marginBottom: 0,
  },
}));

const InfoCaption = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.caption.fontSize,
  color: theme.palette.text.secondary,
  marginRight: theme.spacing(1),
  textDecoration: 'underline',
  display: 'inline-block',
}));

const PlaylistDescription = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.body1.fontSize,
  lineHeight: 1.5,
  margin: theme.spacing(0.5, 0, 0),
  maxHeight: '4.5rem',
  overflowY: 'auto',
  overflowX: 'hidden',
  wordWrap: 'break-word',
  whiteSpace: 'pre-wrap',
}));

const MetaContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    gap: theme.spacing(1),
  },
}));

const MetaText = styled(Typography)(({ theme }) => ({
  margin: 0,
  color: theme.palette.text.secondary,
  fontSize: theme.typography.caption.fontSize,
}));

const TagsContainer = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  flexWrap: 'wrap',
  gap: theme.spacing(1),
  marginTop: theme.spacing(0.5),
}));

const Tag = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.grey[300],
  padding: theme.spacing(0.5, 1.5),
  borderRadius: 16,
  fontSize: theme.typography.caption.fontSize,
  whiteSpace: 'nowrap',
}));

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('zh-TW', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });
};

const PlaylistHeader = ({ playlistName, playlists }) => {
  if (playlistName === 'All') return null;

  const currentPlaylist = playlists.find(
    (playlist) => playlist.playlist_name === playlistName,
  );

  if (!currentPlaylist) return null;

  return (
    <PlaylistContainer>
      <PlaylistInfo elevation={0}>
        <InfoGroup>
          <InfoCaption component="span">描述</InfoCaption>
          <PlaylistDescription>
            {currentPlaylist.description}
          </PlaylistDescription>
        </InfoGroup>

        <MetaContainer>
          <MetaText>建立者：{currentPlaylist.created_by}</MetaText>
          <MetaText>
            建立時間：{formatDate(currentPlaylist.date_created)}
          </MetaText>
        </MetaContainer>

        <InfoGroup>
          <InfoCaption component="span">標籤：</InfoCaption>
          <TagsContainer>
            {currentPlaylist.custom_tags.map((tag, index) => (
              <Tag key={index}>{tag}</Tag>
            ))}
          </TagsContainer>
        </InfoGroup>
      </PlaylistInfo>
    </PlaylistContainer>
  );
};

export default PlaylistHeader;
