import { useState } from 'react';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Checkbox,
  TextField,
} from '@mui/material';
import axios from 'axios';
import Cookies from 'js-cookie';
import './GenerateSignUpLink.scss';

function GenerateSignUpLink() {
  const token = Cookies.get('token');

  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const [generatedLink, setGeneratedLink] = useState('');
  const [accessCode, setAccessCode] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    isCoach: false,
    category: '',
    createdAt: '',
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const generateAccessCode = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let result = '';
    for (let i = 0; i < 6; i++) {
      result += characters.charAt(
        Math.floor(Math.random() * characters.length),
      );
    }
    return result;
  };

  const calculateExpirationDate = () => {
    const today = new Date();
    const expiration = new Date(today.setDate(today.getDate() + 7));
    return expiration.toLocaleDateString();
  };

  const generateSignupLink = () => {
    const today = new Date();
    const formDataWithDate = {
      ...formData,
      createdAt: today.toISOString(),
    };
    const dataString = JSON.stringify(formDataWithDate);
    const encodedData = btoa(dataString);
    return `${process.env.REACT_APP_BASE_URL}/sign-up/${encodedData}`;
  };

  const handleSubmit = async () => {
    if (!formData.name || !formData.category) {
      alert('請填寫所有必要資料');
      return;
    }

    const newAccessCode = generateAccessCode();
    setAccessCode(newAccessCode);
    setExpirationDate(calculateExpirationDate());

    try {
      await axios.post(
        '/api/init-user',
        {
          name: formData.name,
          category: formData.category,
          accessCode: newAccessCode,
          isCoach: formData.isCoach,
          createdAt: new Date().toISOString(),
        },
        {
          headers: {
            Authorization: token,
          },
        },
      );

      const signupLink = generateSignupLink();
      setGeneratedLink(signupLink);
    } catch (error) {
      console.error('Error creating user:', error);
      alert('創建用戶失敗');
    }
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(generatedLink)
      .then(() => {
        alert('已複製連結');
      })
      .catch(() => {
        alert('複製失敗');
      });
  };

  return (
    <>
      <button
        onClick={() => setIsPanelOpen(true)}
        className="admin-add-user-button"
      >
        添加用戶
      </button>

      {isPanelOpen && (
        <div className="admin-signup-link-panel-overlay">
          <div className="panel">
            <h2>新增用戶</h2>
            <div className="admin-sign-up-form-group">
              <span>姓名</span>
              <TextField
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                fullWidth
                variant="filled"
                className="admin-signup-text-field"
              />
            </div>

            <div className="admin-sign-up-form-group">
              <FormControlLabel
                control={
                  <Checkbox
                    name="isCoach"
                    checked={formData.isCoach}
                    onChange={handleChange}
                    sx={{
                      color: '#e50914',
                      '&.Mui-checked': {
                        color: '#e50914',
                      },
                    }}
                  />
                }
                label="教練"
                sx={{
                  color: 'white',
                  '& .MuiTypography-root': {
                    fontSize: '1.5rem',
                  },
                }}
              />
            </div>

            <div className="admin-sign-up-form-group">
              <FormControl>
                <FormLabel>類別：</FormLabel>
                <RadioGroup
                  name="category"
                  value={formData.category}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    value="a"
                    control={
                      <Radio
                        sx={{
                          color: '#8c8c8c',
                          '&.Mui-checked': {
                            color: '#e50914',
                          },
                        }}
                      />
                    }
                    label="一般運動員"
                  />
                  <FormControlLabel
                    value="m"
                    control={
                      <Radio
                        sx={{
                          color: '#8c8c8c',
                          '&.Mui-checked': {
                            color: '#e50914',
                          },
                        }}
                      />
                    }
                    label="智障運動員"
                  />
                  <FormControlLabel
                    value="p"
                    control={
                      <Radio
                        sx={{
                          color: '#8c8c8c',
                          '&.Mui-checked': {
                            color: '#e50914',
                          },
                        }}
                      />
                    }
                    label="肢障運動員"
                  />
                </RadioGroup>
              </FormControl>
            </div>

            {generatedLink && accessCode && (
              <div className="link-container">
                <div className="generated-info">
                  <input
                    type="text"
                    value={generatedLink}
                    readOnly
                    className="generated-link"
                  />
                  <div className="access-code">驗證碼: {accessCode}</div>
                </div>
                <div className="expiration-date">
                  連結有效期限: {expirationDate}
                </div>
                <button onClick={copyToClipboard} className="copy-button">
                  複製連結
                </button>
              </div>
            )}

            <div className="button-group">
              <button onClick={handleSubmit}>生成連結</button>
              <button
                onClick={() => {
                  setIsPanelOpen(false);
                  setGeneratedLink('');
                  setAccessCode('');
                  setExpirationDate('');
                }}
              >
                取消
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default GenerateSignUpLink;
