import { Fragment, useState, useEffect } from 'react';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import axios from 'axios';
import Cookies from 'js-cookie';
import { fetchPlayerDataAPI } from 'src/api/playerService';
import UnverifiedUsers from './UnverifiedUsers';
import UserInfoDrawer from './UserInfoDrawer';
import GenerateSignUpLink from './utils/GenerateSignUpLink';
import { countries } from 'src/constants';
import './UserManagement.scss';

const formatDate = (dateString) => {
  if (!dateString) return '';
  return new Date(dateString).toLocaleDateString('en-US', {
    timeZone: 'Asia/Singapore',
  });
};

export default function UserManagement({
  userData,
  setRefreshData,
  accountType,
}) {
  const token = Cookies.get('token');
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteInputValue, setDeleteInputValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [user_account_type, setUserAccountType] = useState('');
  const [user_category, setUserCategory] = useState('');
  const [user_id, setUserId] = useState('');
  const [username, setUsername] = useState('');
  const [showUnverified, setShowUnverified] = useState(false);

  const unverifiedUsers = userData.filter(
    (user) => user.verified === false || !user.verified,
  );
  const unverifiedCount = unverifiedUsers.length;

  const [players, setPlayers] = useState([]);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleUserInfoClick = (user) => {
    setSelectedUser(user);
    setDrawerOpen(true);
  };

  useEffect(() => {
    const fetchPlayers = async () => {
      try {
        const response = await fetchPlayerDataAPI(
          {
            countries: countries.join(','),
            existingMatch: false,
            accountType: accountType,
          },
          token,
        );
        setPlayers(response.data);
      } catch (error) {
        console.error('Error fetching players:', error);
      }
    };

    fetchPlayers();
  }, [token]);

  const handleEditOpen = (username, category, account_type, user_id) => {
    setUsername(username);
    setUserCategory(category);
    setUserAccountType(account_type);
    setUserId(user_id);
    setEditOpen(true);
  };

  const handleEditClose = () => setEditOpen(false);

  const handleDeleteOpen = (user_id) => {
    setUserId(user_id);
    setDeleteOpen(true);
  };

  const handleDeleteClose = () => {
    setDeleteOpen(false);
    setDeleteInputValue('');
    setErrorMessage('');
  };

  const handleDeleteInputChange = (event) => {
    setDeleteInputValue(event.target.value);
  };

  const handleDeleteUser = (user_id, username, accountType, accountCat) => {
    if (deleteInputValue === 'delete') {
      axios
        .delete('/api/updateUserData', {
          params: {
            user_id: user_id,
            primary_attribute: `deleted username: ${username}`,
            secondary_attribute: `deleted account details: ${accountType}, ${accountCat}`,
          },
          headers: { Authorization: token },
        })
        .then(() => {
          handleDeleteClose();
          setRefreshData(true);
        });
    } else {
      setErrorMessage('Please enter "delete" to confirm the deletion.');
    }
  };

  const handleEditUser = (
    user_id,
    username,
    user_account_type,
    user_category,
  ) => {
    const playerData = selectedPlayer
      ? {
          player_id: selectedPlayer.player_id,
          player_name: selectedPlayer.name,
        }
      : null;

    axios
      .post(
        '/api/updateUserData',
        {
          data: [user_id, username, user_account_type, user_category],
          player: playerData,
        },
        {
          params: {
            primary_attribute: `edited username: ${username}`,
            secondary_attribute: `edited account details: ${user_account_type}, ${user_category}${playerData ? `, linked to player: ${playerData.player_name}` : ''}`,
          },
          headers: { Authorization: token },
        },
      )
      .then(() => {
        handleEditClose();
        setSelectedPlayer(null); // Reset the selected player
        setRefreshData(true);
      })
      .catch((error) => {
        console.error('Error updating user:', error);
        // Handle error appropriately
      });
  };

  return (
    <Fragment>
      <div className="user-management-header">
        <div className="verification-button-container">
          <button
            onClick={() => setShowUnverified(!showUnverified)}
            className={`verification-button ${showUnverified ? 'active' : ''}`}
          >
            {showUnverified ? 'Show All Users' : 'Show Unverified Users'}
            {!showUnverified && unverifiedCount > 0 && (
              <span className="unverified-count">{unverifiedCount}</span>
            )}
          </button>
        </div>
        <GenerateSignUpLink />
      </div>
      {showUnverified ? (
        <UnverifiedUsers
          users={unverifiedUsers}
          setRefreshData={setRefreshData}
        />
      ) : (
        <div className="table-container">
          <table className="user-table">
            <thead>
              <tr>
                <th>用戶名</th>
                <th>運動員</th>
                <th>帳戶類型</th>
                <th>類別</th>
                <th>最後位置</th>
                <th>最後登入</th>
                <th>編輯</th>
                <th>刪除</th>
              </tr>
            </thead>
            <tbody>
              {userData.map((user) => (
                <tr key={user.user_id}>
                  <td>{user.username}</td>
                  <td>{user.user_player_name}</td>
                  <td>{user.account_type}</td>
                  <td>{user.category}</td>
                  <td>{user.last_ip_address}</td>
                  <td>{formatDate(user.last_log_in)}</td>
                  <td>
                    <button
                      className="user-info-button"
                      onClick={() => handleUserInfoClick(user)}
                    >
                      <InfoOutlinedIcon />
                    </button>
                    <button
                      className="icon-button"
                      onClick={() =>
                        handleEditOpen(
                          user.username,
                          user.category,
                          user.account_type,
                          user.user_id,
                        )
                      }
                      title="編輯數據"
                    >
                      <EditOutlinedIcon />
                    </button>
                  </td>
                  <td>
                    <button
                      className="icon-button"
                      onClick={() => handleDeleteOpen(user.user_id)}
                      title="刪除此用戶"
                    >
                      <CloseOutlinedIcon />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          <UserInfoDrawer
            open={drawerOpen}
            onClose={() => setDrawerOpen(false)}
            userData={selectedUser}
          />
          {deleteOpen && (
            <div className="modal-overlay">
              <div className="modal">
                <h2>請輸入「delete」以刪除</h2>
                <div className="modal-content">
                  <input
                    type="text"
                    value={deleteInputValue}
                    onChange={handleDeleteInputChange}
                    className={`delete-input ${errorMessage ? 'error' : ''}`}
                    placeholder="Type 'delete' to confirm"
                  />
                  {errorMessage && (
                    <p className="error-message">{errorMessage}</p>
                  )}
                </div>
                <div className="modal-actions">
                  <button
                    onClick={handleDeleteClose}
                    className="button secondary"
                  >
                    取消
                  </button>
                  <button
                    onClick={() =>
                      handleDeleteUser(
                        user_id,
                        username,
                        user_account_type,
                        user_category,
                      )
                    }
                    className="button primary"
                  >
                    刪除
                  </button>
                </div>
              </div>
            </div>
          )}

          {editOpen && (
            <div className="modal-overlay">
              <div className="modal">
                <h2>在此編輯用戶</h2>
                <div className="modal-content">
                  <div className="input-group">
                    <label>Username</label>
                    <input
                      type="text"
                      value={username}
                      onChange={(e) => setUsername(e.target.value)}
                    />
                  </div>
                  <div className="input-group">
                    <label>Player</label>
                    <Autocomplete
                      options={players}
                      getOptionLabel={(option) =>
                        `${option.player_name} (${option.country})`
                      }
                      value={selectedPlayer}
                      onChange={(event, newValue) => {
                        setSelectedPlayer(newValue);
                      }}
                      renderOption={(props, option) => (
                        <li {...props}>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              gap: '10px',
                            }}
                          >
                            <span>{option.player_name}</span>
                            <span style={{ color: '#666', fontSize: '0.9em' }}>
                              ({option.country}, Rank: {option.current_rank})
                            </span>
                          </div>
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          placeholder="Search player by name"
                          size="small"
                        />
                      )}
                      isOptionEqualToValue={(option, value) =>
                        option.player_id === value?.player_id
                      }
                    />
                  </div>
                  <div className="input-group">
                    <label>Account type</label>
                    <select
                      value={user_account_type}
                      onChange={(e) => setUserAccountType(e.target.value)}
                    >
                      <option value="sub_admin">sub_admin</option>
                      <option value="coach">coach</option>
                      <option value="player">player</option>
                    </select>
                  </div>
                  <div className="input-group">
                    <label>Category</label>
                    <select
                      value={user_category}
                      onChange={(e) => setUserCategory(e.target.value)}
                    >
                      <option value="able">able</option>
                      <option value="intellectual">intellectual</option>
                      <option value="physical">physical</option>
                    </select>
                  </div>
                </div>
                <div className="modal-actions">
                  <button
                    onClick={handleEditClose}
                    className="button secondary"
                  >
                    取消
                  </button>
                  <button
                    onClick={() =>
                      handleEditUser(
                        user_id,
                        username,
                        user_account_type,
                        user_category,
                      )
                    }
                    className="button primary"
                  >
                    確認
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </Fragment>
  );
}
