import React from 'react';
import { Link } from 'react-router-dom';
import DownloadIcon from '@mui/icons-material/Download';
import VisibilityIcon from '@mui/icons-material/Visibility';
import SourceIcon from '@mui/icons-material/Source';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import './VideoSettingPanel.scss';

interface Props {
  userInfo: {
    isCoach: boolean;
    admin: boolean;
  };
  matchData: {
    matchId: string;
    fileUrl: string;
    reportUrl: string;
  };
  setReplayData: (data: any) => void;
  replayData: {
    ballReplayTimes: string;
    highlightReplayTimes: string;
    replayDeuce: boolean;
    replayCriticalPoint: boolean;
  };
  videoData: {
    isCoachPOV: boolean;
    skipped: boolean;
  };
  setVideoData: (data: any) => void;
}

const VideoSettingPanel: React.FC<Props> = ({
  userInfo,
  matchData,
  setReplayData,
  replayData,
  videoData,
  setVideoData,
}) => {
  const handleBallReplayChange = (
    event: React.MouseEvent<HTMLElement>,
    value: string
  ) => {
    setReplayData((prevData: any) => ({
      ...prevData,
      ballReplayTimes: value,
    }));
    setVideoData((prevStatus: any) => ({
      ...prevStatus,
      skipped: true,
    }));
  };

  const handleHighlightReplayChange = (
    event: React.MouseEvent<HTMLElement>,
    value: string
  ) => {
    setReplayData((prevData: any) => ({
      ...prevData,
      highlightReplayTimes: value,
    }));
    setVideoData((prevStatus: any) => ({
      ...prevStatus,
      skipped: true,
    }));
  };

  const handleDeuceChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReplayData((prevData: any) => ({
      ...prevData,
      replayDeuce: event.target.checked,
    }));
    setVideoData((prevStatus: any) => ({
      ...prevStatus,
      skipped: true,
    }));
  };

  const handleCriticalPointChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setReplayData((prevData: any) => ({
      ...prevData,
      replayCriticalPoint: event.target.checked,
    }));
    setVideoData((prevStatus: any) => ({
      ...prevStatus,
      skipped: true,
    }));
  };

  const handlePOVChange = () => {
    setVideoData((prevState: any) => ({
      ...prevState,
      isCoachPOV: !videoData.isCoachPOV,
    }));
  };

  return (
    <div className="video-setting-main">
      <div className="video-prop-setting-panel">
        <Link
          className="editVideo"
          to={`/edit-metadata/${matchData.matchId}/0`}
          title="管理視頻數據"
        >
          <SourceIcon /> 編輯數據
        </Link>
        {userInfo.admin && (
          <Link
            className="editVideo"
            to={`/edit-metadata/${matchData.matchId}/2`}
            title="管理視頻數據"
          >
            <SourceIcon /> 編輯分析數據
          </Link>
        )}
      </div>

      <div className="other-setting-container">
        <button
          className={`coachPOV ${videoData.isCoachPOV ? 'on' : ''}`}
          onClick={handlePOVChange}
          title="教練視角"
        >
          <VisibilityIcon />
          教练視角
        </button>

        <div className="ball-replay-option-panel">
          <span className="ball-replay-span">球重播次數</span>
          <ToggleButtonGroup
            color="error"
            value={replayData.ballReplayTimes}
            exclusive
            onChange={handleBallReplayChange}
            aria-label="Platform"
            title="重播每一球"
          >
            {["1", "2", "5", "10", "99999"].map((value, index) => (
              <ToggleButton
                key={value}
                value={value}
                className={replayData.ballReplayTimes === value ? 'blink-red' : ''}
              >
                {value === "99999" ? "無限" : value === "1" ? "0" : value}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>

          <div className="ball-replay-options">
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  checked={replayData.replayDeuce}
                  color="error"
                  onChange={handleDeuceChange}
                  title="重播僅平分球"
                />
              }
              label="Deuce"
            />
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  checked={replayData.replayCriticalPoint}
                  color="error"
                  onChange={handleCriticalPointChange}
                  title="重播僅關鍵分"
                />
              }
              label="關鍵分"
            />
          </div>
        </div>

        <div className="highlight-replay-option-panel">
          <span className="highlight-replay-span">亮點重播次數</span>
          <ToggleButtonGroup
            color="error"
            value={replayData.highlightReplayTimes}
            exclusive
            onChange={handleHighlightReplayChange}
            aria-label="Platform"
            title="重播每個亮點"
          >
            {["1", "2", "5", "10", "99999"].map((value, index) => (
              <ToggleButton
                key={value}
                value={value}
                className={replayData.highlightReplayTimes === value ? 'blink-red' : ''}
              >
                {value === "99999" ? "無限" : value === "1" ? "0" : value}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </div>

        <div className="download-options">
          {userInfo.isCoach && (
            <>
              <a className="video-download" href="/csReport" title="直/曲球分析">
                <DownloadIcon />
                接發球分析
              </a>
              <a
                className="video-download"
                href={matchData.fileUrl}
                download
                title="下載視頻"
              >
                <DownloadIcon />
                下载视频
              </a>
            </>
          )}

          {matchData.reportUrl !== '/reports/null' ? (
            <a
              className="report-download"
              href={matchData.reportUrl}
              download
              title="下載報告"
            >
              <DownloadIcon />
              下载報告
            </a>
          ) : (
            <span className="no-report" title="没有報告">
              <NotInterestedIcon />
              没有報告
            </span>
          )}
        </div>
      </div>
      <p className="padding"></p>
    </div>
  );
};

export default VideoSettingPanel;