import { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { useParams } from 'react-router-dom';

import axios from 'axios';
import { Snackbar, SnackbarContent } from '@mui/material';

import Cookies from 'js-cookie';

const defaultTheme = createTheme();

export default function SignUp() {
  const token = Cookies.get('token');
  const { hashedInfo } = useParams();
  const [userData, setUserData] = useState(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  useEffect(() => {
    try {
      const decodedString = atob(hashedInfo);
      const parsedData = JSON.parse(decodedString);

      // Check if link is expired
      const createdDate = new Date(parsedData.createdAt);
      const currentDate = new Date();
      const differenceInDays =
        (currentDate - createdDate) / (1000 * 60 * 60 * 24);

      if (differenceInDays > 7) {
        setSnackbarMessage('註冊連結已過期');
        setSnackbarOpen(true);
        // Optionally redirect or disable the form
        return;
      }

      // If not expired, proceed with setting form values
      setUserData(parsedData);
    } catch (error) {
      console.error('Error processing link:', error);
      setSnackbarMessage('無效的註冊連結');
      setSnackbarOpen(true);
    }
  }, [hashedInfo]);

  const getCategoryDisplay = (category) => {
    switch (category) {
      case 'a':
        return '一般運動員';
      case 'm':
        return '智能障礙運動員';
      case 'p':
        return '肢體障礙運動員';
      default:
        return '';
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    // Add userData values to the form data
    data.append('accountType', userData?.category || '');
    data.append('coach', userData?.isCoach || false);
    data.append('name', userData?.name || '');

    axios
      .post('/api/signup', data, {
        params: {
          primary_attribute: '新用戶名稱: ' + data.get('userName'),
          secondary_attribute: '新用戶電子郵件: ' + data.get('email'),
        },
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        setSnackbarMessage('帳戶已創建，請等待驗證');
        setSnackbarOpen(true);
      })
      .catch((error) => {
        if (error.response) {
          setSnackbarMessage(error.response.data.error);
        } else {
          setSnackbarMessage('未經授權，無效的連結或存取碼，請聯繫管理員');
        }
        setSnackbarOpen(true);
        setSnackbarMessage('未經授權，無效的連結或存取碼，請聯繫管理員');
      });
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            歡迎 {userData?.name ? userData.name : '使用者'}
          </Typography>
          <Box sx={{ mt: 2, mb: 2, textAlign: 'center' }}>
            <Typography color="text.secondary">
              身份：{userData?.isCoach ? '教練' : '運動員'}
            </Typography>
            <Typography color="text.secondary">
              類別：{getCategoryDisplay(userData?.category)}
            </Typography>
          </Box>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="user-name"
                  name="userName"
                  required
                  fullWidth
                  id="userName"
                  label="使用者名稱"
                  autoFocus
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="電子郵件"
                  name="email"
                  autoComplete="email"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="密碼"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="accesscode"
                  label="驗證碼"
                  type="accesscode"
                  id="accesscode"
                  autoComplete="access-code"
                />
              </Grid>
            </Grid>

            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              註冊
            </Button>

            <Snackbar
              open={snackbarOpen}
              autoHideDuration={5000}
              onClose={handleSnackbarClose}
              anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
              style={{
                position: 'fixed',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
              }}
            >
              <SnackbarContent
                style={{
                  backgroundColor: 'rgba(0, 0, 0, 0.9)',
                  minWidth: '25vw',
                  minHeight: '25vh',
                  padding: '1rem 1.5rem',
                  fontSize: '2.125rem',
                  fontWeight: '500',
                  borderRadius: '0.25rem',
                  boxShadow: '0 0.25rem 0.5rem rgba(0, 0, 0, 0.2)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
                message={snackbarMessage}
              />
            </Snackbar>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}
