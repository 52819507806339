// SignalGraph.tsx
import { FC, useEffect, useRef } from "react";
import { Box, useTheme, useMediaQuery } from "@mui/material";
import "@react-sigma/core/lib/react-sigma.min.css";
import NEWSelectionContainers from "./components/selection/NEWSelectionContainers";

import useGraphStore from "./GraphVisualization/graphStore";
import useStore from "./store/store";
import GraphVisualization from "./GraphVisualization/GraphVisualization";
import LoadingSpinner from "./components/Loading/LoadingSpinner";
import { useWatchPlayers } from "./hook/useWatchPlayers";

export const SignalGraph: FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const DEBUG_MODE = true;

  const containerRef = useRef<HTMLDivElement>(null);
  const players = useStore((state) => state.players);
  const currentCategory = useStore((state) => state.currentCategory);

  const { initializeFromPlayers, setDebugMode } = useGraphStore();

  useEffect(() => {
    setDebugMode(DEBUG_MODE);
  }, [DEBUG_MODE, setDebugMode]);

  useEffect(() => {
    if (players && currentCategory) {
      console.log("Initializing graph with:", {
        categoryPlayers: players[currentCategory],
        category: currentCategory,
      });
      initializeFromPlayers(players, currentCategory);
    }
  }, [players, currentCategory, initializeFromPlayers]);

  const containerStyles = {
    height: isMobile ? "calc(100dvh - 40px)" : "calc(100vh - 40px)",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    position: "relative",
  };

  const mainStoreLoading = useStore((state) => state.isLoading);
  const graphStoreLoading = useGraphStore((state) => state.isLoading);

  // Combine loading states
  const isLoading = mainStoreLoading || graphStoreLoading;

  useWatchPlayers();
  return (
    <Box ref={containerRef} sx={containerStyles}>
      {/* Results Panel Area */}
      <Box
        sx={{
          flex: 1,
          overflow: "auto",
          p: 1,
          position: "relative",
        }}
      >
        <NEWSelectionContainers />

        {isLoading && (
          <LoadingSpinner
            fullScreen
            icon="cloud"
            color="secondary"
            message="Loading graph data..."
          />
        )}
      </Box>

      {/* Graph Area */}
      <GraphVisualization />
    </Box>
  );
};
export default SignalGraph;
