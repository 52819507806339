import React, { useState } from 'react';
import { GridView, ViewList, Search } from '@mui/icons-material';
import { TextField, InputAdornment, Box } from '@mui/material';
import { PlayerData } from 'types/playerTypes';
import PlaylistGrid from './PlaylistGrid';
import './PlaylistVideoPanel.scss';

interface VideoListItem {
    matchId: string;
    videoName: string;
    stage: string;
    date: string;
    location: string;
    homePlayer: string;
    awayPlayer: string;
    TNUrl: string;
    subEvent: string;
    category: string;
    uploadUser: string;
    homePlayerMatchScore: string;
    awayPlayerMatchScore: string;
    FileUrl: string;
}

interface PlaylistVideoPanelProps {
    videoList: VideoListItem[];
    addedVideoIds: Set<string>;
    onAddVideo: (video: VideoListItem) => void;
    playerList: PlayerData[],
    searchTerm: string,
    groupingMethod: string,
    viewMode: string
}

const PlaylistVideoPanel: React.FC<PlaylistVideoPanelProps> = ({
    videoList,
    addedVideoIds,
    onAddVideo,
    playerList,
    searchTerm,
    groupingMethod,
    viewMode
}) => {

    const filteredVideos = videoList.filter(video =>
        video.videoName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        video.homePlayer.toLowerCase().includes(searchTerm.toLowerCase()) ||
        video.awayPlayer.toLowerCase().includes(searchTerm.toLowerCase())
    );


    const renderListItem = (video: VideoListItem) => (
        <div className="video-content">
            <h4>{video.videoName}</h4>
            <div className="video-info">
                <div className="list-header">
                    <p>{video.homePlayer} vs {video.awayPlayer} ({video.homePlayerMatchScore} - {video.awayPlayerMatchScore})</p>
                    <p>{video.date.slice(-4)} | {video.stage}</p>
                </div>
                <button
                    onClick={() => onAddVideo(video)}
                    disabled={addedVideoIds.has(video.matchId.toString())}
                >
                    {addedVideoIds.has(video.matchId.toString()) ? '已添加' : '添加'}
                </button>
            </div>
        </div>
    );

    const renderListView = () => (
        <div className="video-container list">
            {filteredVideos.map((video) => (
                <div
                    key={video.matchId}
                    className={`video-item ${addedVideoIds.has(video.matchId) ? 'disabled' : ''}`}
                >
                    {renderListItem(video)}
                </div>
            ))}
        </div>
    );


    return (
        <div className="playlist-video-panel">
            {viewMode === 'grid' ? (
                <PlaylistGrid
                    videos={filteredVideos}
                    addedVideoIds={addedVideoIds}
                    onAddVideo={onAddVideo}
                    playerList={playerList}
                    groupingMethod={groupingMethod}
                />
            ) : renderListView()}
        </div>
    );
};

export default PlaylistVideoPanel;