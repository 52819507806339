import React, { useState } from 'react';
import Slider from '@mui/material/Slider';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import Forward5Icon from '@mui/icons-material/Forward5';
import Replay5Icon from '@mui/icons-material/Replay5';
import ProgressBar from './ProgressBar';
import './VideoControlBar.scss';

const VideoControlBar = ({
  videoRef,
  videoData,
  setVideoData,
  duration,
  currentTime,
  setCurrentTime,
  isFullscreen,
  showControls,
  roundResults,
  handlePlayPause,
  handleSeekForward,
  handleSeekBackward,
  handleSeekForwardFrame,
  handleSeekBackwardFrame,
  toggleFullscreen,
  showPreview,
}) => {
  const [volume, setVolume] = useState(1);
  const [isMuted, setIsMuted] = useState(false);
  const [showSpeedMenu, setShowSpeedMenu] = useState(false);
  const [showVolumeControl, setShowVolumeControl] = useState(false);
  const [playbackSpeed, setPlaybackSpeed] = useState(1);
  const [seekPreview, setSeekPreview] = useState({
    visible: false,
    time: 0,
    position: 0,
  });

  const speedOptions = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];

  const toggleMute = () => {
    if (!videoRef.current) return;
    if (isMuted) {
      videoRef.current.volume = volume || 1;
      setIsMuted(false);
    } else {
      videoRef.current.volume = 0;
      setIsMuted(true);
    }
  };

  const handleSpeedChange = (speed) => {
    if (!videoRef.current) return;
    videoRef.current.playbackRate = speed;
    setPlaybackSpeed(speed);
    setShowSpeedMenu(false);
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const handleSeekMouseMove = (e) => {
    const rect = e.currentTarget.getBoundingClientRect();
    const position = e.clientX - rect.left;
    const percentage = position / rect.width;
    const previewTime = percentage * duration;

    setSeekPreview({
      visible: true,
      time: previewTime,
      position: position,
    });
  };

  const handleSeekMouseLeave = () => {
    setSeekPreview({
      visible: false,
      time: seekPreview.time,
      position: seekPreview.position,
    });
  };

  return (
    <div className={`custom-controls ${showControls ? 'visible' : 'hidden'}`}>
      <ProgressBar
        duration={duration}
        currentTime={currentTime}
        setCurrentTime={setCurrentTime}
        roundResults={roundResults}
        seekPreview={seekPreview}
        onMouseMove={handleSeekMouseMove}
        onMouseLeave={handleSeekMouseLeave}
        videoRef={videoRef}
        showPreview={showPreview}
      />
      <div className="controls-bar">
        <div className="left-controls">
          <button
            onClick={() => handlePlayPause(videoRef, videoData, setVideoData)}
            className="control-button"
          >
            {videoData.isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
          </button>
          <button
            onClick={() => handleSeekForwardFrame(videoRef, setVideoData)}
            className="control-button"
          >
            <SkipPreviousIcon />
          </button>
          <button
            onClick={() => handleSeekBackwardFrame(videoRef, setVideoData)}
            className="control-button"
          >
            <SkipNextIcon />
          </button>
          <button
            onClick={() => handleSeekBackward(videoRef, setVideoData)}
            className="control-button"
          >
            <Replay5Icon />
          </button>
          <button
            onClick={() => handleSeekForward(videoRef, setVideoData)}
            className="control-button"
          >
            <Forward5Icon />
          </button>
          <div className="volume-container">
            <button
              onClick={toggleMute}
              onMouseEnter={() => setShowVolumeControl(true)}
              className="control-button"
            >
              {isMuted ? <VolumeOffIcon /> : <VolumeUpIcon />}
            </button>
            <div
              className={`volume-slider ${showVolumeControl ? 'visible' : ''}`}
              onMouseLeave={() => setShowVolumeControl(false)}
            >
              <Slider
                size="small"
                min={0}
                max={1}
                step={0.1}
                value={isMuted ? 0 : volume}
                onChange={(_, newValue) => {
                  const newVolume = parseFloat(newValue);
                  if (videoRef.current) {
                    videoRef.current.volume = newVolume;
                  }
                  setVolume(newVolume);
                  setIsMuted(newVolume === 0);
                }}
                sx={{
                  width: 100,
                  color: 'white',
                  '& .MuiSlider-thumb': {
                    width: 12,
                    height: 12,
                  },
                  '& .MuiSlider-track': {
                    height: 4,
                  },
                  '& .MuiSlider-rail': {
                    height: 4,
                  },
                }}
              />
            </div>
          </div>
          <div className="time-display">
            <span>{formatTime(currentTime)}</span>
            <span> / </span>
            <span>{formatTime(duration)}</span>
          </div>
        </div>
        <div className="right-controls">
          <div className="speed-control">
            <button
              onClick={() => setShowSpeedMenu(!showSpeedMenu)}
              className="control-button"
            >
              {playbackSpeed}x
            </button>
            {showSpeedMenu && (
              <div className="speed-menu">
                {speedOptions.map((speed) => (
                  <button
                    key={speed}
                    onClick={() => handleSpeedChange(speed)}
                    className={`speed-option ${
                      playbackSpeed === speed ? 'active' : ''
                    }`}
                  >
                    {speed}x
                  </button>
                ))}
              </div>
            )}
          </div>
          <button onClick={toggleFullscreen} className="control-button">
            {isFullscreen ? <FullscreenExitIcon /> : <FullscreenIcon />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default VideoControlBar;
