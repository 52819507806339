// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.video-container {
  position: relative;
  width: 100%;
  background: black;
}

.video-wrapper {
  position: relative;
  width: 100%;
}

.video-player {
  width: 100%;
  display: block;
}

@media (orientation: landscape) {
  .video-player {
    object-fit: contain;
    max-height: 100vh;
  }
}`, "",{"version":3,"sources":["webpack://./src/components/VideoPlayer/ImprovedVP2.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,WAAA;EACA,iBAAA;AACJ;;AAEE;EACE,kBAAA;EACA,WAAA;AACJ;;AAEE;EACE,WAAA;EACA,cAAA;AACJ;;AAGE;EACE;IACE,mBAAA;IACA,iBAAA;EAAJ;AACF","sourcesContent":[".video-container {\n    position: relative;\n    width: 100%;\n    background: black;\n  }\n  \n  .video-wrapper {\n    position: relative;\n    width: 100%;\n  }\n  \n  .video-player {\n    width: 100%;\n    display: block;\n  }\n  \n  \n  @media (orientation: landscape) {\n    .video-player {\n      object-fit: contain;\n      max-height: 100vh;\n    }\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
