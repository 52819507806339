import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { Button } from '@mui/material';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';
import { PlayerData } from 'types/playerTypes';
import { interestedCountries } from 'src/constants';
import './PlaylistGrid.scss';

interface VideoListItem {
    matchId: string;
    videoName: string;
    stage: string;
    date: string;
    location: string;
    homePlayer: string;
    awayPlayer: string;
    TNUrl: string;
    subEvent: string;
    category: string;
    uploadUser: string;
    homePlayerMatchScore: string;
    awayPlayerMatchScore: string;
    FileUrl: string;
}

interface PlaylistGridProps {
    videos: VideoListItem[];
    addedVideoIds: Set<string>;
    onAddVideo: (video: VideoListItem) => void;
    playerList: PlayerData[],
    groupingMethod: string,
}

interface SliderRefs {
    [key: string]: HTMLDivElement | null;
}

interface LazyImageProps {
    src: string;
    alt: string;
    className?: string;
}

interface VideoRowProps {
    videos: VideoListItem[];
    onVideoClick: (video: VideoListItem) => void;
    addedVideoIds: Set<string>;
    sliderRef: (el: HTMLDivElement | null) => void;
    onScroll: (direction: number) => void;
}

type GroupedVideos = {
    [key: string]: VideoListItem[];
};


const LazyImage: React.FC<LazyImageProps> = React.memo(({ src, alt, className }) => {
    const imgRef = useRef<HTMLImageElement>(null);
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        if (!imgRef.current) return;

        const observer = new IntersectionObserver(
            (entries) => {
                const entry = entries[0];
                if (entry.isIntersecting && imgRef.current) {
                    imgRef.current.src = src;
                    observer.unobserve(entry.target);
                }
            },
            { rootMargin: '50px' }
        );

        observer.observe(imgRef.current);
        return () => observer.disconnect();
    }, [src]);

    return (
        <img
            ref={imgRef}
            className={`${className} ${isLoaded ? 'loaded' : 'loading'}`}
            alt={alt}
            onLoad={() => setIsLoaded(true)}
            loading="lazy"
        />
    );
});

const VideoRow: React.FC<VideoRowProps> = React.memo(({
    videos,
    onVideoClick,
    addedVideoIds,
    sliderRef,
    onScroll
}) => {
    const observerRef = useRef<IntersectionObserver | null>(null);
    const containerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        const options: IntersectionObserverInit = {
            root: null,
            rootMargin: '20px',
            threshold: 0.1
        };

        observerRef.current = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    const video = entry.target as HTMLElement;
                    video.classList.add('visible');
                }
            });
        }, options);

        const thumbnails = containerRef.current?.querySelectorAll('.video-thumbnail');
        thumbnails?.forEach(thumbnail => observerRef.current?.observe(thumbnail));

        return () => observerRef.current?.disconnect();
    }, [videos]);

    const handleScroll = useCallback((direction: number) => {
        onScroll(direction);
    }, [onScroll]);

    return (
        <div className="video-slider-container">
            <button
                className="scroll-arrow left"
                onClick={() => handleScroll(-1)}
            >
                ←
            </button>
            <div
                ref={(el) => {
                    containerRef.current = el;
                    sliderRef(el);
                }}
                className="video-slider"
            >
                {videos.map((video) => (
                    <div
                        key={video.matchId}
                        className="video-thumbnail"
                        onClick={() => !addedVideoIds.has(video.matchId) && onVideoClick(video)}
                    >
                        {addedVideoIds.has(video.matchId.toString()) && (
                            <CheckCircleOutlineOutlinedIcon className="checkmark-icon" />
                        )}
                        <LazyImage src={video.TNUrl} alt={video.videoName} />
                        <div className="thumbnail-info">
                            <p className="video-title">{video.videoName}</p>
                        </div>
                    </div>
                ))}
            </div>
            <button
                className="scroll-arrow right"
                onClick={() => handleScroll(1)}
            >
                →
            </button>
        </div>
    );
});



const PlaylistGrid: React.FC<PlaylistGridProps> = ({ videos, addedVideoIds, onAddVideo, playerList, groupingMethod }) => {
    const [selectedVideo, setSelectedVideo] = useState<VideoListItem | null>(null);
    const sliderRefs = useRef<SliderRefs>({});

    const eventList = ["Olympic", "WTT", "ITTF", "National Games", "Asian Games", "Olympic Games", "Universiade",
        "World Abilitysport Games", "Virtus Global Games", "Asian Para Games", "Paralympic Games",
        "ITTF World Championships", "ITTF Mixed Team World Cup", "ITTF World Youth Championships",
        "Asia Continental Stage of 2023 WTTC Finals", "championships", "Contender", "Star Contender",
        "WTT Feeder Series", "Champions", "Cup Finals", "Grand Smash", "WTT Youth Series",
        "Games"];

    const getEventCategory = useCallback((subEvent: string): string => {
        const normalizedSubEvent = subEvent.toLowerCase();
        return eventList.find(event =>
            normalizedSubEvent.includes(event.toLowerCase())) || 'Others';
    }, [eventList]);


    const handleVideoClick = (video: VideoListItem) => {
        if (!addedVideoIds.has(video.matchId)) {
            setSelectedVideo(video);
        }
    };

    const getPlayerRanking = useCallback((playerName: string): number => {
        return playerList.find(p =>
            p.player_name.toLowerCase() === playerName.toLowerCase())?.current_rank ?? 999999;
    }, [playerList]);

    const getPlayerCountry = useCallback((playerName: string): string => {
        return playerList.find(p =>
            p.player_name.toLowerCase() === playerName.toLowerCase())?.country ?? 'Unknown Country';
    }, [playerList]);


    const groupedVideos = useMemo<GroupedVideos>(() => {
        // Pre-compute maps for faster lookups
        const eventMap = new Map(eventList.map((event, index) => [event, index]));
        const countryMap = new Map(interestedCountries.map((country, index) => [country, index]));

        let grouped: GroupedVideos = {};

        // Initial grouping 
        switch (groupingMethod) {
            case 'events': {
                // Create indexed map for faster sorting
                const indexedGroups = videos.reduce<Map<string, VideoListItem[]>>((acc, video) => {
                    const category = getEventCategory(video.subEvent || '');
                    if (!acc.has(category)) {
                        acc.set(category, []);
                    }
                    acc.get(category)!.push(video);
                    return acc;
                }, new Map());

                // Convert to array, sort, and convert back to object
                const sortedEntries = Array.from(indexedGroups.entries())
                    .sort((a, b) => {
                        const indexA = eventMap.get(a[0]);
                        const indexB = eventMap.get(b[0]);

                        if (indexA !== undefined && indexB !== undefined) {
                            return indexA - indexB;
                        }
                        if (indexA !== undefined) return -1;
                        if (indexB !== undefined) return 1;
                        return a[0].localeCompare(b[0]); // alphabetical for non-listed events
                    });

                grouped = Object.fromEntries(sortedEntries);
                break;
            }

            case 'countries': {
                const indexedGroups = videos.reduce<Map<string, VideoListItem[]>>((acc, video) => {
                    [video.homePlayer, video.awayPlayer].forEach(player => {
                        const country = getPlayerCountry(player);
                        if (!acc.has(country)) {
                            acc.set(country, []);
                        }
                        acc.get(country)!.push(video);
                    });
                    return acc;
                }, new Map());

                const sortedEntries = Array.from(indexedGroups.entries())
                    .sort((a, b) => {
                        const indexA = countryMap.get(a[0]);
                        const indexB = countryMap.get(b[0]);

                        if (indexA !== undefined && indexB !== undefined) {
                            return indexA - indexB;
                        }
                        if (indexA !== undefined) return -1;
                        if (indexB !== undefined) return 1;
                        return a[0].localeCompare(b[0]);
                    });

                grouped = Object.fromEntries(sortedEntries);
                break;
            }

            case 'players': {
                const rankingCache = new Map<string, number>();
                const indexedGroups = videos.reduce<Map<string, VideoListItem[]>>((acc, video) => {
                    [video.homePlayer, video.awayPlayer].forEach(player => {
                        if (!acc.has(player)) {
                            acc.set(player, []);
                            // Cache the ranking on first encounter
                            if (!rankingCache.has(player)) {
                                rankingCache.set(player, getPlayerRanking(player));
                            }
                        }
                        acc.get(player)!.push(video);
                    });
                    return acc;
                }, new Map());

                const sortedEntries = Array.from(indexedGroups.entries())
                    .sort((a, b) => {
                        const rankA = rankingCache.get(a[0]) ?? 999999;
                        const rankB = rankingCache.get(b[0]) ?? 999999;
                        return rankA - rankB;
                    });

                grouped = Object.fromEntries(sortedEntries);
                break;
            }
        }

        return grouped;
    }, [videos, groupingMethod, getEventCategory, getPlayerCountry, getPlayerRanking]);

    const scroll = useCallback((category: string, direction: number) => {
        const slider = sliderRefs.current[category];
        if (slider) {
            const scrollAmount = window.innerWidth < 768 ? 160 : 240;
            slider.scrollTo({
                left: slider.scrollLeft + (direction * scrollAmount),
                behavior: 'smooth'
            });
        }
    }, []);


    const renderPlayerHeader = (playerName: string) => {
        const player = playerList.find(p =>
            p.player_name.toLowerCase() === playerName.toLowerCase());

        if (player) {
            return (
                <div className="player-header">
                    <span className="player-name">{playerName}</span>
                    {player.current_rank && (
                        <span className="player-rank">Rank: #{player.current_rank}</span>
                    )}
                    <span className="player-country">{player.country}</span>
                </div>
            );
        }

        return <h3 className="row-title">{playerName}</h3>;
    };

    return (
        <div className="playlist-grid">
            {selectedVideo && (
                <div className="featured-video">
                    <div className="featured-content">
                        <button
                            onClick={() => setSelectedVideo(null)}
                            className='remove-feature-content-button'
                        >
                            Close
                        </button>
                        <video
                            src={selectedVideo.FileUrl}
                            className="featured-video"
                            controls
                            autoPlay
                            muted
                            preload="auto"
                            playsInline
                            onLoadedMetadata={(e: React.SyntheticEvent<HTMLVideoElement>) => {
                                const video = e.target as HTMLVideoElement;
                                video.currentTime = video.duration / 2;
                            }}
                        />
                        <div className="featured-info">
                            <h2>{selectedVideo.videoName}</h2>
                            <p>{selectedVideo.homePlayer} vs {selectedVideo.awayPlayer}</p>
                            <p className="score">
                                {selectedVideo.homePlayerMatchScore} - {selectedVideo.awayPlayerMatchScore}
                            </p>
                            <p className="details">
                                {selectedVideo.date} | {selectedVideo.stage} | {selectedVideo.location}
                            </p>
                            <Button
                                variant="contained"
                                className="add-button"
                                onClick={() => onAddVideo(selectedVideo)}
                                disabled={addedVideoIds.has(selectedVideo.matchId.toString())}
                            >
                                {addedVideoIds.has(selectedVideo.matchId.toString()) ? '已添加' : '添加'}
                            </Button>
                        </div>
                    </div>
                </div>
            )}

            <div className="videos-container">
                {Object.entries(groupedVideos).map(([category, categoryVideos]) => (
                    <div key={category} className="video-row">
                        {groupingMethod === 'players'
                            ? renderPlayerHeader(category)
                            : <h3 className="row-title">{category}</h3>
                        }
                        <VideoRow
                            videos={categoryVideos}
                            onVideoClick={handleVideoClick}
                            addedVideoIds={addedVideoIds}
                            sliderRef={el => sliderRefs.current[category] = el}
                            onScroll={(direction) => scroll(category, direction)}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default PlaylistGrid;