// VideoCard.js
import { VideoThumbnail } from './VideoThumbnail';
import { VideoMetadata } from './VideoMetadata';
import { VideoScore } from './VideoScore';
import { VideoActions } from './VideoActions';
import { VideoDetails } from './VideoDetails';
import AddPlaylist from 'src/components/AddPlaylist/AddPlaylist';
import { Card, CardContent, Box } from '@mui/material';

export const VideoCard = ({ videoData, isPin = false, userInfo }) => {
  const videoName =
    userInfo.accountType !== 'a'
      ? videoData.videoName
      : `${videoData.homePlayer} vs ${videoData.awayPlayer} (${videoData.subEvent})`;
  return (
    <Card
      sx={{
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 2,
        '@media (min-width: 600px)': {
          flexDirection: 'row',
        },
      }}
    >
      <VideoThumbnail
        videoUrl={videoData.fileUrl}
        thumbnailUrl={videoData.TNUrl}
        isPin={isPin}
      />

      <Box sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            flexGrow: 1,
            '@media (min-width: 600px)': {
              flexDirection: 'row',
              alignItems: 'center',
            },
          }}
        >
          <VideoMetadata
            title={videoName}
            matchId={videoData.matchId}
            flagged_comment={videoData.flagged}
          />
          <VideoScore
            homeScore={videoData.homePlayerMatchScore}
            awayScore={videoData.awayPlayerMatchScore}
          />
          <AddPlaylist userInfo={userInfo} matchId={videoData.matchId} />
        </CardContent>

        <VideoActions
          uploader={videoData.uploadUser}
          tags={videoData.customTag}
        />

        <VideoDetails videoData={videoData} />
      </Box>
    </Card>
  );
};

export default VideoCard;
