// UserInfoDrawer.jsx
import { useState } from 'react';
import {
  Drawer,
  Card,
  CardContent,
  Typography,
  Grid,
  IconButton,
  Box,
  List,
  ListItem,
  Divider,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './UserInfoDrawer.scss';

const UserInfoDrawer = ({ open, onClose, userData }) => {
  if (!userData) return null;

  // Process video views data
  const getVideoHistory = (videoViews) => {
    if (!videoViews || typeof videoViews !== 'object') return [];

    return Object.entries(videoViews)
      .map(([videoId, videoData]) => ({
        id: videoId,
        ...videoData,
      }))
      .sort((a, b) => {
        const dateA = a.last_viewed ? new Date(a.last_viewed) : new Date(0);
        const dateB = b.last_viewed ? new Date(b.last_viewed) : new Date(0);
        return dateB - dateA;
      });
  };

  const videoHistory = getVideoHistory(userData.video_views);
  const totalViews = videoHistory.reduce(
    (sum, video) => sum + (video.views || 0),
    0,
  );

  const userInfo = {
    basicInfo: {
      title: 'User Information',
      data: {
        Username: userData.username,
        'Account Type': userData.account_type,
        'User ID': userData.user_id,
        Email: userData.user_email,
        Category: userData.category,
        Status: userData.verified ? 'Verified' : 'Unverified',
      },
    },
    accessInfo: {
      title: 'Access Information',
      data: {
        'Last Login': userData.last_log_in
          ? new Date(userData.last_log_in).toLocaleString()
          : 'Never',
        'Last IP': userData.last_ip_address,
        'Login Count': userData.log_in_count,
      },
    },
    videoStats: {
      title: 'Video Statistics',
      data: {
        'Videos Uploaded': userData.video_uploaded,
        'Total Views': totalViews,
        'Unique Videos Viewed': videoHistory.length,
      },
    },
  };

  const formatDate = (dateString) => {
    if (!dateString) return 'No date available';
    try {
      return new Date(dateString).toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit',
      });
    } catch (error) {
      return 'Invalid date';
    }
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      className="user-info-drawer"
      sx={{
        '& .MuiDrawer-paper': {
          width: '100%',
          maxWidth: '50rem',
        },
      }}
    >
      <Box className="user-info-drawer-content">
        <Box className="drawer-header">
          <Typography variant="h5" sx={{ fontSize: '1.5rem' }}>
            User Details
          </Typography>
          <IconButton onClick={onClose} sx={{ padding: '0.5rem' }}>
            <CloseIcon />
          </IconButton>
        </Box>

        <Grid container spacing={2} className="info-grid">
          {/* Basic Info Cards */}
          {Object.entries(userInfo).map(([key, section]) => (
            <Grid item xs={12} md={6} key={key}>
              <Card className="info-card">
                <CardContent>
                  <Typography variant="h6" className="card-title">
                    {section.title}
                  </Typography>
                  <Box className="info-list">
                    {Object.entries(section.data).map(([label, value]) => (
                      <Box key={label} className="info-item">
                        <Typography variant="subtitle2" className="label">
                          {label}:
                        </Typography>
                        <Typography variant="body2" className="value">
                          {value ?? 'N/A'}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}

          {/* Video History Card */}
          <Grid item xs={12}>
            <Card className="info-card video-history-card">
              <CardContent>
                <Typography variant="h6" className="card-title">
                  Video Viewing History ({videoHistory.length} videos)
                </Typography>
                <List className="video-history-list">
                  {videoHistory.map((video) => (
                    <Box key={video.id}>
                      <ListItem className="video-history-item">
                        <Box className="video-info">
                          <Typography
                            variant="subtitle1"
                            className="video-name"
                          >
                            {video.video_name || 'Untitled Video'}
                          </Typography>
                          <Box className="video-details">
                            <Typography variant="body2" className="views">
                              Views: {video.views || 0}
                            </Typography>
                            <Typography variant="body2" className="last-viewed">
                              {video.last_viewed
                                ? `Last viewed: ${formatDate(video.last_viewed)}`
                                : 'no data'}
                            </Typography>
                          </Box>
                        </Box>
                      </ListItem>
                      <Divider />
                    </Box>
                  ))}
                </List>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  );
};

export default UserInfoDrawer;
