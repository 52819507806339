import { Box, Typography } from '@mui/material';

import { Link } from 'react-router-dom';

export const VideoMetadata = ({ title, matchId, flagged_comment }) => (
  <Box
    component={Link}
    to={`/video-player/${matchId}`}
    target="_blank"
    rel="noopener noreferrer"
    sx={{
      textDecoration: 'none',
      color: 'inherit',
      flexGrow: 1,
      maxWidth: 'calc(100% - 120px)',
      '@media (min-width: 600px)': {
        maxWidth: 'auto',
      },
    }}
    title="播放視頻"
  >
    <Typography
      variant="body2"
      sx={{
        cursor: 'pointer',
        '&:hover': {
          textDecoration: 'underline',
        },
      }}
    >
      {title.replace(/_/g, ' ')}
      {flagged_comment !== '' && flagged_comment !== '_' && (
        <span style={{ color: 'red' }}>[{flagged_comment}]</span>
      )}
    </Typography>
  </Box>
);
