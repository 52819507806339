import { LineChart } from '@mui/x-charts/LineChart';
import { ballDataIndex, ballStartTimeIndex } from 'src/constants';
import './ScoreChart.scss';

const ScoreChart = ({
  score1,
  score2,
  matchData,
  currentRound,
  yAxis,
  xAxis,
  chartWidth,
  chartHeight,
  videoRef,
  roundResults,
  setVideoData,
}) => {
  const handleDataClick = (event, data) => {
    if (data && roundResults[currentRound]) {
      videoRef.current.currentTime =
        roundResults[currentRound][ballDataIndex][data.dataIndex][
          ballStartTimeIndex
        ] + 0.01;

      setVideoData((prev) => ({
        ...prev,
        skipped: true,
      }));
    }
  };

  const chartTheme = {
    background: {
      color: 'transparent',
    },
    axis: {
      domain: {
        line: {
          stroke: '#525252',
          strokeWidth: 1,
        },
      },
      tick: {
        line: {
          stroke: '#525252',
          strokeWidth: 1,
        },
        label: {
          fill: '#d1d1d1',
        },
      },
    },
    grid: {
      line: {
        stroke: '#282828',
        strokeWidth: 1,
      },
    },
    legend: {
      label: {
        fill: '#fff',
      },
    },
  };

  return (
    <div className="score-chart">
      <div className="chart-content">
        {score1 && score1[currentRound] ? (
          <LineChart
            series={[
              {
                curve: 'monotoneX',
                data: score1[currentRound],
                label:
                  matchData.homePlayer.length <= 15
                    ? matchData.homePlayer
                    : `${matchData.homePlayer.slice(0, 15)}...`,
                color: '#E50914', // Netflix red
                valueFormatter: (v) => `${v}分`,
              },
              {
                curve: 'monotoneX',
                data: score2[currentRound],
                label:
                  matchData.awayPlayer.length <= 15
                    ? matchData.awayPlayer
                    : `${matchData.awayPlayer.slice(0, 15)}...`,
                color: '#2196f3', // Bright blue
                valueFormatter: (v) => `${v}分`,
              },
            ]}
            yAxis={[
              {
                data: yAxis[currentRound],
                tickMinStep: 1,
                min: 0,
                tickSize: 0,
              },
            ]}
            xAxis={[
              {
                data: xAxis[currentRound],
                tickMinStep: 2,
                min: 1,
                tickSize: 0,
              },
            ]}
            height={chartHeight}
            onMarkClick={handleDataClick}
            slotProps={{
              legend: {
                direction: 'row',
                position: { vertical: 'top', horizontal: 'center' },
                padding: 20,
              },
            }}
            sx={{
              '& .MuiMarkElement-root': {
                scale: '1.5',
                transition: 'all 0.2s ease',
                '&:hover': {
                  scale: '2',
                },
              },
              ...chartTheme,
            }}
          />
        ) : (
          <div className="no-data">
            <span>無分數圖表</span>
          </div>
        )}
      </div>
    </div>
  );
};

export default ScoreChart;
