// GraphVisualization.tsx
import React, { FC, useEffect, useRef } from "react";
import { useTheme } from "@mui/material";
import {
  SigmaContainer,
  useLoadGraph,
  useRegisterEvents,
} from "@react-sigma/core";
import Graph from "graphology";
import forceAtlas2 from "graphology-layout-forceatlas2";
import useGraphStore from "./graphStore";
import useStore from "../store/store";

interface GraphLoaderProps {
  onPlayerSelect?: (playerId: string) => void;
  theme?: any;
}

const GraphLoader: FC<GraphLoaderProps> = ({ onPlayerSelect }) => {
  const loadGraph = useLoadGraph();
  const registerEvents = useRegisterEvents();

  // Graph state
  const {
    nodes,
    edges,
    updateNodeVisibility,
    setStartPlayer,
    setVisibleDepth,
    setReachablePlayers,
    setSelectedCategory,
    initializeFromPlayers,
  } = useGraphStore();

  // Application state
  const {
    firstPlayer,
    setFirstPlayer,
    secondPlayer,
    setSecondPlayer,
    reachablePlayers,
    currentCategory,
    nodeDepth,
    players,
    setSelectedGame,
  } = useStore();

  // Track previous firstPlayer value
  const previousFirstPlayer = useRef(firstPlayer);

  // Synchronize useStore and useGraphStore
  useEffect(() => {
    if (
      previousFirstPlayer.current &&
      !firstPlayer &&
      currentCategory &&
      players
    ) {
      // firstPlayer changed from value to null - reset graph
      console.log("Resetting to initial category view");
      initializeFromPlayers(players, currentCategory);
    } else if (
      firstPlayer &&
      reachablePlayers &&
      currentCategory &&
      reachablePlayers[currentCategory]
    ) {
      // Normal sync for selected player
      console.log("Syncing store to graph state...");
      setStartPlayer(firstPlayer.toString());
      setVisibleDepth(nodeDepth);
      setReachablePlayers(reachablePlayers[currentCategory]);
      setSelectedCategory(currentCategory);
    }

    // Update ref for next check
    previousFirstPlayer.current = firstPlayer;
  }, [
    firstPlayer,
    reachablePlayers,
    currentCategory,
    nodeDepth,
    players,
    setStartPlayer,
    setVisibleDepth,
    setReachablePlayers,
    setSelectedCategory,
    initializeFromPlayers,
  ]);

  // Load graph data into Sigma
  useEffect(() => {
    const graph = new Graph();

    // Add nodes
    Object.values(nodes).forEach((node) => {
      (graph as any).addNode(node.id, {
        label: node.label,
        size: node.size,
        color: node.color,
        x: node.x || Math.random() * 1000 - 500,
        y: node.y || Math.random() * 1000 - 500,
        hidden: node.hidden,
      });
    });

    // Add edges
    Object.values(edges).forEach((edge) => {
      if ((graph as any).hasNode(edge.source) && (graph as any).hasNode(edge.target)) {
        (graph as any).addEdge(edge.source, edge.target, {
          size: edge.size,
          color: edge.color,
          hidden: edge.hidden,
        });
      }
    });

    // Apply ForceAtlas2 layout
    const settings = {
      gravity: 1.5,
      scalingRatio: 8,
      strongGravityMode: true,
      slowDown: 5,
      barnesHutOptimize: true,
      barnesHutTheta: 0.5,
    };
    forceAtlas2.assign(graph, { settings, iterations: 50 });

    loadGraph(graph);
  }, [nodes, edges, loadGraph]);

  // Update visibility
  useEffect(() => {
    updateNodeVisibility();
  }, [updateNodeVisibility]);

  // Register event listeners
  useEffect(() => {
    registerEvents({
      clickNode: (event) => {
        // Skip if clicked node is a game node
        if (event.node.startsWith("game-")) {
          // console.log('Clicked on a game node, ignoring player selection');
          setSelectedGame(parseInt(event.node.replace("game-", ""), 10));
          return;
        }

        const clickedNodeId = parseInt(event.node, 10);
        // Add validation to ensure it's a valid player ID
        if (isNaN(clickedNodeId)) {
          console.log("Invalid player ID:", event.node);
          return;
        }

        if (!firstPlayer) {
          console.log(`Setting first player: ${clickedNodeId}`);
          setFirstPlayer(clickedNodeId);
        } else if (!secondPlayer) {
          console.log(`Setting second player: ${clickedNodeId}`);
          setSecondPlayer(clickedNodeId);
        } else {
          console.log(
            `Node clicked but both players are already selected: ${clickedNodeId}`
          );
        }
      },
      clickEdge: (event) => {
        console.log("Edge clicked:", event.edge);
      },
      clickStage: () => {
        console.log("Clicked on the background");
      },
    });
  }, [
    registerEvents,
    setFirstPlayer,
    setSecondPlayer,
    firstPlayer,
    secondPlayer,
    setSelectedGame,
  ]);

  return null;
};

interface GraphVisualizationProps {
  onPlayerSelect?: (playerId: string) => void;
}

const GraphVisualization: FC<GraphVisualizationProps> = ({
  onPlayerSelect,
}) => {
  const theme = useTheme();
  return (
    <SigmaContainer
      style={{
        height: "100%",
        width: "100%",
        position: "absolute",
        top: 0,
        left: 0,
        backgroundColor: theme.palette.background.paper,
        touchAction: "none",
      }}
      settings={{
        defaultNodeColor: "#999",
        defaultEdgeColor: "#ccc",
        labelSize: 12,
        labelWeight: "bold",
        renderLabels: true,
        labelRenderedSizeThreshold: 6,
        labelDensity: 0.7,
      }}
    >
      <GraphLoader onPlayerSelect={onPlayerSelect} />
    </SigmaContainer>
  );
};

export default GraphVisualization;
