import { useEffect, useState, useRef, useMemo, memo } from 'react';
import h337 from 'heatmap.js';
import StraightIcon from '@mui/icons-material/Straight';

import { fetchDropPointsAPI } from 'src/api/videoService';

import { formatTime } from 'src/utils/utils';

import Cookies from 'js-cookie';

import './SimpleHeatmap.scss';
import {
  ballAwayPlayerScoreIndex,
  ballDataIndex,
  ballEndTimeIndex,
  ballHomePlayerScoreIndex,
  ballStartTimeIndex,
} from 'src/constants';

const region_dict = {
  0: '左長枱',
  1: '中長枱',
  2: '右長枱',
  3: '左短枱',
  4: '中短枱',
  5: '右短枱',
};

function findMaxSumWithinArea(points, area) {
  let maxSum = -Infinity;

  // Iterate over each point
  for (let i = 0; i < points.length; i++) {
    const currentPoint = points[i];
    let sum = currentPoint.value;

    // Check if other points fall within the area
    for (let j = 0; j < points.length; j++) {
      if (i !== j) {
        const otherPoint = points[j];
        if (
          otherPoint.x >= currentPoint.x - area &&
          otherPoint.x <= currentPoint.x + area &&
          otherPoint.y >= currentPoint.y - area &&
          otherPoint.y <= currentPoint.y + area
        ) {
          sum += otherPoint.value;
        }
      }
    }

    // Update the maximum sum if a higher sum is found
    if (sum > maxSum) {
      maxSum = sum;
    }
  }

  // Return the maximum sum
  return Math.min(maxSum, 3);
}

const SimpleHeatmap = memo(
  ({
    videoId,
    playerName,
    opponentName,
    hmOptions,
    subOptions,
    heatmapClass,
    mini = false,
    giant = false,
    roundResults,
    videoRef,
    setVideoData,
  }) => {
    const token = Cookies.get('token');
    const loopIntervalRef = useRef(null);
    const previousPlaybackRate = useRef(1);

    const [hmData, setHmData] = useState([[-1, -1]]);
    const [filteredHmData, setFilteredHmData] = useState([[-1, -1]]);
    const [percentages, setPercentages] = useState([0, 0, 0, 0, 0, 0]);
    const [count, setCounts] = useState([0, 0, 0, 0, 0, 0]);
    const [boxDetails, setBoxDetails] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState(null);
    const [activeLoop, setActiveLoop] = useState(null);
    const [availableRounds, setAvailableRounds] = useState([]);
    const [selectedRounds, setSelectedRounds] = useState(new Set());

    let prevInnerWidth = window.innerWidth;
    let prevInnerHeight = window.innerHeight;

    const calculatePlaybackSpeed = (startTime, endTime) => {
      const actualDuration = endTime - startTime;
      const desiredDuration = 3;

      const requiredSpeed = actualDuration / desiredDuration;

      return Math.min(1, requiredSpeed);
    };

    const getUniqueRounds = (data) => {
      const rounds = new Set();
      data.forEach((entry) => {
        if (entry.length >= 3) {
          rounds.add(entry[entry.length - 2]); // roundIndex is second to last element
        }
      });
      return Array.from(rounds).sort((a, b) => a - b);
    };

    const filterHmData = (data, selectedRounds) => {
      if (selectedRounds.size === 0) return data;
      return data.filter((entry) => {
        if (entry.length >= 3) {
          const roundIndex = entry[entry.length - 2];
          return selectedRounds.has(roundIndex);
        }
        return false;
      });
    };

    function calculatePercentageSpread(hmData, roundResults) {
      const boxes = [
        [0.333, 0.5],
        [0.667, 0.5],
        [1, 0.5],
        [0.333, 1],
        [0.667, 1],
        [1, 1],
      ];
      const counts = [0, 0, 0, 0, 0, 0];
      const boxDetails = [[], [], [], [], [], []];

      // First, organize data by round and ball index for easier time calculations
      const organizedData = new Map(); // key: `${roundIndex}-${ballIndex}`, value: array of points in order

      // Collect and organize all points
      for (const entry of hmData) {
        if (entry.length >= 3) {
          const coordinates = entry.slice(0, -2);
          const roundIndex = entry[entry.length - 2];
          const ballIndex = entry[entry.length - 1];
          const key = `${roundIndex}-${ballIndex}`;

          if (!organizedData.has(key)) {
            organizedData.set(key, []);
          }

          coordinates.forEach(([x, y, time]) => {
            if (typeof time === 'number' && !isNaN(time)) {
              // Validate time
              organizedData
                .get(key)
                .push({ x, y, time, roundIndex, ballIndex });
            }
          });
        }
      }

      // Sort points by time within each round-ball group
      for (const points of organizedData.values()) {
        points.sort((a, b) => a.time - b.time);
      }

      // Process the data
      for (const [key, points] of organizedData) {
        const [roundIndex, ballIndex] = key.split('-').map(Number);
        const ballData = roundResults[roundIndex]?.[ballDataIndex]?.[ballIndex];

        points.forEach((point, index) => {
          const { x, y, time } = point;

          // Calculate start and end times with validation
          let ballTimeStart, ballTimeEnd;

          if (index > 0) {
            // There's a previous point
            const prevTime = points[index - 1].time;
            if (time - prevTime <= 1) {
              ballTimeStart = prevTime;
            } else {
              ballTimeStart = time - 1;
            }
          } else {
            // First point
            ballTimeStart = time - 1;
          }

          if (index < points.length - 1) {
            // There's a next point
            const nextTime = points[index + 1].time;
            if (nextTime - time <= 1) {
              ballTimeEnd = nextTime;
            } else {
              ballTimeEnd = time + 1;
            }
          } else {
            // Last point
            ballTimeEnd = time + 1;
          }

          // Ensure start is not negative and end doesn't exceed ball duration
          ballTimeStart = Math.max(0, ballTimeStart);
          if (ballData?.[ballEndTimeIndex]) {
            ballTimeEnd = Math.min(ballTimeEnd, ballData[ballEndTimeIndex]);
          }

          // Final sanity check - ensure end is always greater than start
          if (ballTimeEnd <= ballTimeStart) {
            ballTimeEnd = ballTimeStart + 1;
          }

          // Find which box this point belongs to
          for (let j = 0; j < boxes.length; j++) {
            const [boxX, boxY] = boxes[j];

            if (x <= boxX && y <= boxY) {
              counts[j]++;
              boxDetails[j].push({
                x,
                y,
                roundIndex,
                ballIndex,
                ballTime: time,
                ballTimeStart,
                ballTimeEnd,
                homePlayerScore: ballData?.[ballHomePlayerScoreIndex],
                awayPlayerScore: ballData?.[ballAwayPlayerScoreIndex],
                startTime: ballData?.[ballStartTimeIndex],
                endTime: ballData?.[ballEndTimeIndex],
              });
              break;
            }
          }
        });
      }

      const totalPoints = counts.reduce((a, b) => a + b, 0);
      const percentages = counts.map((count) =>
        count !== 0 ? (count / totalPoints) * 100 : 0,
      );
      return { counts, percentages, boxDetails };
    }
    const plotHM = (hmData, isResized = false) => {
      const { innerWidth, innerHeight } = window;

      // Check if it was triggered by a resize event
      if (isResized) {
        // Calculate the percentage difference between previous and current dimensions
        const widthDiff =
          Math.abs(innerWidth - prevInnerWidth) / prevInnerWidth;
        const heightDiff =
          Math.abs(innerHeight - prevInnerHeight) / prevInnerHeight;

        // Check if the difference is less than 3%, because weird mobile interaction
        if (widthDiff < 0.03 && heightDiff < 0.03) {
          return;
        }

        // Save the current dimensions as the new previous values
        prevInnerWidth = innerWidth;
        prevInnerHeight = innerHeight;
      }

      const average = (innerWidth + innerHeight) / 2;
      const HMRadius = average / 50;

      // Destroy the previous heatmap instance
      const heatmapContainer = document.querySelector(
        `.${heatmapClass} .simple-heatmap`,
      );
      if (heatmapContainer && heatmapContainer.firstChild) {
        heatmapContainer.removeChild(heatmapContainer.firstChild);
      }

      // heatmap
      const HMContainer = document.querySelector(
        `.${heatmapClass} .simple-heatmap`,
      );
      if (HMContainer) {
        try {
          var heatmapInstance = h337.create({
            container: HMContainer,
            backgroundColor: '#FAFAFA',
            radius: HMRadius,
          });
          var width, height;
          var plot_height, plot_width;

          // set dimension here
          // landscape
          if (innerWidth > innerHeight) {
            if (mini) {
              plot_height = 0.56;
              plot_width = 0.32;
              width = plot_width * innerHeight;
              height = plot_height * innerHeight;
            } else if (giant) {
              plot_height = 0.245;
              plot_width = 0.28;
              width = plot_width * innerWidth;
              height = plot_height * innerWidth;
            } else {
              plot_height = 0.63;
              plot_width = 0.36;
              width = plot_width * innerHeight;
              height = plot_height * innerHeight;
            }
          }
          //portrait
          else {
            if (mini) {
              plot_height = 0.42;
              plot_width = 0.24;
            } else if (giant) {
              plot_height = 0.4375;
              plot_width = 0.5;
            } else {
              plot_height = 0.49;
              plot_width = 0.28;
            }
            width = plot_width * innerWidth;
            height = plot_height * innerWidth;
          }

          var points = hmData.flatMap((entry) => {
            if (entry.length >= 3) {
              const coordinates = entry.slice(0, -2);
              const roundIndex = entry[entry.length - 2];
              const ballIndex = entry[entry.length - 1];

              return coordinates.map(([x, y]) => ({
                x: Math.floor(x * width),
                y: Math.floor(y * height),
                value: 1,
                roundIndex,
                ballIndex,
              }));
            }
            return [];
          });

          var data = {
            data: points,
          };

          const area = HMRadius / 30;
          const maxSum = findMaxSumWithinArea(points, area);
          heatmapInstance.setData(data);
          heatmapInstance.setDataMax(maxSum);
        } catch (error) {
          console.error(error);
        }
      }

      // Create the event listener function
      const resizeListener = () => {
        plotHM(hmData, true);
      };

      // Add the event listener
      window.addEventListener('resize', resizeListener);

      // Clean up the event listener when the component unmounts
      return () => window.removeEventListener('resize', resizeListener);
    };

    const removeExistingHm = () => {
      const heatmapContainer = document.querySelector(
        `.${heatmapClass} .simple-heatmap`,
      );
      if (heatmapContainer && heatmapContainer.firstChild) {
        heatmapContainer.removeChild(heatmapContainer.firstChild);
      }
    };

    const fetchDpData = useMemo(
      () => async () => {
        try {
          const responseData = await fetchDropPointsAPI(
            {
              videoId,
              player_name: playerName[0],
              M_option: hmOptions,
              S_option: subOptions,
            },
            token,
          );
          setHmData(responseData[0]);
          const rounds = getUniqueRounds(responseData[0]);
          setAvailableRounds(rounds);
          setSelectedRounds((prevSelected) =>
            prevSelected.size === 0 ? new Set(rounds) : prevSelected,
          );
          setFilteredHmData(responseData[0]);
        } catch (error) {
          // Handle error
        }
      },
      [videoId, playerName, hmOptions, subOptions, token],
    );

    const filteredData = useMemo(
      () => filterHmData(hmData, selectedRounds),
      [hmData, selectedRounds],
    );

    const calculations = useMemo(() => {
      if (filteredHmData.length > 0 && roundResults.length > 0) {
        return calculatePercentageSpread(filteredHmData, roundResults);
      }
      return { counts: [], percentages: [], boxDetails: [] };
    }, [filteredHmData, roundResults]);

    useEffect(() => {
      fetchDpData();
    }, [fetchDpData]);

    useEffect(() => {
      setFilteredHmData(filteredData);
    }, [filteredData]);

    useEffect(() => {
      removeExistingHm();
      if (filteredHmData.length > 0 && roundResults.length > 0) {
        const { counts, percentages, boxDetails } = calculations;
        setCounts(counts);
        setPercentages(percentages);
        setBoxDetails(boxDetails);
        plotHM(filteredHmData);
      }
    }, [calculations, filteredHmData]);

    const toggleRound = (roundIndex) => {
      const newSelected = new Set(selectedRounds);
      if (newSelected.has(roundIndex)) {
        if (newSelected.size > 1) {
          // Prevent deselecting all rounds
          newSelected.delete(roundIndex);
        }
      } else {
        newSelected.add(roundIndex);
      }
      setSelectedRounds(newSelected);
    };

    const selectAllRounds = () => {
      setSelectedRounds(new Set(availableRounds));
    };

    const renderRoundFilters = () => {
      return (
        <div className="round-filters">
          <div className="filter-buttons">
            <button
              className={`all-rounds-btn ${selectedRounds.size === availableRounds.length ? 'selected' : ''}`}
              onClick={selectAllRounds}
            >
              全部
            </button>
            {availableRounds.map((roundIndex) => (
              <button
                key={roundIndex}
                className={`round-btn ${selectedRounds.has(roundIndex) ? 'selected' : ''}`}
                onClick={() => toggleRound(roundIndex)}
              >
                回合 {roundIndex + 1}
              </button>
            ))}
          </div>
          <div className="selected-info">
            已選擇 {selectedRounds.size} / {availableRounds.length} 回合
          </div>
        </div>
      );
    };

    const handleRegionClick = (index) => {
      setSelectedRegion(index);
    };

    const getScoreColor = (score, opponentScore) => {
      if (score > opponentScore) return 'score-higher';
      if (score < opponentScore) return 'score-lower';
      return 'score-tie';
    };
    const stopLoop = () => {
      if (loopIntervalRef.current) {
        clearInterval(loopIntervalRef.current);
        loopIntervalRef.current = null;
      }
      if (videoRef.current) {
        videoRef.current.playbackRate = previousPlaybackRate.current;
      }
      setActiveLoop(null);
    };

    const startLoop = (start, end, type) => {
      if (videoRef.current) {
        // Store current playback rate
        previousPlaybackRate.current = videoRef.current.playbackRate;

        // Calculate appropriate speed based on type
        const speed =
          type === 'ball' ? calculatePlaybackSpeed(start, end) : 0.7; // Keep original speed for 'start' type

        // Set the playback rate
        videoRef.current.playbackRate = speed;

        // Set the new active loop
        setActiveLoop({ start, end, type });

        // Start playback from the beginning of the range
        videoRef.current.currentTime = start;
        videoRef.current.play();

        // Set up the loop checker
        loopIntervalRef.current = setInterval(() => {
          if (videoRef.current) {
            if (videoRef.current.currentTime >= end) {
              videoRef.current.currentTime = start;
              videoRef.current.play();
            }
          }
        }, 100);
      }
    };

    const toggleLoopPlayback = (start, end, type) => {
      // If clicking the same time range and type, stop the loop
      if (
        activeLoop?.start === start &&
        activeLoop?.end === end &&
        activeLoop?.type === type
      ) {
        stopLoop();
        return;
      }

      // Stop any existing loop before starting new one
      stopLoop();
      setVideoData((prevState) => ({
        ...prevState,
        isPlaying: true,
      }));
      startLoop(start, end, type);
    };

    // Clean up interval on unmount
    useEffect(() => {
      return () => {
        if (loopIntervalRef.current) {
          clearInterval(loopIntervalRef.current);
        }
        // Reset playback rate if component unmounts during a loop
        if (videoRef.current) {
          videoRef.current.playbackRate = 1;
        }
      };
    }, []);

    const renderDetailsTable = () => {
      if (selectedRegion === null) return null;

      const details = boxDetails[selectedRegion];

      return (
        <div className="details-table">
          <h3 className="details-title">
            {' '}
            {region_dict[selectedRegion]}(
            <span className="player-name" title={playerName[0]}>
              {playerName[0].length > 15
                ? playerName[0].slice(0, 15) + '...'
                : playerName[0]}
            </span>
            面向枱)
          </h3>
          <table>
            <thead>
              <tr>
                <th>回合</th>
                <th>比分</th>
                <th>球開始</th>
                <th>球結束</th>
                <th>落點</th>
              </tr>
            </thead>
            <tbody>
              {details.map((detail, index) => {
                const isBallTimeLoop =
                  activeLoop?.start === detail.ballTimeStart &&
                  activeLoop?.end === detail.ballTimeEnd &&
                  activeLoop?.type === 'ball';

                const isStartTimeLoop =
                  activeLoop?.start === detail.startTime &&
                  activeLoop?.end === detail.endTime &&
                  activeLoop?.type === 'start';

                return (
                  <tr key={index} className="detail-row">
                    <td>{detail.roundIndex + 1}</td>
                    <td>
                      <div className="score-container">
                        <span
                          className={getScoreColor(
                            detail.homePlayerScore,
                            detail.awayPlayerScore,
                          )}
                        >
                          {detail.homePlayerScore}
                        </span>
                        <span className="score-separator">:</span>
                        <span
                          className={getScoreColor(
                            detail.awayPlayerScore,
                            detail.homePlayerScore,
                          )}
                        >
                          {detail.awayPlayerScore}
                        </span>
                      </div>
                    </td>
                    <td
                      className="start-time"
                      onClick={() =>
                        toggleLoopPlayback(
                          detail.startTime,
                          detail.endTime,
                          'start',
                        )
                      }
                    >
                      {formatTime(detail.startTime)}
                      {isStartTimeLoop && (
                        <span
                          style={{
                            width: '8px',
                            height: '8px',
                            borderRadius: '50%',
                            backgroundColor: '#4CAF50',
                            display: 'inline-block',
                          }}
                        />
                      )}
                    </td>
                    <td>{formatTime(detail.endTime)}</td>
                    <td
                      className="ball-start-time"
                      onClick={() =>
                        toggleLoopPlayback(
                          detail.ballTimeStart,
                          detail.ballTimeEnd,
                          'ball',
                        )
                      }
                    >
                      {formatTime(detail.ballTimeStart)}
                      {' - '}
                      {formatTime(detail.ballTimeEnd)}
                      {isBallTimeLoop && (
                        <span
                          style={{
                            width: '8px',
                            height: '8px',
                            borderRadius: '50%',
                            backgroundColor: '#4CAF50',
                            display: 'inline-block',
                          }}
                        />
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      );
    };

    return (
      <div
        className={`sim-hm-main-container ${mini ? 'mini' : ''} ${
          giant ? 'giant' : ''
        }`}
      >
        {renderRoundFilters()}
        <p className="video-info-heatmap-opponent-name"> {opponentName[0]}</p>
        <div style={{ backgroundColor: '#FAFAFA' }} className={heatmapClass}>
          <div
            className="simple-heatmap"
            style={{ width: '100%', height: '100%' }}
          ></div>
          <div className="dp-percent">
            {percentages.map((percentage, index) => (
              <span
                key={index}
                className={`region ${selectedRegion === index ? 'selected' : ''}`}
                onClick={() => handleRegionClick(index)}
              >
                <span className="region-percentage">
                  {percentage && Math.round(percentage)}%({count[index]})
                </span>
              </span>
            ))}
          </div>
        </div>
        <div className="simple-hm-bottom-panel">
          <StraightIcon />
          <p className="video-info-heatmap-player-name">{playerName[0]}</p>
          <StraightIcon />
        </div>
        {renderDetailsTable()}
      </div>
    );
  },
  (prevProps, nextProps) => {
    // Custom comparison function
    return (
      prevProps.videoId === nextProps.videoId &&
      prevProps.playerName[0] === nextProps.playerName[0] &&
      prevProps.hmOptions === nextProps.hmOptions &&
      prevProps.subOptions === nextProps.subOptions &&
      prevProps.heatmapClass === nextProps.heatmapClass &&
      prevProps.giant === nextProps.giant &&
      JSON.stringify(prevProps.roundResults) ===
        JSON.stringify(nextProps.roundResults)
    );
  },
);
SimpleHeatmap.displayName = 'SimpleHeatmap';
export default SimpleHeatmap;
