import { Box, Typography, Button, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import useStore from "../../../store/store";

export interface GameMetadata {
  id: number;
  filename: string;
  hash: string;
  round: string;
  date: string;
  location: string;
  team1: string;
  team2: string;
  videoFilename: string;
  thumbnailFilename: string;
  duration: number | null;
  startFrame: number;
  endFrame: number;
  isPublic: boolean;
  isDeleted: boolean;
  isHidden: boolean;
  category: string;
  subcategory: string;
  categoryType: string;
  gameType: string;
  isPending: boolean;
  priority: number;
  source: string;
  eventName: string;
  finalscore1: number;
  finalscore2: number;
}

export interface GameScore {
  gameNumber: number;
  finalTeam1Score: number;
  finalTeam2Score: number;
}

export interface Player {
  playerId: string;
  playerName: string;
}

export interface GameClickInfo {
  gameId: string;
  metadata: GameMetadata;
  gameScores: GameScore[];
  players: Player[];
}

interface GameInfoProps {
  gameInfo: GameClickInfo; // Use the GameClickInfo interface here

  variant?: "drawer" | "paper" | "dialog";
}

const GameInfoRenderer = ({
  gameInfo,

  variant = "drawer",
}: GameInfoProps) => {
  console.log("gameInfo", gameInfo);

  const { setSelectedGameInfo } = useStore();
  const { metadata, gameScores, players } = gameInfo;
  const isPaper = variant === "paper";
  const { t } = useTranslation();

  const handleWatchVideo = () => {
    window.open(
      `/video-player/${metadata.id}`,
      "_blank",
      "noopener,noreferrer"
    );
  };

  const handleClose = () => {
    setSelectedGameInfo(null); // Just clear the game info
  };

  return (
    <Box
      sx={{
        width: "100%",
        p: isPaper ? 0 : 2,
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <Box
        sx={{
          flex: 1,
          overflowY: "auto",
          "&::-webkit-scrollbar": {
            width: "8px",
          },
          "&::-webkit-scrollbar-track": {
            background: "#f1f1f1",
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#888",
            borderRadius: "4px",
          },
        }}
      >
        <Typography variant={isPaper ? "h6" : "h6"} gutterBottom>
          {t("gameInfo.title")}
        </Typography>

        {metadata.thumbnailFilename?.length > 0 && (
          <Box sx={{ mb: 3, width: "100%" }}>
            <Box
              component="img"
              src={`/thumbnails/${metadata.thumbnailFilename}`}
              alt={`${metadata.team1} vs ${metadata.team2}`}
              sx={{
                width: "100%",
                maxHeight: "200px",
                borderRadius: 1,
                objectFit: "cover",
              }}
            />
          </Box>
        )}

        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" color="text.secondary">
            {t("gameInfo.eventInfo.title")}
          </Typography>
          <Typography noWrap sx={{ maxWidth: "100%" }}>
            {metadata.eventName}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {metadata.round} - {metadata.location}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {metadata.date ? metadata.date : t("gameInfo.eventInfo.date")}
          </Typography>
        </Box>

        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" color="text.secondary">
            {t("gameInfo.teams.title")}
          </Typography>
          <Typography noWrap sx={{ maxWidth: "100%" }}>
            {metadata.team1} {t("gameInfo.teams.vs")} {metadata.team2}
          </Typography>
          <Typography variant={isPaper ? "body1" : "h6"}>
            {t("gameInfo.teams.finalScore")}: {metadata.finalscore1} -{" "}
            {metadata.finalscore2}
          </Typography>
        </Box>

        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" color="text.secondary">
            {t("gameInfo.gameScores.title")}
          </Typography>
          {gameScores.map((game) => (
            <Box key={game.gameNumber} sx={{ mb: 1 }}>
              <Typography>
                {t("gameInfo.gameScores.game")} {game.gameNumber}:{" "}
                {game.finalTeam1Score} - {game.finalTeam2Score}
              </Typography>
            </Box>
          ))}
        </Box>

        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" color="text.secondary">
            {t("gameInfo.players.title")}
          </Typography>
          {players.map((player) => (
            <Typography key={player.playerId} noWrap sx={{ maxWidth: "100%" }}>
              {player.playerName}
            </Typography>
          ))}
        </Box>

        <Box sx={{ mb: 2 }}>
          <Typography variant="subtitle2" color="text.secondary">
            {t("gameInfo.additionalInfo.title")}
          </Typography>
          <Typography>
            {t("gameInfo.additionalInfo.matchId")}: {metadata.id}
          </Typography>
          <Typography
            noWrap
            sx={{
              maxWidth: "100%",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {t("gameInfo.additionalInfo.filename")}: {metadata.filename}
          </Typography>
        </Box>
      </Box>

      <Stack spacing={1} sx={{ mt: 2 }}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleWatchVideo}
        >
          {t("gameInfo.buttons.watchVideo")}
        </Button>
        <Button
          variant="contained"
          color="error"
          fullWidth
          onClick={handleClose}
        >
          {t("gameInfo.buttons.close")}
        </Button>
      </Stack>
    </Box>
  );
};

export default GameInfoRenderer;
