import { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import HomeSidePanel from 'src/components/HomeSidePanel/HomeSidePanel';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import { Tabs, Tab, Fab, Fade } from '@mui/material';
import { fetchMatchDataAPI } from 'src/api/videoService';
import { useTheme, useMediaQuery } from '@mui/material';
import { fetchPlaylistDataAPI } from 'src/api/playlistService';
import NavigationIcon from '@mui/icons-material/Navigation';

import HomeOptionsBarNew from './HomeOptionsBar/HomeOptionsBarNew';
import PaginatedVideoListNew from './PaginatedVideoList/PaginatedVideoListNew';

import {
  matchIdIndex,
  videoNameIndex,
  matchDescriptionIndex,
  stageIndex,
  locationIndex,
  homePlayerIndex,
  awayPlayerIndex,
  urlIndex,
  dateIndex,
  thumbnailIndex,
  reportIndex,
  subEventIndex,
  customTagIndex,
  homePlayerMatchScoreIndex,
  awayPlayerMatchScoreIndex,
  eventIndex,
  categoryIndex,
  isDoubleIndex,
  viewCountIndex,
  uploadUserIndex,
  parentEventIndex,
} from 'src/constants';

const CACHE_EXPIRY_HOURS = 1;

const getWithExpiry = (key) => {
  const item = localStorage.getItem(key);
  if (!item) return null;

  const parsed = JSON.parse(item);
  const now = new Date();

  if (parsed.expiry && now.getTime() > parsed.expiry) {
    localStorage.removeItem(key);
    return null;
  }

  return parsed.value;
};

const setWithExpiry = (key, value) => {
  const now = new Date();
  const item = {
    value: value,
    expiry: now.getTime() + CACHE_EXPIRY_HOURS * 60 * 60 * 1000,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

const defaultFilterCriteria = {
  matchName: '',
  matchDate: '',
  stages: [],
  location: '',
  events: [],
  categories: [],
  startDate: '',
  endDate: '',
  customTags: [],
};

const useFilterCriteria = () => {
  const [filterCriteria, setFilterCriteria] = useState(() => {
    const cachedCriteria = getWithExpiry('filterCriteria');
    return cachedCriteria || defaultFilterCriteria;
  });

  const updateFilterCriteria = (newCriteria) => {
    setFilterCriteria(newCriteria);
    setWithExpiry('filterCriteria', newCriteria);
  };

  return [filterCriteria, updateFilterCriteria];
};

const useSortCriteria = () => {
  const [sortCriteria, setSortCriteria] = useState(() => {
    const cachedSortCriteria = getWithExpiry('sortCriteria');
    return cachedSortCriteria ?? 0;
  });

  const updateSortCriteria = (newCriteria) => {
    setSortCriteria(newCriteria);
    setWithExpiry('sortCriteria', newCriteria);
  };

  return [sortCriteria, updateSortCriteria];
};

const HomeNew = ({ userInfo }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const token = Cookies.get('token');
  const [videoList, setVideoList] = useState([]);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [playlists, setPlaylists] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [CustomTagList, setCustomTagList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showScrollTop, setShowScrollTop] = useState(false);

  const scrollContainerRef = useRef(null);

  const [filterCriteria, setFilterCriteria] = useFilterCriteria();
  const [sortCriteria, setSortCriteria] = useSortCriteria();
  const [filterStats, setFilterStats] = useState({
    stageCounts: {},
    subEventCounts: {},
    customTagCounts: {},
    categoryCounts: {},
  });

  const handleScroll = () => {
    const scrollY = scrollContainerRef.current?.scrollTop || 0;
    setShowScrollTop(scrollY > 300);
  };

  const scrollToTop = () => {
    scrollContainerRef.current?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const fetchMatchData = useCallback(async () => {
    setIsLoading(true);
    const {
      matchName,
      matchDate,
      stages,
      location,
      events,
      categories,
      startDate,
      endDate,
      customTags,
    } = filterCriteria;

    try {
      const matchResponse = await fetchMatchDataAPI(
        {
          match_name: matchName,
          match_date: matchDate,
          location: location,
          stage: stages.join(','),
          accountType: userInfo.accountType,
          events: events.join(','),
          categories: categories.join(','),
          startDate: startDate,
          endDate: endDate,
          sortCriteria: sortCriteria,
          customTags: customTags.join(','),
          admin: userInfo.admin,
          user: userInfo.identifier,
          videoQueue: true,
        },
        token,
      );

      const responseData = matchResponse.videoData;
      const vqData = matchResponse.vqData;

      setFilterStats({
        stageCounts: matchResponse.stageCounts,
        subEventCounts: matchResponse.subEventCounts,
        customTagCounts: matchResponse.customTagCounts,
        categoryCounts: matchResponse.categoryCounts,
      });

      const mergedData = responseData.map((videoData) => ({
        matchId: videoData[matchIdIndex],
        videoName: videoData[videoNameIndex],
        matchDescription: videoData[matchDescriptionIndex],
        stage: videoData[stageIndex],
        date: videoData[dateIndex].replace(/\s\d{2}:\d{2}:\d{2}\s\w{3}$/, ''),
        location: videoData[locationIndex],
        homePlayer: videoData[homePlayerIndex],
        awayPlayer: videoData[awayPlayerIndex],
        fileUrl: `${process.env.PUBLIC_URL}/videos/${videoData[
          urlIndex
        ].substring(
          0,
          videoData[urlIndex].lastIndexOf('.'),
        )}/${videoData[urlIndex]}`,
        TNUrl: `${process.env.PUBLIC_URL}/videos/${videoData[
          urlIndex
        ].substring(
          0,
          videoData[urlIndex].lastIndexOf('.'),
        )}/${videoData[thumbnailIndex]}`,
        report: videoData[reportIndex],
        parentEvent: videoData[parentEventIndex],
        event: videoData[eventIndex],
        subEvent: videoData[subEventIndex],
        category: videoData[categoryIndex],
        isDouble: videoData[isDoubleIndex],
        viewCount: videoData[viewCountIndex],
        uploadUser: videoData[uploadUserIndex],
        customTag: videoData[customTagIndex],
        homePlayerMatchScore: videoData[homePlayerMatchScoreIndex],
        awayPlayerMatchScore: videoData[awayPlayerMatchScoreIndex],
        flagged: vqData[videoData[matchIdIndex]]['flagged_comment'],
      }));

      //hide flagged video
      const filteredData = !userInfo.admin
        ? mergedData.filter((item) => !item.flagged || item.flagged === '_')
        : mergedData;

      setVideoList(filteredData);
    } catch (error) {
      console.error('Error fetching and merging data:', error);
    } finally {
      setIsLoading(false);
    }
  }, [filterCriteria, sortCriteria, token, userInfo]);

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const playlistResponse = await fetchPlaylistDataAPI(
          { user_name: userInfo.identifier, user_type: userInfo.accountType },
          token,
        );
        setPlaylists(playlistResponse[0]);

        const customTagResponse = await axios.get('/api/customTagList', {
          headers: { Authorization: `${token}` },
        });
        setCustomTagList(customTagResponse.data);

        await fetchMatchData();
      } catch (error) {
        console.error('Error fetching initial data:', error);
      }
    };

    fetchInitialData();
  }, [fetchMatchData, token, userInfo.accountType, userInfo.identifier]);

  const allTabs = [{ playlist_name: 'All' }, ...playlists];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        backgroundColor: 'white',
        minWidth: '100vw',
        height: '100vh',
        overflow: 'hidden',
      }}
    >
      {!isMobile && (
        <HomeSidePanel
          isCollapsed={isCollapsed}
          setIsCollapsed={setIsCollapsed}
          isCoach={userInfo.isCoach}
          accountType={userInfo.accountType}
        />
      )}
      <Box
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'hidden',
        }}
      >
        <Box
          sx={{
            position: 'sticky',
            top: 0,
            zIndex: 1,
            backgroundColor: 'white',
            pt: 1,
            px: 1,
          }}
        >
          <HomeOptionsBarNew
            filterCriteria={filterCriteria}
            setFilterCriteria={setFilterCriteria}
            sortCriteria={sortCriteria}
            setSortCriteria={setSortCriteria}
            filterStats={filterStats}
            CustomTagList={CustomTagList}
            userInfo={userInfo}
          />

          <Paper sx={{ backgroundColor: 'white' }}>
            <Tabs
              value={activeTab}
              onChange={handleTabChange}
              variant="scrollable"
              scrollButtons="auto"
              aria-label="playlist tabs"
            >
              {allTabs.map((tab, index) => (
                <Tab
                  key={tab.playlist_name}
                  label={tab.playlist_name}
                  id={`playlist-tab-${index}`}
                  aria-controls={`playlist-tabpanel-${index}`}
                />
              ))}
            </Tabs>
          </Paper>
        </Box>

        <Box
          ref={scrollContainerRef}
          onScroll={handleScroll}
          sx={{
            flexGrow: 1,
            overflow: 'auto',
            px: 1,
            pb: 2,
            position: 'relative',
          }}
        >
          {allTabs.map((tab, index) => (
            <Box
              key={tab.playlist_name}
              role="tabpanel"
              hidden={activeTab !== index}
              id={`playlist-tabpanel-${index}`}
              aria-labelledby={`playlist-tab-${index}`}
            >
              {activeTab === index && (
                <PaginatedVideoListNew
                  isLoading={isLoading}
                  videoList={videoList}
                  playlistName={tab.playlist_name}
                  playlists={playlists}
                  userInfo={userInfo}
                />
              )}
            </Box>
          ))}

          <Fade in={showScrollTop}>
            <Fab
              color="primary"
              size="small"
              onClick={scrollToTop}
              sx={{
                position: 'fixed',
                bottom: 20,
                right: 20,
                '&:hover': {
                  backgroundColor: 'primary.dark',
                },
              }}
              aria-label="scroll back to top"
            >
              <NavigationIcon />
            </Fab>
          </Fade>
        </Box>
      </Box>
    </Box>
  );
};

export default HomeNew;
