import { useState } from 'react';
import './RoundScorePanel.scss';
import VerticalTable from './VerticalTable';
import { ballDataIndex } from 'src/constants';

const RoundScorePanel = ({
  matchData,
  roundData,
  roundResults,
  videoRef,
  videoData,
  setVideoData,
}) => {
  const [showDeuce, setShowDeuce] = useState(false);
  const [showCP, setShowCP] = useState(false);

  const handleDeuceChange = () => {
    setShowDeuce((prev) => !prev);
  };

  const handleCriticalPointChange = () => {
    setShowCP((prev) => !prev);
  };

  return (
    <div className="roundscore-container">
      <div className="score-controls">
        <div className="legend-container">
          <h3 className="legend-title">圖例</h3>
          <div className="legend-items">
            <div className="legend-item">
              <span className="legend-indicator highlight"></span>
              <span className="legend-text">亮點</span>
            </div>
            <div className="legend-item">
              <span className="legend-indicator current"></span>
              <span className="legend-text">現在球</span>
            </div>
            <div className="legend-item">
              <span className="legend-indicator current-highlight"></span>
              <span className="legend-text">現在亮點</span>
            </div>
          </div>
        </div>

        <div className="filter-controls">
          <button
            className={`filter-btn ${showDeuce ? 'active' : ''}`}
            onClick={handleDeuceChange}
          >
            Deuce
          </button>
          <button
            className={`filter-btn ${showCP ? 'active' : ''}`}
            onClick={handleCriticalPointChange}
          >
            關鍵分
          </button>
        </div>
      </div>

      <div className="scoreboard-content">
        {showCP || showDeuce ? (
          <div className="multi-scoreboard">
            {roundResults.map((round, index) => (
              <VerticalTable
                key={index}
                matchData={matchData}
                roundData={round[ballDataIndex]}
                roundIndex={index}
                videoRef={videoRef}
                currentBall={index}
                setVideoData={setVideoData}
                cp={showCP}
                deuce={showDeuce}
              />
            ))}
          </div>
        ) : (
          <VerticalTable
            matchData={matchData}
            roundData={roundData}
            roundIndex={videoData.currentRound}
            videoRef={videoRef}
            currentBall={videoData.currentBall}
            setVideoData={setVideoData}
          />
        )}
      </div>
    </div>
  );
};

export default RoundScorePanel;
