import { useEffect } from 'react';
import useStore from '../store/store';

export const useWatchPlayers = () => {
    const fetchPlayerPaths = useStore(state => state.fetchPlayerPaths);
    const firstPlayer = useStore(state => state.firstPlayer);
    const secondPlayer = useStore(state => state.secondPlayer);

    useEffect(() => {
        if (firstPlayer && secondPlayer) {
            fetchPlayerPaths();
        }
    }, [firstPlayer, secondPlayer, fetchPlayerPaths]);
};