import { Box, Slider, Typography } from "@mui/material";
import { FC } from "react";

interface DepthSliderProps {
  depth?: number;
  maxDepth: number | null;
  onDepthChange: (newDepth: number) => void;
  disabled?: boolean;
}

export const NEWDepthSlider: FC<DepthSliderProps> = ({
  depth,
  maxDepth,
  onDepthChange,
  disabled = false,
}) => {
  return (
    <Box sx={{ width: "100%", mt: 2 }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
        <Typography variant="body2" color="text.secondary">
          Depth
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {depth} {maxDepth ? `/ ${maxDepth}` : ""}
        </Typography>
      </Box>
      <Slider
        value={depth}
        onChange={(_, value) => onDepthChange(value as number)}
        min={1}
        max={maxDepth || 6} // Default max if maxDepth is null
        step={1}
        marks
        disabled={disabled}
        valueLabelDisplay="auto"
      />
    </Box>
  );
};
