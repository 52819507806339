import React, { useState, useCallback, useEffect } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import CircularProgress from '@mui/material/CircularProgress';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import VideoCard from '../VideoCard/VideoCard';
import PlaylistHeader from '../PlaylistHeader/PlaylistHeader';
import { useTheme } from '@mui/material/styles';

const BATCH_SIZE = 20;
const SCROLL_THRESHOLD = '100px';

// Styled components
const VideoContainer = styled(Box)(({ theme }) => ({
  position: 'relative',
  overflowAnchor: 'none',
  WebkitOverflowScrolling: 'touch',
  overscrollBehavior: 'contain',
  minHeight: '100vh',
  [theme.breakpoints.down('md')]: {
    scrollBehavior: 'auto',
  },
}));

const VideoListContainer = styled(List)(({ theme }) => ({
  padding: theme.spacing(1.5),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));

const LoaderContainer = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '20px',
  width: '100%',
});

const NoResultsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: theme.spacing(4),
  width: '100%',
}));

const PaginatedVideoListNew = ({
  isLoading,
  videoList = [],
  playlistName,
  playlists = [],
  userInfo,
}) => {
  const theme = useTheme();
  const [displayedVideos, setDisplayedVideos] = useState([]);
  const [hasMore, setHasMore] = useState(true);
  const [error, setError] = useState(null);
  const [pageSize, setPageSize] = useState(BATCH_SIZE);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  // Filter videos based on playlist
  const getFilteredVideos = useCallback(() => {
    try {
      if (playlistName === 'All') {
        return videoList.sort((a, b) => (b.top ? 1 : 0) - (a.top ? 1 : 0));
      }
      const currentPlaylist = playlists.find(
        (playlist) => playlist.playlist_name === playlistName,
      );
      if (!currentPlaylist) return [];
      return videoList
        .filter((video) => currentPlaylist.match_order?.includes(video.matchId))
        .sort((a, b) => (b.top ? 1 : 0) - (a.top ? 1 : 0));
    } catch (err) {
      console.error('Error filtering videos:', err);
      setError('Error filtering videos');
      return [];
    }
  }, [videoList, playlistName, playlists]);

  // Load initial batch of videos
  useEffect(() => {
    if (!isLoading) {
      try {
        const filteredVideos = getFilteredVideos();
        const initialBatch = filteredVideos.slice(0, pageSize);
        setDisplayedVideos(initialBatch);
        setHasMore(filteredVideos.length > pageSize);
        setError(null);
      } catch (err) {
        console.error('Error loading initial videos:', err);
        setError('Error loading videos');
      }
    }
  }, [isLoading, getFilteredVideos, pageSize]);

  // Load more videos when scrolling
  const loadMore = useCallback(async () => {
    if (isLoadingMore) return;

    try {
      setIsLoadingMore(true);
      const filteredVideos = getFilteredVideos();
      const currentSize = displayedVideos.length;
      const nextBatch = filteredVideos.slice(
        currentSize,
        currentSize + BATCH_SIZE,
      );

      if (nextBatch.length > 0) {
        setDisplayedVideos((prev) => [...prev, ...nextBatch]);
        setHasMore(currentSize + nextBatch.length < filteredVideos.length);
      } else {
        setHasMore(false);
      }
      setError(null);
    } catch (err) {
      console.error('Error loading more videos:', err);
      setError('Error loading more videos');
    } finally {
      setIsLoadingMore(false);
    }
  }, [displayedVideos.length, getFilteredVideos, isLoadingMore]);

  // Intersection Observer for infinite scroll
  const observerRef = useCallback(
    (node) => {
      if (!node || !hasMore || isLoadingMore) return;

      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting && hasMore) {
            loadMore();
          }
        },
        {
          rootMargin: SCROLL_THRESHOLD,
          threshold: 0.1,
        },
      );

      observer.observe(node);
      return () => observer.disconnect();
    },
    [hasMore, isLoadingMore, loadMore],
  );

  // Reset when playlist changes
  useEffect(() => {
    setDisplayedVideos([]);
    setPageSize(BATCH_SIZE);
    setHasMore(true);
    setError(null);
  }, [playlistName]);

  if (isLoading) {
    return (
      <LoaderContainer>
        <CircularProgress />
      </LoaderContainer>
    );
  }

  return (
    <VideoContainer>
      <PlaylistHeader playlistName={playlistName} playlists={playlists} />

      {error && (
        <Alert severity="error" sx={{ m: 2 }}>
          {error}
        </Alert>
      )}

      {userInfo.accountType && (
        <div className="videos-container">
          {displayedVideos.length === 0 && !isLoading ? (
            <NoResultsContainer>
              <Typography color="text.secondary">
                此播放清單中找不到影片
              </Typography>
            </NoResultsContainer>
          ) : (
            <VideoListContainer>
              {displayedVideos.map((videoData, index) => (
                <ListItem
                  key={`${videoData.matchId}-${index}`}
                  sx={{
                    display: 'block',
                    p: 0,
                    mb: 2,
                    opacity: 1,
                    transform: 'none',
                    animation: theme.transitions.create(
                      ['opacity', 'transform'],
                      {
                        duration: theme.transitions.duration.standard,
                      },
                    ),
                  }}
                >
                  <VideoCard videoData={videoData} userInfo={userInfo} />
                </ListItem>
              ))}
            </VideoListContainer>
          )}

          {hasMore && (
            <LoaderContainer ref={observerRef}>
              {isLoadingMore && <CircularProgress size={24} />}
            </LoaderContainer>
          )}
        </div>
      )}
    </VideoContainer>
  );
};

export default PaginatedVideoListNew;
