import React, { useState } from "react";
import {
  Box,
  SwipeableDrawer,
  Paper,
  Typography,
  CssBaseline,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Global } from "@emotion/react";

import SharedContent from "./NEWSharedRenderers";

// Constants
const DRAWER_BLEEDING = 56;

// Styled Components (keeping original styling)
const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
}));

const Puller = styled(Box)(({ theme }) => ({
  width: 30,
  height: 6,
  backgroundColor: theme.palette.mode === "light" ? "#dadada" : "#666",
  borderRadius: 3,
  position: "absolute",
  top: 8,
  left: "calc(50% - 15px)",
}));

const Root = styled(Box)(({ theme }) => ({
  height: "100%",
  backgroundColor: theme.palette.mode === "light" ? "#fff" : "#121212",
}));

const SelectionContainers = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const [openDetails, setOpenDetails] = useState<any[]>([]);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  // Mobile View
  const MobileDrawer = () => (
    <Root>
      <CssBaseline />
      <Global
        styles={{
          ".MuiDrawer-root > .MuiPaper-root": {
            height: `calc(80% - ${DRAWER_BLEEDING}px)`,
            overflow: "visible",
          },
        }}
      />
      <SwipeableDrawer
        anchor="bottom"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        onOpen={() => setIsDrawerOpen(true)}
        swipeAreaWidth={DRAWER_BLEEDING}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <StyledBox
          sx={{
            position: "absolute",
            top: -DRAWER_BLEEDING,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: "visible",
            right: 0,
            left: 0,
          }}
        >
          <Puller />
          <Typography sx={{ p: 2, color: "text.secondary" }}>
            Select Players
          </Typography>
        </StyledBox>
        <StyledBox
          sx={{
            px: 2,
            pb: 2,
            height: "100%",
            overflow: "auto",
          }}
        >
          <SharedContent
            openDetails={openDetails}
            setOpenDetails={setOpenDetails}
            variant="drawer"
          />
        </StyledBox>
      </SwipeableDrawer>
    </Root>
  );

  // Desktop View
  const DesktopPaper = () => (
    <Paper
      elevation={3}
      sx={{
        position: "absolute",
        top: 24,
        left: 24,
        p: 2,
        borderRadius: 2,
        zIndex: (theme) => theme.zIndex.drawer + 2,
        bgcolor: "background.paper",
        minWidth: "320px",
        width: "50%",
        maxWidth: "480px",
        maxHeight: "calc(80vh - 48px)",
        overflow: "auto",
        "@media (max-width: 600px)": {
          top: 16,
          left: 16,
          p: 2,
          width: "calc(50% - 32px)",
          maxWidth: "none",
          height: "auto",
          maxHeight: "calc(80vh - 32px)",
          marginBottom: "16px",
        },
      }}
    >
      <SharedContent
        openDetails={openDetails}
        setOpenDetails={setOpenDetails}
        variant="paper"
      />
    </Paper>
  );

  return <>{isMobile ? <MobileDrawer /> : <DesktopPaper />}</>;
};

export default SelectionContainers;
