import { useEffect, useRef } from 'react';
import DpInputOL from 'src/components/DpInputOL/DpInputOL';
import {
  handlePlayPause,
  handleSeekBackward,
  handleSeekForward,
  handleSeekBackwardFrame,
  handleSeekForwardFrame,
} from 'src/utils/videoControl';
import './ImprovedVP2.scss';

const EnhancedVideoPlayer2 = ({
  videoRef,
  setVideoData,
  videoData,
  dpOL,
  matchId,
  setShowControls,
}) => {
  const controlsTimeout = useRef(null);
  const isPressed = useRef({});
  const isHolding = useRef(false);
  const pressTimer = useRef(null);
  const resolveRef = useRef(null);
  const keyPressTimer = useRef(null);
  const clickTimer = useRef(null);

  const isIOS =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  const handleMouseMove = () => {
    setShowControls(true);
    if (controlsTimeout.current) {
      clearTimeout(controlsTimeout.current);
    }
    controlsTimeout.current = setTimeout(() => {
      if (videoData.isPlaying === true) {
        setShowControls(false);
      }
    }, 3000);
  };

  // Keyboard event handlers
  useEffect(() => {
    if (videoData.isMobile === true || isIOS === true) {
      return;
    }
    const handleKeyDown = (e) => {
      if ([32, 37, 39].includes(e.keyCode)) {
        e.preventDefault();
      }

      const checkPressType = (keyCode, handleSeek, handleSeekFrame) => {
        return new Promise((resolve) => {
          if (!isPressed.current[keyCode]) {
            isPressed.current[keyCode] = true;

            if (pressTimer.current) {
              clearTimeout(pressTimer.current);
            }

            // Store the resolve function so we can call it from keyup if needed
            resolveRef.current = resolve;

            pressTimer.current = setTimeout(() => {
              if (isPressed.current[keyCode]) {
                isHolding.current = true;
                handleSeekFrame(videoRef, setVideoData, 1);
                resolve('hold');
              }
              // Don't resolve with 'press' here - we'll do that in keyup
            }, 200);
          } else if (isHolding.current) {
            handleSeekFrame(videoRef, setVideoData, 1);
            resolve('holding');
          }
        });
      };

      switch (e.keyCode) {
        case 32: // Space
          handlePlayPause(videoRef, videoData, setVideoData);
          break;
        case 37: // Left arrow
          checkPressType(37, handleSeekBackward, handleSeekBackwardFrame).then(
            (pressType) => {
              if (pressType === 'press') {
                handleSeekBackward(videoRef, setVideoData);
              }
            },
          );
          break;
        case 39: // Right arrow
          checkPressType(39, handleSeekForward, handleSeekForwardFrame).then(
            (pressType) => {
              if (pressType === 'press') {
                handleSeekForward(videoRef, setVideoData);
              }
            },
          );
          break;
        default:
          break;
      }
    };

    const handleKeyUp = (e) => {
      switch (e.keyCode) {
        case 37: // Left arrow
          if (
            isPressed.current[37] &&
            !isHolding.current &&
            resolveRef.current
          ) {
            resolveRef.current('press');
          }
          isPressed.current[37] = false;
          isHolding.current = false;
          if (pressTimer.current) {
            clearTimeout(pressTimer.current);
            pressTimer.current = null;
          }
          resolveRef.current = null;
          break;
        case 39: // Right arrow
          if (
            isPressed.current[39] &&
            !isHolding.current &&
            resolveRef.current
          ) {
            resolveRef.current('press');
          }
          isPressed.current[39] = false;
          isHolding.current = false;
          if (pressTimer.current) {
            clearTimeout(pressTimer.current);
            pressTimer.current = null;
          }
          resolveRef.current = null;
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
      clearInterval(keyPressTimer.current);
      clearTimeout(clickTimer.current);
      if (pressTimer.current) {
        clearTimeout(pressTimer.current);
      }
    };
  }, [videoData]);

  return (
    <div className="video-container" onMouseMove={handleMouseMove}>
      {dpOL === true && (
        <DpInputOL
          videoRef={videoRef}
          setVideoData={setVideoData}
          matchId={matchId}
        />
      )}
      <div className="video-wrapper">
        <video
          ref={videoRef}
          className="video-player"
          preload="auto"
          playsInline={true}
        />
      </div>
    </div>
  );
};

export default EnhancedVideoPlayer2;
