import { ballDataIndex, ballStartTimeIndex } from 'src/constants';

export const handlePlayPause = (videoRef, videoData, setVideoData) => {
  if (videoRef.current.currentTime === videoRef.current.duration) {
    videoRef.current.currentTime = 0;
  }

  if (videoData.isPlaying) {
    try {
      videoRef.current.pause();
    } catch (error) {}
  } else {
    try {
      videoRef.current.play();
    } catch (error) {}
  }

  setVideoData((prevState) => ({
    ...prevState,
    isPlaying: !videoData.isPlaying,
  }));
};

export const handleSeekForward = (videoRef, setVideoData) => {
  const currentTime = videoRef.current.currentTime;
  const duration = videoRef.current.duration;
  const seekTime = currentTime + 5;
  setVideoData((prevStatus) => ({
    ...prevStatus,
    skipped: true,
  }));

  if (seekTime >= duration) {
    videoRef.current.currentTime = duration;
  } else {
    videoRef.current.currentTime = seekTime;
  }
};

export const handleSeekBackward = (videoRef, setVideoData) => {
  const currentTime = videoRef.current.currentTime;
  const seekTime = currentTime - 5;
  setVideoData((prevStatus) => ({
    ...prevStatus,
    skipped: true,
  }));

  if (seekTime <= 0) {
    videoRef.current.currentTime = 0;
  } else {
    videoRef.current.currentTime = seekTime;
  }
};

let skipInterval = null;
let skipDuration = 100; // Adjust this value to control the skip duration
let clickCounterForward = 0;
let clickCounterBackward = 0;
const maxSkipFrames = 5;
const resetDuration = 200; // Adjust this value to control the duration before resetting the click counters
let lastClickTime = Date.now();

const isClickIntervalExpired = () => {
  const currentTime = Date.now();
  return currentTime - lastClickTime > resetDuration;
};

export const handleSeekForwardFrame = (
  videoRef,
  setVideoData,
  maxFrames = maxSkipFrames,
) => {
  clearInterval(skipInterval);
  setVideoData((prevStatus) => ({
    ...prevStatus,
    skipped: true,
  }));

  clickCounterForward++;
  const skipFrames = Math.pow(2, clickCounterForward - 1);
  const actualSkipFrames = Math.min(skipFrames, maxFrames);
  const seekTime = videoRef.current.currentTime + actualSkipFrames / 24; // Assuming 24 frames per second
  videoRef.current.currentTime = seekTime;

  lastClickTime = Date.now();

  skipInterval = setInterval(() => {
    if (isClickIntervalExpired()) {
      clickCounterForward = 0;
      clearInterval(skipInterval);
    }
  }, skipDuration);
};

export const handleSeekBackwardFrame = (
  videoRef,
  setVideoData,
  maxFrames = maxSkipFrames,
) => {
  clearInterval(skipInterval);
  setVideoData((prevStatus) => ({
    ...prevStatus,
    skipped: true,
  }));

  clickCounterBackward++;
  const skipFrames = Math.pow(2, clickCounterBackward - 1);
  const actualSkipFrames = Math.min(skipFrames, maxFrames);
  const seekTime = videoRef.current.currentTime - actualSkipFrames / 24; // Assuming 24 frames per second
  videoRef.current.currentTime = seekTime >= 0 ? seekTime : 0;

  lastClickTime = Date.now();

  skipInterval = setInterval(() => {
    if (isClickIntervalExpired()) {
      clickCounterBackward = 0;
      clearInterval(skipInterval);
    }
  }, skipDuration);
};

export const handleSeekForwardBall = (
  videoRef,
  commentaryTimes,
  roundResults,
  currentRound,
  currentBall,
  setVideoData,
) => {
  setVideoData((prevStatus) => ({
    ...prevStatus,
    skipped: true,
  }));
  const currentTime = videoRef.current.currentTime;
  if (commentaryTimes.lastCommentaryEndtime != 0) {
    // if before first
    if (currentTime < commentaryTimes.firstCommentaryStarttime) {
      videoRef.current.currentTime =
        commentaryTimes.firstCommentaryStarttime + 0.01;
    }
    //if after last
    else if (currentTime >= commentaryTimes.lastCommentaryStarttime) {
      videoRef.current.currentTime =
        commentaryTimes.lastCommentaryStarttime + 0.01;
    } else {
      const lastBall = roundResults[currentRound][ballDataIndex].length - 1;
      // if same round
      if (currentBall !== lastBall) {
        videoRef.current.currentTime =
          roundResults[currentRound][ballDataIndex][currentBall + 1][
            ballStartTimeIndex
          ] + 0.01;
      } else {
        videoRef.current.currentTime =
          roundResults[currentRound + 1][ballDataIndex][0][ballStartTimeIndex] +
          0.01;
      }
    }
  }
};

export const handleSeekBackwardBall = (
  videoRef,
  commentaryTimes,
  roundResults,
  currentRound,
  currentBall,
  setVideoData,
) => {
  const currentTime = videoRef.current.currentTime;
  setVideoData((prevStatus) => ({
    ...prevStatus,
    skipped: true,
  }));
  // if before first
  if (commentaryTimes.lastCommentaryEndtime != 0) {
    if (currentTime <= commentaryTimes.firstCommentaryEndtime) {
      videoRef.current.currentTime =
        commentaryTimes.firstCommentaryStarttime + 0.01;
    }
    //if after last
    else if (currentTime > commentaryTimes.lastCommentaryEndtime) {
      videoRef.current.currentTime =
        commentaryTimes.lastCommentaryStarttime + 0.01;
    } else {
      // if same round
      if (currentBall !== 0) {
        videoRef.current.currentTime =
          roundResults[currentRound][ballDataIndex][currentBall - 1][
            ballStartTimeIndex
          ] + 0.01;
      } else {
        const lastBall =
          roundResults[currentRound - 1][ballDataIndex].length - 1;
        videoRef.current.currentTime =
          roundResults[currentRound - 1][ballDataIndex][lastBall][
            ballStartTimeIndex
          ] + 0.01;
      }
    }
  }
};
