import { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Snackbar, SnackbarContent } from '@mui/material';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { GoogleLogin } from '@react-oauth/google';
import Cookies from 'js-cookie';

import './Login.scss';

const defaultTheme = createTheme();

export default function LoginForm({ setToken }) {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [loginError, setLoginError] = useState(false);
  const [isSigningUp, setIsSigningUp] = useState(false);
  const [fullName, setFullName] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordValidation, setPasswordValidation] = useState({
    length: false,
    upperCase: false,
    lowerCase: false,
    number: false,
    specialChar: false,
  });

  const navigate = useNavigate();

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const updateAuthContext = (token) => {
    setToken(token);
    const decodedToken = jwtDecode(token);
    const expirationTimeInSeconds = decodedToken.exp;
    const expirationDate = new Date(expirationTimeInSeconds * 1000);

    Cookies.set('token', token, {
      expires: expirationDate,
      secure: true,
      sameSite: 'strict',
    });
  };

  const validatePassword = (password) => {
    return {
      length: password.length >= 8,
      upperCase: /[A-Z]/.test(password),
      lowerCase: /[a-z]/.test(password),
      number: /\d/.test(password),
      specialChar: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    };
  };

  const handleGoogleSuccess = async (credentialResponse) => {
    try {
      // Validate full name if signing up
      if (isSigningUp && !fullName.trim()) {
        setSnackbarMessage('請輸入全名');
        setSnackbarOpen(true);
        return;
      }

      // Validate password match if password is provided
      if (password && password !== confirmPassword) {
        setSnackbarMessage('密碼不匹配');
        setSnackbarOpen(true);
        return;
      }

      // Validate password requirements if password is provided
      if (password && !Object.values(passwordValidation).every(Boolean)) {
        setSnackbarMessage('密碼不符合要求');
        setSnackbarOpen(true);
        return;
      }

      const response = await axios.post('/api/auth/google', {
        credential: credentialResponse.credential,
        actual_name: isSigningUp ? fullName : undefined,
        username: username.trim() || undefined, // Only send if provided
        password: password || undefined, // Only send if provided
      });
      const newToken = response.data;
      setSnackbarMessage(response?.data?.status || '登入失敗');
      updateAuthContext(newToken);
      setSnackbarMessage(isSigningUp ? '註冊成功' : '登入成功');
      navigate('/');
    } catch (error) {
      console.error('Google 登入錯誤:', error);
      setSnackbarOpen(true);
      setLoginError(true);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);

    axios
      .post('/api/login', data)
      .then((response) => {
        if (response.data.message === 'Invalid credentials') {
          setSnackbarMessage(
            response?.data?.status || '登入失敗：請檢查您的憑證',
          );
          setSnackbarOpen(true);
          setLoginError(true);
        } else if (response?.data?.status) {
          setSnackbarMessage(response?.data?.status);
          setSnackbarOpen(true);
          setLoginError(true);
        } else {
          const newToken = response.data;
          updateAuthContext(newToken);
          setSnackbarMessage('Logged in');
          navigate('/');
        }
      })
      .catch((error) => {
        setSnackbarMessage('登入失敗：請檢查您的憑證');
        setSnackbarOpen(true);
        setLoginError(true);
      });
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Grid
        className={`main-login ${loginError ? 'error' : ''}`}
        container
        component="main"
        sx={{ height: '100vh' }}
      >
        <CssBaseline />
        <Grid
          item
          xs={false}
          sm={4}
          md={7}
          className="login-background"
          sx={{
            backgroundImage:
              'url(https://i.etsystatic.com/45803373/r/il/03d92d/5454890829/il_fullxfull.5454890829_a87w.jpg)',
            backgroundRepeat: 'no-repeat',
            backgroundColor: (t) =>
              t.palette.mode === 'dark'
                ? t.palette.grey[50]
                : t.palette.grey[900],
            backgroundSize: 'cover',
            backgroundPosition: 'center',
          }}
        />
        <Grid
          className="login-panel"
          item
          xs={12}
          sm={8}
          md={5}
          component={Paper}
          elevation={6}
          square
        >
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar className="login-avatar">
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5" className="login-title">
              {isSigningUp ? '註冊新帳戶' : '登入系統'}
            </Typography>
            <Box
              component="form"
              noValidate
              onSubmit={handleSubmit}
              sx={{ mt: 1, width: '100%' }}
              className="login-form"
            >
              {!isSigningUp && (
                <>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="identifier"
                    label="電郵/用户"
                    name="identifier"
                    autoComplete="identifier"
                    autoFocus
                    className="login-input"
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="密碼"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    className="login-input"
                  />
                  <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ mt: 3, mb: 2 }}
                    className="login-button"
                  >
                    進入系統
                  </Button>
                </>
              )}

              {isSigningUp && (
                <>
                  <div className="info-text">
                    <p style={{ color: '#FFFFFF', marginBottom: '10px' }}>
                      注意：系統預設會使用您的Google用戶名。若您所在地區無法使用VPN或Google服務被封鎖，建議您現在設定用戶名和密碼。
                    </p>
                  </div>

                  <TextField
                    margin="normal"
                    fullWidth
                    id="fullName"
                    label="全名"
                    value={fullName}
                    onChange={(e) => setFullName(e.target.value)}
                    className="login-input"
                  />

                  <TextField
                    margin="normal"
                    fullWidth
                    id="username"
                    label="用戶名（選填）"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    className="login-input"
                  />

                  <TextField
                    margin="normal"
                    fullWidth
                    type="password"
                    id="password"
                    label="密碼（選填）"
                    value={password}
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setPasswordValidation(validatePassword(e.target.value));
                    }}
                    className="login-input"
                  />

                  <TextField
                    margin="normal"
                    fullWidth
                    type="password"
                    id="confirmPassword"
                    label="確認密碼"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    error={
                      password &&
                      confirmPassword &&
                      password !== confirmPassword
                    }
                    helperText={
                      password &&
                      confirmPassword &&
                      password !== confirmPassword
                        ? '密碼不匹配'
                        : ''
                    }
                    className="login-input"
                  />

                  {password && (
                    <div style={{ marginTop: '10px', fontSize: '14px' }}>
                      <p
                        style={{
                          color: passwordValidation.length
                            ? '#6ccf4d'
                            : '#f12f2f',
                        }}
                      >
                        ✓ 密碼長度至少8個字符
                      </p>
                      <p
                        style={{
                          color: passwordValidation.upperCase
                            ? '#6ccf4d'
                            : '#f12f2f',
                        }}
                      >
                        ✓ 至少包含一個大寫字母
                      </p>
                      <p
                        style={{
                          color: passwordValidation.lowerCase
                            ? '#6ccf4d'
                            : '#f12f2f',
                        }}
                      >
                        ✓ 至少包含一個小寫字母
                      </p>
                      <p
                        style={{
                          color: passwordValidation.number
                            ? '#6ccf4d'
                            : '#f12f2f',
                        }}
                      >
                        ✓ 至少包含一個數字
                      </p>
                      <p
                        style={{
                          color: passwordValidation.specialChar
                            ? '#6ccf4d'
                            : '#f12f2f',
                        }}
                      >
                        ✓ 至少包含一個特殊字符
                      </p>
                      {password && confirmPassword && (
                        <p
                          style={{
                            color:
                              password === confirmPassword
                                ? '#6ccf4d'
                                : '#f12f2f',
                          }}
                        >
                          ✓ 兩次輸入的密碼相符
                        </p>
                      )}
                    </div>
                  )}
                </>
              )}

              <div className="auth-container">
                <div className="google-login-wrapper">
                  <GoogleLogin
                    onSuccess={handleGoogleSuccess}
                    onError={() => {
                      setSnackbarMessage('Google 登入失敗，請重試。');
                      setSnackbarOpen(true);
                      setLoginError(true);
                    }}
                    shape="rectangular"
                    theme="filled_blue"
                    text="continue_with"
                    useOneTap
                    disabled={isSigningUp && !fullName.trim()}
                  />
                </div>
              </div>

              <Button
                className="signup-switch-button"
                variant="text"
                sx={{ mt: 2 }}
                onClick={() => {
                  setIsSigningUp(!isSigningUp);
                  setFullName('');
                }}
              >
                {isSigningUp ? '已有帳戶？登入' : '首次使用？使用 Google 註冊'}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Snackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        className="login-custom-snackbar"
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={handleSnackbarClose}
      >
        <SnackbarContent message={snackbarMessage} />
      </Snackbar>
    </ThemeProvider>
  );
}
