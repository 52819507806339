import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Box, styled } from "@mui/material";

import useStore from "../../store/store";

export const WIPBanner = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.warning.light,
  color: theme.palette.warning.contrastText,
  padding: theme.spacing(0.5),
  fontSize: "0.75rem",
  textAlign: "center",
  [theme.breakpoints.down("sm")]: {
    fontSize: "0.7rem",
    padding: theme.spacing(0.25),
  },
}));

export const Banner: FC = () => {
  const { t } = useTranslation();

  // Get values directly from store
  const error = useStore((state) => state.error);
  const isLoading = useStore((state) => state.isLoading);

  const getBannerMessage = () => {
    if (error) return t("common.errorFallback");
    if (isLoading) return t("common.loading");
    return t("common.liveData");
  };

  const getBannerColor = (): "error" | "warning" | "success" => {
    if (error) return "error";
    if (isLoading) return "warning";
    return "success";
  };

  return (
    <WIPBanner
      sx={{
        backgroundColor: (theme) => theme.palette[getBannerColor()].light,
      }}
    >
      {getBannerMessage()}
    </WIPBanner>
  );
};

export default Banner;
