import { Slider } from '@mui/material';
import { styled } from '@mui/material/styles';
import './ProgressBar.scss';

import {
  ballStartTimeIndex,
  ballEndTimeIndex,
  ballHomePlayerScoreIndex,
  ballAwayPlayerScoreIndex,
  roundNumberIndex,
  roundHomePlayerScoreIndex,
  roundAwayPlayerScoreIndex,
} from 'src/constants';
import './ProgressBar.scss';

const CustomSlider = styled(Slider)(({ theme }) => ({
  color: '#ff0000',
  height: 4,
  // Add padding to increase touch area vertically
  padding: '15px 0',
  '& .MuiSlider-thumb': {
    width: 12,
    height: 12,
    backgroundColor: '#ff0000',
    // Create a larger invisible touch target
    '&::before': {
      content: '""',
      position: 'absolute',
      width: '36px',
      height: '36px',
      borderRadius: '50%',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    '&:focus, &:hover, &.Mui-active': {
      boxShadow: 'inherit',
    },
    transform: 'translate(0%, -50%)',
  },
  '& .MuiSlider-track': {
    height: 4,
    backgroundColor: '#ff0000',
  },
  '& .MuiSlider-rail': {
    height: 4,
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
    // Increase the hit area of the rail
    '&::before': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '24px',
      top: '50%',
      transform: 'translateY(-50%)',
    },
  },
  // Add touch-action manipulation for better touch handling
  '@media (pointer: coarse)': {
    '& .MuiSlider-thumb': {
      touchAction: 'manipulation',
    },
  },
}));

const ProgressBar = ({
  duration = 0,
  currentTime = 0,
  setCurrentTime,
  roundResults = [],
  seekPreview,
  onMouseMove,
  onMouseLeave,
  videoRef,
  showPreview,
}) => {
  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const getCurrentCaption = (time) => {
    for (const roundData of roundResults) {
      const roundInfo = roundData[0];
      const ballsInfo = roundData[1];

      for (const ballData of ballsInfo) {
        const ballStart = ballData[ballStartTimeIndex];
        const ballEnd = ballData[ballEndTimeIndex];

        if (time >= ballStart && time <= ballEnd) {
          const roundNumber = roundInfo[roundNumberIndex];
          const homePlayerRoundScore = roundInfo[roundHomePlayerScoreIndex];
          const awayPlayerRoundScore = roundInfo[roundAwayPlayerScoreIndex];
          const ballHomePlayerScore = ballData[ballHomePlayerScoreIndex];
          const ballAwayPlayerScore = ballData[ballAwayPlayerScoreIndex];

          return `第${roundNumber}局 比賽比分 ${homePlayerRoundScore}:${awayPlayerRoundScore} 局內比分 ${ballHomePlayerScore}:${ballAwayPlayerScore}`;
        }
      }
    }
    return '';
  };

  const getAllIntervals = () => {
    const intervals = [];
    roundResults.forEach((roundData) => {
      const ballsInfo = roundData[1];
      ballsInfo.forEach((ballData) => {
        intervals.push({
          start: ballData[ballStartTimeIndex],
          end: ballData[ballEndTimeIndex],
        });
      });
    });
    return intervals;
  };

  const handleChange = (_, value) => {
    if (!videoRef.current) {
      return;
    }
    videoRef.current.currentTime = value;
    setCurrentTime(value);
  };

  return (
    <div className="progress-bar">
      <div className="timeline-background">
        {roundResults.map((roundData, roundIndex) => {
          const [, ballsInfo] = roundData;

          return ballsInfo.map((ballData, ballIndex) => {
            const start = (ballData[ballStartTimeIndex] / duration) * 100;
            const end = (ballData[ballEndTimeIndex] / duration) * 100;
            const width = end - start;

            return (
              <div
                key={`${roundIndex}-${ballIndex}`}
                className="timeline-highlight"
                style={{
                  left: `${start}%`,
                  width: `${width}%`,
                }}
              />
            );
          });
        })}
      </div>

      <div className="interval-markers">
        {getAllIntervals().map((interval, index) => (
          <div
            key={index}
            className="interval-marker"
            style={{
              left: `${(interval.start / duration) * 100}%`,
              width: `${((interval.end - interval.start) / duration) * 100}%`,
            }}
          />
        ))}
      </div>

      {seekPreview.visible && showPreview && (
        <div className="seek-preview">
          <div>{formatTime(seekPreview.time)}</div>
          <div className="seek-preview-caption">
            {getCurrentCaption(seekPreview.time)}
          </div>
        </div>
      )}

      <CustomSlider
        value={currentTime}
        min={0}
        max={duration}
        onChange={handleChange}
        onMouseMove={onMouseMove}
        onMouseLeave={onMouseLeave}
      />
      <div className="current-caption-container">
        <div className="current-caption">{getCurrentCaption(currentTime)}</div>
      </div>
    </div>
  );
};

export default ProgressBar;
