import { Box, Divider } from "@mui/material";
import { FirstPlayerInfo, ReachablePlayerInfo } from "../../store/Type"; // Add this import
import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";
import { NEWSearchablePlayerList } from "./NEWSearchablePlayerList";
import { NEWDepthSlider } from "./NEWDepthSlider";
import useStore from "../../store/store";
import GraphControls from "../../GraphVisualization/GraphControls";
// import TennisConnectionSequence from "./TennisConnectionSequence";

export const NEWSelectionContent = () => {
  const { t } = useTranslation();

  const {
    currentCategory,
    players,
    firstPlayer,
    secondPlayer,

    nodeDepth,
    reachablePlayers,
    setFirstPlayer,
    setSecondPlayer,
    setThirdPlayer,
    setNodeDepth,
  } = useStore();

  // Get the category key (COM_FSingle, COM_MDouble, etc.)
  const getCategoryKey = useCallback(() => {
    if (
      !currentCategory ||
      !firstPlayer ||
      !reachablePlayers[currentCategory]?.[firstPlayer]
    ) {
      return ""; // Return empty string instead of null
    }

    // Get the first key from depth_structured
    return (
      Object.keys(
        reachablePlayers[currentCategory][firstPlayer].depth_structured
      )[0] || ""
    ); // Ensure we always return a string
  }, [currentCategory, firstPlayer, reachablePlayers]);

  // Get reachable players for current depth
  const getReachablePlayers = useCallback(() => {
    if (
      !currentCategory ||
      !firstPlayer ||
      !reachablePlayers[currentCategory]?.[firstPlayer]
    ) {
      return [];
    }

    const categoryKey = getCategoryKey();
    if (!categoryKey) return [];

    const reachablePlayersData =
      reachablePlayers[currentCategory][firstPlayer]?.reachable_players?.[
        categoryKey
      ] || {};

    return Object.entries(reachablePlayersData)
      .filter(([_, player]) => player.depth <= nodeDepth)
      .map(([id, player]) => ({
        id: Number(id),
        ...player,
        games: player.path?.[0]?.games?.length || 0,
      }));
  }, [
    currentCategory,
    firstPlayer,
    reachablePlayers,
    nodeDepth,
    getCategoryKey,
  ]);

  // Get max depth
  const maxDepth = useMemo(() => {
    if (
      !currentCategory ||
      !firstPlayer ||
      !reachablePlayers[currentCategory]?.[firstPlayer]
    ) {
      return 6;
    }
    return reachablePlayers[currentCategory][firstPlayer]?.max_depth || 6;
  }, [currentCategory, firstPlayer, reachablePlayers]);

  // Handle first player selection
  const handlePlayerOneSelect = useCallback(
    (
      event: React.SyntheticEvent,
      value: (FirstPlayerInfo | ReachablePlayerInfo) | null
    ) => {
      setFirstPlayer(value ? value.node : null);
      setSecondPlayer(null);
      setThirdPlayer(null);
    },
    [setFirstPlayer, setSecondPlayer, setThirdPlayer]
  );

  // Handle second player selection
  const handlePlayerTwoSelect = useCallback(
    (
      event: React.SyntheticEvent,
      value: (FirstPlayerInfo | ReachablePlayerInfo) | null
    ) => {
      setSecondPlayer(value ? value.node : null);
      setThirdPlayer(null);
    },
    [setSecondPlayer, setThirdPlayer]
  );

  return (
    <Box sx={{ width: "100%" }}>
      <GraphControls />
      <Divider sx={{ my: 2 }} />
      <NEWSearchablePlayerList
        label={t("selectionDrawer.selectFirstPlayer")}
        value={
          firstPlayer &&
          currentCategory &&
          players[currentCategory]?.[firstPlayer]
            ? {
                node: firstPlayer,
                name: players[currentCategory][firstPlayer].name,
                country_code:
                  players[currentCategory][firstPlayer].country_code ?? "", // Ensure non-null
                head_shot: players[currentCategory][firstPlayer].head_shot,
                path: players[currentCategory][firstPlayer].connections ?? [], // Map connections to path
              }
            : null
        }
        onChange={handlePlayerOneSelect}
        options={
          currentCategory
            ? Object.entries(players[currentCategory] || {}).map(
                ([id, player]) => ({
                  node: Number(id),
                  name: player.name,
                  country_code: player.country_code ?? "", // Ensure non-null
                  head_shot: player.head_shot,
                  path: player.connections ?? [], // Map connections to path
                })
              )
            : []
        }
        placeholderKey="selectionDrawer.searchPlayers"
        mode="selectFirstPlayer"
      />

      <Divider sx={{ my: 2 }} />

      <NEWDepthSlider
        depth={nodeDepth}
        maxDepth={maxDepth}
        onDepthChange={setNodeDepth}
        disabled={!firstPlayer}
      />

      <NEWSearchablePlayerList
        label={t("selectionDrawer.selectSecondPlayer")}
        value={
          secondPlayer && currentCategory && firstPlayer && getCategoryKey()
            ? {
                // id: secondPlayer,
                ...reachablePlayers[currentCategory]?.[firstPlayer]
                  ?.reachable_players?.[getCategoryKey()]?.[secondPlayer],
              }
            : null
        }
        onChange={handlePlayerTwoSelect}
        options={getReachablePlayers()}
        disabled={!firstPlayer}
        placeholderKey="selectionDrawer.searchPlayers"
        mode="selectSecondPlayer"
      />
      <Divider sx={{ my: 2 }} />

      {/* <TennisConnectionSequence /> */}
    </Box>
  );
};
