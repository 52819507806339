import React, { useState, useEffect } from 'react';
import { fetchMatchDataAPI } from 'src/api/videoService';
import { fetchUserListAPI } from 'src/api/userService';
import { Autocomplete, TextField, Chip, Button, Alert, Snackbar } from '@mui/material';
import { createPlaylistAPI, editPlaylistAPI } from 'src/api/playlistService';
import { useParams } from 'react-router-dom';
import * as playlistAPI from 'src/api/playlistService';
import { fetchPlayerDataAPI } from 'src/api/playerService';
import PlaylistVideoPanel from './PlaylistVideoPanel';
import DraggableVideoItem from './DraggableVideo';
import { PlayerData } from 'types/playerTypes';
import ViewCarouselOutlinedIcon from '@mui/icons-material/ViewCarouselOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import { AlertColor } from '@mui/material/Alert';
import { styled } from '@mui/material/styles';

import Cookies from 'js-cookie';
import { countryCodeMapping, countries } from 'src/constants';
import './playlistManagement.scss';

import {
    matchIdIndex,
    videoNameIndex,
    stageIndex,
    locationIndex,
    homePlayerIndex,
    awayPlayerIndex,
    urlIndex,
    dateIndex,
    thumbnailIndex,
    subEventIndex,
    homePlayerMatchScoreIndex,
    awayPlayerMatchScoreIndex,
    categoryIndex,
    uploadUserIndex,
} from 'src/constants';

interface PlaylistManagementProps {
    userType: string;
    userName: string;
}

interface Tag {
    id: string;
    label: string;
}

interface Video {
    id: string;
    isPinned: boolean;
    videoName: string;
}

interface User {
    user_id: number;
    username: string;
    category: 'intellectual' | 'physical' | 'able';
}

interface UserOption {
    id: number;
    label: string;
}

interface VideoListItem {
    matchId: string;
    videoName: string;
    stage: string;
    date: string;
    location: string;
    homePlayer: string;
    awayPlayer: string;
    TNUrl: string;
    subEvent: string;
    category: string;
    uploadUser: string;
    homePlayerMatchScore: string;
    awayPlayerMatchScore: string;
    FileUrl: string;
}

const StyledSnackbar = styled(Snackbar)(({ theme }) => ({
    '& .MuiAlert-root': {
        backgroundColor: '#2F2F2F',
        color: '#FFFFFF',
        '& .MuiAlert-icon': {
            color: 'green'
        }
    }
}));

const StyledButton = styled(Button)({
    backgroundColor: '#E50914',
    color: 'white',
    '&:hover': {
        backgroundColor: '#B2070E',
    },
    padding: '8px 20px',
    textTransform: 'none',
});

const PlaylistManagement: React.FC<PlaylistManagementProps> = ({
    userType, userName
}) => {
    const token = Cookies.get('token');
    const { playlistId } = useParams();

    const [playlistName, setPlaylistName] = useState('');
    const [playlistDescription, setPlaylistDescription] = useState('');
    const [userOptions, setUserOptions] = useState<UserOption[]>([]);
    const [viewPermission, setViewPermission] = useState<UserOption[]>([]);
    const [videoList, setVideoList] = useState<VideoListItem[]>([]);
    const [allTags, setAllTags] = useState<string[]>([]);
    const [tags, setTags] = useState<Tag[]>([]);
    const [addedVideoIds, setAddedVideoIds] = useState<Set<string>>(new Set());
    const [videos, setVideos] = useState<Video[]>([]);
    const [draggedItem, setDraggedItem] = useState<number | null>(null);
    const [draggedOverItem, setDraggedOverItem] = useState<number | null>(null);

    const allTagOptions: Tag[] = allTags.map(tag => ({ id: tag, label: tag }));
    const mode = playlistId === '-1' || !playlistId ? 'create' : 'edit';
    const [playerList, setPlayerList] = useState<PlayerData[]>([]);

    const [view, setView] = useState<'details' | 'selection' | 'playlist'>('details');
    const [searchTerm, setSearchTerm] = useState('');
    const [viewMode, setViewMode] = useState<'grid' | 'list'>('grid');
    const [groupingMethod, setGroupingMethod] = useState<'events' | 'countries' | 'players'>('events');
    const [isExpanded, setIsExpanded] = useState(false);
    const [open, setOpen] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');
    const [notificationType, setNotificationType] = useState<AlertColor>('success');

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };
    const handleAlertClose = (event?: React.SyntheticEvent) => {
        setOpen(false);
    };

    const handleViewChange = (_: any, newView: 'details' | 'selection' | 'playlist') => {
        if (newView !== null) {
            setView(newView);
        }
    };


    const fetchPlayersData = async () => {

        try {
            const response = await fetchPlayerDataAPI(
                {
                    player_name: '',
                    categories: [].join(','),
                    countries: countries.join(','),
                    existingMatch: true,
                    sortCriteria: null,
                    accountType: userType,
                },
                token as string,
            );
            const responseData = response.data;

            if (responseData === undefined) {
                setPlayerList([]);
                return;
            }

            const playerDict = responseData.map((playerData: any) => {
                const birthDate = playerData.dob
                    ? new Date(playerData.dob)
                    : new Date('2000-01-01');
                const currentDate = new Date();
                let actual_age = currentDate.getFullYear() - birthDate.getFullYear();

                const currentMonth = currentDate.getMonth();
                const birthMonth = birthDate.getMonth();
                const currentDay = currentDate.getDate();
                const birthDay = birthDate.getDate();
                const countryCode = countryCodeMapping[playerData.country_code as keyof typeof countryCodeMapping] || '';

                if (
                    currentMonth < birthMonth ||
                    (currentMonth === birthMonth && currentDay < birthDay)
                ) {
                    actual_age--;
                }

                let head_shot_path = playerData.head_shot;
                let player2_head_shot_path = playerData.player2_head_shot;

                if (head_shot_path && !head_shot_path.startsWith('https')) {
                    head_shot_path = `${process.env.PUBLIC_URL}/players/headshots/${head_shot_path}`;
                }

                if (
                    player2_head_shot_path &&
                    !player2_head_shot_path.startsWith('https')
                ) {
                    player2_head_shot_path = `${process.env.PUBLIC_URL}/players/headshots/${player2_head_shot_path}`;
                }

                const playerDict = {
                    ...playerData,
                    countryCode: countryCode,
                    actual_age: actual_age,
                    head_shot: head_shot_path,
                    player2_head_shot: player2_head_shot_path,
                };

                return playerDict;
            });
            setPlayerList(playerDict);
        } catch (error) {
            console.log(error)
        }
    };

    const fetchPlaylists = async (videoListData: VideoListItem[]) => {
        try {
            const response = await playlistAPI.fetchPlaylistDataAPI(
                { user_name: userName, user_type: userType, playlist_id: playlistId ? parseInt(playlistId, 10) : undefined },
                token as string
            );
            setPlaylistName(response[0][0].playlist_name);
            setPlaylistDescription(response[0][0].description);

            // Get the match_order array from the playlist
            const playlistMatchIds = response[0][0].match_order;

            // Set videos for the playlist
            setVideos(playlistMatchIds.map((id: number) => {
                setAddedVideoIds(prevIds => new Set(prevIds).add(id.toString()));
                const videoData = videoListData.find(video => video.matchId.toString() === id.toString());
                return {
                    id: id.toString(),
                    isPinned: response[0][0].is_pin.includes(id),
                    videoName: videoData ? videoData.videoName : `Video ${id}`
                };
            }));

            const tags = new Set<string>();
            response[0].forEach((playlist: any) => {
                playlist.custom_tags.forEach((tag: string) => tags.add(tag));
            });
            setAllTags(Array.from(tags));

            return response;
        } catch (api_error) {
            console.error('Error fetching playlists:', api_error);
            throw api_error;
        }
    };

    const fetchData = async () => {
        try {
            const matchResponse = await fetchMatchDataAPI({ accountType: userType, user: userName }, token as string);
            const responseData = matchResponse.videoData;

            const mergedData = responseData.map((videoData: any[]) => {
                const fileUrl = videoData[urlIndex];
                const urlWithoutSuffix = fileUrl.substring(0, fileUrl.lastIndexOf('.'));
                const TNUrl = videoData[thumbnailIndex];
                const newFileUrl = `${process.env.PUBLIC_URL}/videos/${urlWithoutSuffix}/${fileUrl}`;
                const newTNUrl = `${process.env.PUBLIC_URL}/videos/${urlWithoutSuffix}/${TNUrl}`;

                const fetchedMatchDate = videoData[dateIndex];
                const dateWithoutTime = fetchedMatchDate.replace(/\s\d{2}:\d{2}:\d{2}\s\w{3}$/, '');

                return {
                    matchId: videoData[matchIdIndex],
                    videoName: videoData[videoNameIndex],
                    stage: videoData[stageIndex],
                    date: dateWithoutTime,
                    location: videoData[locationIndex],
                    homePlayer: videoData[homePlayerIndex],
                    awayPlayer: videoData[awayPlayerIndex],
                    TNUrl: newTNUrl,
                    FileUrl: newFileUrl,
                    subEvent: videoData[subEventIndex],
                    category: videoData[categoryIndex],
                    uploadUser: videoData[uploadUserIndex],
                    homePlayerMatchScore: videoData[homePlayerMatchScoreIndex],
                    awayPlayerMatchScore: videoData[awayPlayerMatchScoreIndex],
                };
            });

            setVideoList(mergedData);
            return mergedData; // Return the data for the next function
        } catch (error) {
            console.error("Error fetching match data:", error);
            throw error;
        }
    };

    const fetchUserList = async () => {
        try {
            const response: User[] = await fetchUserListAPI(token as string);

            let category: User['category'];
            switch (userType) {
                case 'm':
                    category = 'intellectual';
                    break;
                case 'p':
                    category = 'physical';
                    break;
                default:
                    category = 'able';
            }

            const filteredUsers = response
                .filter((user: User) => user.category === category)
                .map((user: User) => ({
                    id: user.user_id,
                    label: user.username,
                }));
            setUserOptions(filteredUsers);

            setViewPermission(prev =>
                prev.map(permission => ({
                    ...permission,
                    label: filteredUsers.find(user => user.id === permission.id)?.label || 'Unknown User',
                }))
            );

        } catch (error) {
            console.error('Error fetching user list:', error);
        }
    };


    useEffect(() => {
        const fetchAllData = async () => {
            try {
                const videoListData = await fetchData();
                await fetchPlaylists(videoListData);
                await fetchUserList();
                await fetchPlayersData();
            } catch (error) {
                console.error('Error in data fetching sequence:', error);
            }
        };

        fetchAllData();
    }, [userType, userName]);


    const handleTagChange = (event: React.SyntheticEvent, newValue: (Tag | string)[]) => {
        const updatedTags = newValue.map(tag => {
            if (typeof tag === 'string') {
                // This is a new tag
                return { id: tag, label: tag };
            }
            return tag;
        });
        setTags(updatedTags as Tag[]);
    };

    const handleViewPermissionChange = (event: React.SyntheticEvent, newValue: UserOption[]) => {
        setViewPermission(newValue);
    };

    const handleAddVideo = (videoToAdd: VideoListItem) => {
        setVideos(prevVideos => [...prevVideos, { id: videoToAdd.matchId, isPinned: false, videoName: videoToAdd.videoName }]);
        setAddedVideoIds(prevIds => new Set(prevIds).add(videoToAdd.matchId.toString()));
    };

    const handleRemoveVideo = (videoId: string) => {
        setVideos(prevVideos => prevVideos.filter(video => video.id !== videoId));
        setAddedVideoIds(prevIds => {
            const newIds = new Set(prevIds);
            newIds.delete(videoId);
            return newIds;
        });
    };

    const handlePinVideo = (videoId: string) => {
        setVideos(prevVideos => {
            const videoIndex = prevVideos.findIndex(video => video.id === videoId);
            if (videoIndex === -1) return prevVideos;

            const updatedVideos = [...prevVideos];
            const video = { ...updatedVideos[videoIndex] };

            // Toggle the isPinned status
            video.isPinned = !video.isPinned;

            // Remove the video from its current position
            updatedVideos.splice(videoIndex, 1);

            if (video.isPinned) {
                // If pinned, add to the top
                updatedVideos.unshift(video);
            } else {
                // If unpinned, add to the bottom
                updatedVideos.push(video);
            }

            return updatedVideos;
        });
    };

    const handleSave = async () => {
        const playlistData = {
            created_by: userName,
            playlist_name: playlistName,
            description: playlistDescription,
            custom_tags: tags.map(tag => tag.label),
            physical: userType === 'p',
            mental: userType === 'm',
            available_user_ids: viewPermission.map(user => user.id),
            is_pin: videos.filter(v => v.isPinned).map(v => parseInt(v.id)),
            match_order: videos.map(v => parseInt(v.id)),
        };

        try {
            if (mode === 'create') {
                const response = await createPlaylistAPI(playlistData, token as string);
                console.log('Playlist created:', response);
                setNotificationMessage('播放清單已建立成功');
                setNotificationType('success');
            } else if (mode === 'edit' && playlistId) {
                const editData = {
                    ...playlistData,
                    playlist_id: parseInt(playlistId, 10),
                };
                const response = await editPlaylistAPI(parseInt(playlistId, 10), editData, token as string);
                console.log('Playlist updated:', response);
                setNotificationMessage('播放清單已更新成功');
                setNotificationType('success');
            }
            fetchPlaylists(videoList);
            setOpen(true);
        } catch (error) {
            console.error('Error saving playlist:', error);
            setNotificationMessage('發生錯誤，請稍後再試');
            setNotificationType('error');
            setOpen(true);
        }
    };

    const handleDragStart = (index: number) => {
        setDraggedItem(index);
    };

    const handleDragEnter = (index: number) => {
        setDraggedOverItem(index);
    };

    const handleDragEnd = () => {
        if (draggedItem !== null && draggedOverItem !== null) {
            const newVideos = [...videos];
            const [reorderedItem] = newVideos.splice(draggedItem, 1);
            newVideos.splice(draggedOverItem, 0, reorderedItem);
            setVideos(newVideos);
        }
        setDraggedItem(null);
        setDraggedOverItem(null);
    };



    return (
        <div className="playlist-management-container">
            <div className="nav-container">
                <nav className={`playlist-navbar ${isExpanded ? 'expanded' : ''}`}>
                    <div className="main-nav">
                        <div className="nav-section">
                            <div className="view-toggle-group">
                                {(['details', 'selection', 'playlist'] as const).map((viewType) => (
                                    <button
                                        key={viewType}
                                        className={`toggle-button ${view === viewType ? 'active' : ''}`}
                                        onClick={() => handleViewChange(null, viewType)}
                                    >
                                        {viewType === 'details' ? '視頻列表面板' :
                                            viewType === 'selection' ? '影片選擇面板' : '播放清單視頻'}
                                    </button>
                                ))}
                            </div>
                        </div>

                        <button
                            className="expand-button"
                            onClick={() => setIsExpanded(!isExpanded)}
                        >
                            {isExpanded ? '▲' : '▼'}
                        </button>
                    </div>

                    <div className={`expanded-content ${isExpanded ? 'show' : ''}`}>
                        <div className="nav-section">
                            <div className="search-container">
                                <input
                                    type="text"
                                    className="search-input"
                                    placeholder="搜索視頻..."
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}
                                />
                            </div>

                            <div className="view-controls">
                                <button
                                    className={`view-button ${viewMode === 'grid' ? 'active' : ''}`}
                                    onClick={() => setViewMode('grid')}
                                >
                                    <ViewCarouselOutlinedIcon />
                                </button>
                                <button
                                    className={`view-button ${viewMode === 'list' ? 'active' : ''}`}
                                    onClick={() => setViewMode('list')}
                                >
                                    <ListOutlinedIcon />
                                </button>
                            </div>
                        </div>

                        <div className="grouping-nav">
                            <div className="grouping-buttons">
                                <div
                                    className="indicator"
                                    style={{
                                        left: groupingMethod === 'events' ? '0' :
                                            groupingMethod === 'countries' ? '33.33%' : '66.66%'
                                    }}
                                />
                                {(['events', 'countries', 'players'] as const).map((method) => (
                                    <button
                                        key={method}
                                        className={`group-button ${groupingMethod === method ? 'active' : ''}`}
                                        onClick={() => setGroupingMethod(method)}
                                    >
                                        {method === 'events' ? '賽事' :
                                            method === 'countries' ? '國家/地區' :
                                                method === 'players' ? '選手' :
                                                    method}
                                    </button>
                                ))}
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
            {view === 'details' ? (
                <div className="form-group">
                    <label htmlFor="playlistName">播放清單名稱：</label>
                    <input
                        id="playlistName"
                        type="text"
                        value={playlistName}
                        onChange={(e) => setPlaylistName(e.target.value)}
                    />
                    <label htmlFor="playlistDescription">播放清單評論：</label>
                    <input
                        id="playlistDescription"
                        type="text"
                        value={playlistDescription}
                        onChange={(e) => setPlaylistDescription(e.target.value)}
                    />
                    <label htmlFor="viewPermission">播放清單權限：</label>
                    <Autocomplete
                        multiple
                        id="viewPermission"
                        options={userOptions}
                        value={viewPermission}
                        onChange={handleViewPermissionChange}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        getOptionLabel={(option) => option.label}

                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                placeholder="選擇用戶"
                                value={viewPermission}
                            />
                        )}
                        renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => (
                                <Chip
                                    label={option.label}
                                    {...getTagProps({ index })}
                                />
                            ))
                        }
                    />
                    <label htmlFor="playlistTags">播放清單標籤：</label>
                    <Autocomplete
                        multiple
                        id="playlistTags"
                        options={allTagOptions}
                        value={tags}
                        onChange={handleTagChange}
                        isOptionEqualToValue={(option, value) => option.id === value.id}
                        getOptionLabel={(option) => typeof option === 'string' ? option : option.label}
                        filterOptions={(options, params) => {
                            const filtered = options.filter(option =>
                                option.label.toLowerCase().includes(params.inputValue.toLowerCase())
                            );

                            if (params.inputValue !== '' && !filtered.some(option => option.label.toLowerCase() === params.inputValue.toLowerCase())) {
                                filtered.push({
                                    id: params.inputValue,
                                    label: params.inputValue,
                                });
                            }

                            return filtered;
                        }}
                        freeSolo
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                variant="standard"
                                placeholder="添加標籤"
                            />
                        )}
                        renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => (
                                <Chip
                                    label={option.label}
                                    {...getTagProps({ index })}
                                />
                            ))
                        }
                    />
                </div>
            ) : (
                <div className='playlist-video-container'>
                    {view === 'selection' ? (
                        <PlaylistVideoPanel
                            videoList={videoList}
                            addedVideoIds={addedVideoIds}
                            onAddVideo={handleAddVideo}
                            playerList={playerList}
                            searchTerm={searchTerm}
                            viewMode={viewMode}
                            groupingMethod={groupingMethod}
                        />
                    ) : (
                        <div className="playlist-videos">
                            {videos.map((video, index) => (
                                <DraggableVideoItem
                                    key={video.id}
                                    video={video}
                                    index={index}
                                    isDragging={draggedItem === index}
                                    isDraggedOver={draggedOverItem === index}
                                    onDragStart={handleDragStart}
                                    onDragEnter={handleDragEnter}
                                    onDragEnd={handleDragEnd}
                                    onPin={handlePinVideo}
                                    onRemove={handleRemoveVideo}
                                />
                            ))}
                        </div>
                    )}
                </div>
            )}

            <div className="dialog-actions">
                <StyledButton onClick={handleSave}>
                    儲存
                </StyledButton>
            </div>
            <StyledSnackbar
                open={open}
                autoHideDuration={300000}
                onClose={handleClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert
                    onClose={handleAlertClose}
                    severity={notificationType}
                    variant="filled"
                >
                    {notificationMessage}
                </Alert>
            </StyledSnackbar>
        </div>
    );
};

export default PlaylistManagement;