import { useState, useEffect } from 'react';

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import MatchScoreBoard from 'src/components/MatchScoreBoard/MatchScoreBoard';
import FlagVideo from 'src/components/VideoControlPanel/VideoFlag';
import RoundScorePanel from './RoundScorePanel';
import ScoreChart from './ScoreChart';

import './ScorePanel.scss';

import { ballDataIndex } from 'src/constants';

const ScorePanel = ({
  roundResults,
  matchData,
  videoRef,
  score1,
  score2,
  xAxis,
  yAxis,
  chartHeight,
  chartWidth,
  videoData,
  setVideoData,
}) => {
  const [roundData, setRoundData] = useState([]);

  useEffect(() => {
    if (roundResults && roundResults[videoData.currentRound]) {
      setRoundData(roundResults[videoData.currentRound][ballDataIndex]);
    }
  }, [roundResults, videoData.currentRound]);

  const sections = [
    {
      id: 'match-score',
      title: '比賽得分',
      defaultExpanded: true,
      component: (
        <MatchScoreBoard
          score1={score1}
          score2={score2}
          HomePlayer={matchData.homePlayer}
          AwayPlayer={matchData.awayPlayer}
          videoData={videoData}
          videoRef={videoRef}
          roundResults={roundResults}
          setVideoData={setVideoData}
        />
      ),
    },
    {
      id: 'round-score',
      title: '回合得分',
      component: (
        <RoundScorePanel
          matchData={matchData}
          roundData={roundData}
          videoData={videoData}
          setVideoData={setVideoData}
          videoRef={videoRef}
          roundResults={roundResults}
        />
      ),
    },
    {
      id: 'score-chart',
      title: '回合得分圖表',
      component: (
        <ScoreChart
          score1={score1}
          score2={score2}
          matchData={matchData}
          currentRound={videoData.currentRound}
          xAxis={xAxis}
          yAxis={yAxis}
          chartHeight={chartHeight}
          chartWidth={chartWidth}
          videoRef={videoRef}
          roundResults={roundResults}
          setVideoData={setVideoData}
        />
      ),
    },
  ];

  return (
    <div className="score-panel-container">
      <FlagVideo
        matchId={matchData.matchId}
        defaultOpen={false}
        ocr={true}
        dp={false}
        setVideoData={setVideoData}
      />

      {score1 !== null && score2 !== null && (
        <div className="accordion-container">
          {sections.map(({ id, title, component, defaultExpanded }) => (
            <Accordion
              key={id}
              defaultExpanded={defaultExpanded}
              className="score-accordion"
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`${id}-content`}
                id={`${id}-header`}
              >
                <Typography>{title}</Typography>
              </AccordionSummary>
              <div className="accordion-content">{component}</div>
            </Accordion>
          ))}
        </div>
      )}
      <div className="panel-padding" />
    </div>
  );
};

export default ScorePanel;
