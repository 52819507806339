import { useState } from 'react';
import axios from 'axios';
import Cookies from 'js-cookie';
import './UnverifiedUsers.scss';
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
} from '@mui/material';

const UnverifiedUsers = ({ users, setRefreshData }) => {
  const token = Cookies.get('token');
  const [verifyingUser, setVerifyingUser] = useState(null);
  const [accountType, setAccountType] = useState('a');

  const handleVerify = async (userId) => {
    setVerifyingUser(userId);
    try {
      await axios.post(
        '/api/verifyUser',
        {
          user_id: userId,
          accountType: accountType,
        },
        { headers: { Authorization: token } },
      );
      setRefreshData(true);
    } catch (error) {
      console.error('Error verifying user:', error);
    }
    setVerifyingUser(null);
  };

  const handleAccountTypeChange = (event) => {
    setAccountType(event.target.value);
  };

  return (
    <div className="unverified-users">
      <div className="cards-grid">
        {users.map((user) => (
          <div key={user.user_id} className="user-card">
            <div className="card-content">
              <div className="user-header">
                <h3>{user.username}</h3>
              </div>
              <div className="user-details">
                <div className="info-row">
                  <span className="label">姓名：</span>
                  <span className="value">{user.actual_name || '未提供'}</span>
                </div>
                <div className="info-row">
                  <span className="label">電子郵件：</span>
                  <span className="value">{user.user_email}</span>
                </div>
                <div className="info-row">
                  <span className="label">最後登入：</span>
                  <span className="value">{user.last_log_in}</span>
                </div>
                <FormControl component="fieldset">
                  <RadioGroup
                    row
                    name="account-type"
                    value={accountType}
                    onChange={handleAccountTypeChange}
                  >
                    <FormControlLabel
                      value="a"
                      control={<Radio />}
                      label="一般運動員"
                    />
                    <FormControlLabel
                      value="m"
                      control={<Radio />}
                      label="智障運動員"
                    />
                    <FormControlLabel
                      value="p"
                      control={<Radio />}
                      label="傷殘運動員"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <div className="card-actions">
              <button
                className={`verify-button ${verifyingUser === user.user_id ? 'verifying' : ''}`}
                onClick={() => handleVerify(user.user_id)}
                disabled={verifyingUser === user.user_id}
              >
                {verifyingUser === user.user_id ? '驗證中...' : '驗證用戶'}
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default UnverifiedUsers;
