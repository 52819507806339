import React from 'react';
import SearchBar from 'src/components/SearchBar/SearchBar';
import HomeFilterOption from 'src/components/FilterPanel/HomeFilterOption';
import ActiveFiltersNew from './HomeFilterPanelNew';

import { Box, Stack } from '@mui/material';

interface FilterCriteria {
    matchName: string;
    matchDate: string;
    stages: string[];
    location: string;
    events: string[];
    categories: string[];
    startDate: string;
    endDate: string;
    customTags: string[];
}

interface FilterStats {
    stageCounts: Record<string, number>;
    subEventCounts: Record<string, number>;
    customTagCounts: Record<string, number>;
    categoryCounts: Record<string, number>;
}

interface UserInfo {
    accountType: string;
    admin: boolean;
    subadmin: boolean;
}

interface HomeOptionsBarProps {
    filterCriteria: FilterCriteria;
    setFilterCriteria: React.Dispatch<React.SetStateAction<FilterCriteria>>;
    sortCriteria: number;
    setSortCriteria: React.Dispatch<React.SetStateAction<number>>;
    filterStats: FilterStats;
    CustomTagList: string[];
    userInfo: UserInfo;
}

const HomeOptionsBarNew: React.FC<HomeOptionsBarProps> = ({
    filterCriteria,
    setFilterCriteria,
    sortCriteria,
    setSortCriteria,
    filterStats,
    CustomTagList,
    userInfo,
}) => {
    return (
        <Box sx={{ width: '100%' }}>
            <Stack spacing={2}>
                <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="space-between"
                    sx={{ width: '100%' }}
                >
                    <Box sx={{ flexGrow: 1, maxWidth: '85%' }}>
                        <SearchBar
                            player={false}
                            setFilterCriteria={setFilterCriteria}
                            searchName={filterCriteria.matchName}
                        />
                    </Box>
                    <Box sx={{ minWidth: 'fit-content' }}>
                        <HomeFilterOption
                            setFilterCriteria={setFilterCriteria}
                            appliedFilterCriteria={filterCriteria}
                            CustomTagList={CustomTagList}
                            userInfo={userInfo}
                            filterStats={filterStats}
                            setSortCriteria={setSortCriteria}
                            sortCriteria={sortCriteria}
                        />
                    </Box>
                </Stack>

                <Box sx={{ width: '100%' }}>
                    <ActiveFiltersNew
                        filterCriteria={filterCriteria}
                        setFilterCriteria={setFilterCriteria}
                    />
                </Box>
            </Stack>
        </Box>
    );
};

export default HomeOptionsBarNew;