import axios from "axios";
import { ConnectionsData, PlayerData } from "../types/graph.types";
// import { DEBUGPLAYERSDATA } from "./DEBUGPLAYERSDATA";

export const selectJsonGameModeName = (
  gameMode: keyof Omit<VideoGraphDataDummyType, "name_lists">,
  APIDataDemo: VideoGraphDataDummyType
): ConnectionsData => {
  let res = {} as ConnectionsData;

  if (gameMode in APIDataDemo) {
    res = APIDataDemo[gameMode];
  }

  return res;
};

export type VideoGraphDataDummyType = {
  name_lists: PlayerData;
  COM_multi_player: ConnectionsData;
  COM_single_player: ConnectionsData;
  COM_FSingle: ConnectionsData;
  COM_FDouble: ConnectionsData;
  COM_MSingle: ConnectionsData;
  COM_MDouble: ConnectionsData;
  COM_MixedDouble: ConnectionsData;
};

export interface DataState {
  data: VideoGraphDataDummyType | null;
  isLiveData: boolean;
}

// Type Definitions
interface PlayerInfo {
  gender: string;
  isDouble: boolean | null;
  player_id: number;
  player_name: string;
  player_family_name: string;
  player_given_name: string;
  country: string;
  country_code: string;
  head_shot?: string;
  player2_head_shot?: string;
}

// Main data fetching functions
export const fetchPlayersData = async (
  token?: string
): Promise<PlayerInfo[]> => {
  try {
    const response = await axios.get("/api/playersData", {
      headers: {
        Authorization: `${token}`,
      },
      params: {
        player_name: "",
        categories: "",
        countries:
          "No Data,Algeria,Angola,Argentina,Australia,Austria,Belgium,Botswana,Brazil,Cameroon,Canada,Chile,China,Chinese Taipei,Colombia,Congo Brazzaville,Congo Democratic,Croatia,Cuba,Czechia,Denmark,Dominican Republic,Ecuador,Egypt,England,Ethiopia,Fiji,Finland,France,Germany,Greece,Guatemala,Hong Kong, China,Hungary,India,Iran,Italy,Japan,Kazakhstan,Korea DPR,Korea Republic,Lebanon,Luxembourg,Madagascar,Malaysia,Malta,Mauritius,Mexico,Moldova,Monaco,Morocco,Netherlands,New Caledonia,New Zealand,Nigeria,Norway,Papua New Guinea,Paraguay,Peru,Philippines,Poland,Portugal,Puerto Rico,Romania,Saudi Arabia,Senegal,Serbia,Singapore,Slovak Republic,Slovenia,South Africa,Spain,Sweden,Switzerland,Tahiti,Thailand,Togo,Tunisia,Türkiye,Ukraine,USA,Uzbekistan,Venezuela,Wales",
        existingMatch: false,
        sortCriteria: 4,
        accountType: "a",
      },
    });
    return response.data.data;
  } catch (error) {
    console.error("Error fetching players data:", error);
    return [];
  }
};

export function normalizePlayerName(name: string): string {
  // Remove excess whitespace and convert to uppercase
  return name.replace(/\s+/g, " ").trim().toUpperCase();
}

function sortPlayersByCategory(
  videoData: VideoGraphDataDummyType,
  playersData: PlayerInfo[]
) {
  const result = {
    COM_FSingle: {} as ConnectionsData,
    COM_MSingle: {} as ConnectionsData,
    COM_FDouble: {} as ConnectionsData,
    COM_MDouble: {} as ConnectionsData,
    COM_MixedDouble: {} as ConnectionsData,
  };

  // Create map of known players
  const playerInfoMap = new Map<string, PlayerInfo>();
  playersData.forEach((player) => {
    playerInfoMap.set(player.player_id.toString(), player);
  });
  const combineMatches = (
    existingMatches: number[] = [],
    newMatches: number[]
  ) => {
    return Array.from(new Set([...existingMatches, ...newMatches])).sort(
      (a, b) => a - b
    );
  };

  const playerPairsAreEqual = (pair1: string[], pair2: string[]): boolean => {
    const [a1, b1] = pair1.map(normalizePlayerName);
    const [a2, b2] = pair2.map(normalizePlayerName);
    return (a1 === a2 && b1 === b2) || (a1 === b2 && b1 === a2);
  };

  const findExistingPairId = (
    players: string[],
    category: ConnectionsData
  ): string | null => {
    return (
      Object.keys(category).find((existingPairId) => {
        const existingPlayers =
          videoData.name_lists[existingPairId]?.split(" | ");
        return existingPlayers && playerPairsAreEqual(players, existingPlayers);
      }) || null
    );
  };
  // console.log({ playerInfoMap });
  const playerInfoMapByNames = new Map<string, PlayerInfo>();
  playersData.forEach((player) => {
    playerInfoMap.set(player.player_id.toString(), player);
    // Store both original and normalized name versions
    playerInfoMapByNames.set(normalizePlayerName(player.player_name), player);
    if (player.player_family_name && player.player_given_name) {
      // Also store the combined name format
      const combinedName = `${player.player_family_name} ${player.player_given_name}`;
      playerInfoMapByNames.set(normalizePlayerName(combinedName), player);
    }
  });

  // console.log({ playerInfoMapByNames });

  // Process single players, filtering out "_" names
  for (const [playerId, matches] of Object.entries(
    videoData.COM_single_player
  )) {
    // Skip if player name is "_"
    if (videoData.name_lists[playerId] === "_") continue;

    const playerInfo = playerInfoMap.get(playerId);
    const gender =
      playerInfo?.gender ||
      inferPlayerGender(playerId, videoData, playerInfoMap);

    if (gender === "F") {
      result.COM_FSingle[playerId] = matches;
    } else {
      // Default to male singles if gender is unknown (since we're in men's view)
      result.COM_MSingle[playerId] = matches;
    }
  }

  // Process multi players (doubles)
  for (const [pairId, matches] of Object.entries(videoData.COM_multi_player)) {
    // Skip if pair name contains "_"
    if (videoData.name_lists[pairId]?.includes("_")) continue;

    const players = videoData.name_lists[pairId]?.split(" | ");

    if (players && players.length === 2) {
      // Find player IDs for the pair
      const player1Id = Object.entries(videoData.name_lists).find(
        ([id, name]) => name === players[0]
      )?.[0];
      const player2Id = Object.entries(videoData.name_lists).find(
        ([id, name]) => name === players[1]
      )?.[0];


      // Debug log for the specific pair
      const player1Info = player1Id
        ? playerInfoMap.get(player1Id)
        : playerInfoMapByNames.get(normalizePlayerName(players[0]));

      const player2Info = player2Id
        ? playerInfoMap.get(player2Id)
        : playerInfoMapByNames.get(normalizePlayerName(players[1]));

      if (player1Info && player2Info) {
        const pairType = getDoublePairType(
          player1Info.gender,
          player2Info.gender
        );
        // if (pairId === "207158") {
        //   console.log({ pairType });
        // }
        switch (pairType) {
          case "FF": {
            const existingId = findExistingPairId(players, result.COM_FDouble);
            const targetId = existingId || pairId;
            result.COM_FDouble[targetId] = combineMatches(
              result.COM_FDouble[targetId],
              matches
            );
            break;
          }
          case "MM": {
            const existingId = findExistingPairId(players, result.COM_MDouble);
            const targetId = existingId || pairId;
            result.COM_MDouble[targetId] = combineMatches(
              result.COM_MDouble[targetId],
              matches
            );
            break;
          }
          case "MF":
          case "FM": {
            const existingId = findExistingPairId(
              players,
              result.COM_MixedDouble
            );
            const targetId = existingId || pairId;
            result.COM_MixedDouble[targetId] = combineMatches(
              result.COM_MixedDouble[targetId],
              matches
            );
            break;
          }
        }
      }
    }
  }

  return result;
}

export function getDoublePairType(
  player1Gender: string,
  player2Gender: string
): "FF" | "MM" | "MF" | "FM" {
  if (player1Gender === "F" && player2Gender === "F") return "FF";
  if (player1Gender === "M" && player2Gender === "M") return "MM";
  if (player1Gender === "M" && player2Gender === "F") return "MF";
  return "FM"; // Must be F/M if it reaches here
}

export const fetchRawVideoGraphData = async (
  token?: string
): Promise<DataState> => {
  try {
    const response = await axios.get("/api/videoGraph", {
      headers: {
        Authorization: `${token}`,
      },
    });

    return {
      data: response.data,
      isLiveData: true,
    };
  } catch (error) {
    console.error("Error fetching fetchVideoGraphData: ", error);
    console.log("We will use the dummy data instead");
    return {
      data: VideoGraphDataDummy,
      isLiveData: false,
    };
  }
};

// And update processAllTableTennisData to use fetchRawVideoGraphData
export async function processAllTableTennisData(
  token?: string
): Promise<VideoGraphDataDummyType> {
  try {
    const [videoGraphResult, playersData] = await Promise.all([
      fetchRawVideoGraphData(token), // Use the raw data fetching function
      fetchPlayersData(token),
    ]);

    if (!videoGraphResult.data) {
      throw new Error("No video graph data available");
    }

    const sortedMatches = sortPlayersByCategory(
      videoGraphResult.data, //TODO: Uncomment this line
      // VideoGraphDataDummy, // Use dummy data for now
      playersData
      // DEBUGPLAYERSDATA
    );

    return {
      name_lists: videoGraphResult.data.name_lists,
      COM_multi_player: videoGraphResult.data.COM_multi_player,
      COM_single_player: videoGraphResult.data.COM_single_player,
      COM_FSingle: sortedMatches.COM_FSingle,
      COM_FDouble: sortedMatches.COM_FDouble,
      COM_MSingle: sortedMatches.COM_MSingle,
      COM_MDouble: sortedMatches.COM_MDouble,
      COM_MixedDouble: sortedMatches.COM_MixedDouble,
    };
  } catch (error) {
    console.error("Error processing table tennis data:", error);
    throw error;
  }
}

// Utility function to get player details by ID
export function getPlayerById(
  playersData: PlayerInfo[],
  playerId: string
): PlayerInfo | null {
  return playersData.find((p) => p.player_id.toString() === playerId) || null;
}

// Utility function to get doubles pair details
export function getDoublesPairDetails(
  pairId: string,
  videoData: VideoGraphDataDummyType,
  playersData: PlayerInfo[]
): { player1: PlayerInfo | null; player2: PlayerInfo | null } {
  const names = videoData.name_lists[pairId]?.split(" | ");
  if (!names || names.length !== 2) {
    return { player1: null, player2: null };
  }

  const player1Id = Object.entries(videoData.name_lists).find(
    ([id, name]) => name === names[0]
  )?.[0];
  const player2Id = Object.entries(videoData.name_lists).find(
    ([id, name]) => name === names[1]
  )?.[0];

  return {
    player1: player1Id ? getPlayerById(playersData, player1Id) : null,
    player2: player2Id ? getPlayerById(playersData, player2Id) : null,
  };
}

function inferPlayerGender(
  playerId: string,
  videoData: VideoGraphDataDummyType,
  knownPlayers: Map<string, PlayerInfo>
): string | null {
  const clues: string[] = [];

  function normalizePlayerName(name: string): string {
    return name.toUpperCase().trim();
  }

  // 1. Check existing categorized matches
  if (videoData.COM_FSingle[playerId]) clues.push("F");
  if (videoData.COM_MSingle[playerId]) clues.push("M");

  // 2. Check doubles matches
  for (const [pairId, _] of Object.entries(videoData.COM_multi_player)) {
    const players = videoData.name_lists[pairId]?.split(" | ");
    const playerName = videoData.name_lists[playerId];

    // Normalize names before comparison
    if (
      players?.some(
        (p) => normalizePlayerName(p) === normalizePlayerName(playerName)
      )
    ) {
      if (pairId in videoData.COM_FDouble) clues.push("F");
      if (pairId in videoData.COM_MDouble) clues.push("M");

      // For mixed doubles, try to infer from partner
      if (pairId in videoData.COM_MixedDouble) {
        const partnerName = players.find(
          (p) => normalizePlayerName(p) !== normalizePlayerName(playerName)
        );

        if (partnerName) {
          const partnerId = Object.entries(videoData.name_lists).find(
            ([id, name]) =>
              normalizePlayerName(name) === normalizePlayerName(partnerName) &&
              id !== playerId
          )?.[0];

          if (partnerId) {
            const partnerInfo = knownPlayers.get(partnerId);
            if (partnerInfo?.gender) {
              clues.push(partnerInfo.gender === "F" ? "M" : "F");
            }
          }
        }
      }
    }
  }

  // 3. If no clues found but has matches in COM_single_player,
  // default to male since it's men's singles
  if (clues.length === 0 && videoData.COM_single_player[playerId]) {
    return "M";
  }

  // Return most common gender inference
  const mCount = clues.filter((g) => g === "M").length;
  const fCount = clues.filter((g) => g === "F").length;

  if (mCount > fCount) return "M";
  if (fCount > mCount) return "F";
  if (mCount === fCount && mCount > 0) return clues[0];

  // Default to male if no other clues
  return "M";
}

// fetch data from the API
export const fetchVideoGraphData = async (
  token?: string
): Promise<DataState> => {
  try {
    const response = await processAllTableTennisData(token);
    console.log(response);
    return {
      data: response,
      isLiveData: true,
    };
  } catch (error) {
    console.error("Error fetching fetchVideoGraphData: ", error);
    console.log("We will use the dummy data instead");
    return {
      data: VideoGraphDataDummy,
      isLiveData: false,
    };
  } finally {
    console.log("fetchVideoGraphData executed");
  }
};

export const VideoGraphDataDummy: VideoGraphDataDummyType = {
  name_lists: {
    "100439": "AHMED SALEH",
    "101000": "BROMLEY MICHELLE",
    "101820": "CHUANG CHIH YUAN",
    "102380": "DRINKHALL PAUL",
    "102832": "PATRICK FRANZISKA",
    "102863": "YU FU",
    "102968": "GARDOS ROBERT",
    "103752": "HUANG YI HUA",
    "103903": "IONESCU OVIDIU",
    "104314": "ACHANTA SHARATH KAMAL",
    "104379": "KARLSSON KRISTIAN",
    "105159": "LEE HO CHING",
    "107321": "PESOTSKA MARGARYTA",
    "107477": "POLCANOVA SOFIA",
    "108226": "ELIZABETA SAMARA",
    "108470": "SUH HYO WON",
    "109272": "TAPPER MELISSA",
    "110673": "ALAMIYAN NOSHAD",
    "110797": "CHENG I CHING",
    "110995": "DEBORA VIVARELLI",
    "111012": "BERNADETTE SZOCS",
    "111066": "SABINE WINTER",
    "111849": "XIAONA SHAN",
    "112019": "CHEN MENG",
    "112035": "NIMA ALAMIAN",
    "112074": "FALCK MATTIAS",
    "112092": "QUADRI ARUNA",
    "112221": "LILY ZHANG",
    "112280": "DE NUTTE SARAH",
    "112463": "MELANIE DIAZ",
    "112620": "WONG CHUN TING",
    "112735": "WANG YANG",
    "112868": "YANG XIAOXIN",
    "113681": "IBRAHIMA DIAW",
    "114105": "CHEN SZU YU",
    "114706": "CHENG HSIEN TZU",
    "114726": "NINA MITTELHAM",
    "114848": "ALLEGRO MARTIN",
    "114897": "BERNADETT BALINT",
    "114936": "JANG WOOJIN",
    "115009": "ADRIANA DIAZ",
    "115396": "JOAO GERALDO",
    "115454": "BAJOR NATALIA",
    "115543": "DOO HOI KEM",
    "115545": "KWAN MAN HO",
    "115546": "LAM SIU HANG",
    "115548": "LI HON MING",
    "115641": "HUGO CALDERANO",
    "115910": "LIN GAOYUAN",
    "116152": "LAM YEE LOK",
    "116532": "PUCAR TOMISLAV",
    "116874": "ZARIF AUDREY",
    "116921": "CHASSELIN PAULINE",
    "117003": "CASSIN ALEXANDRE",
    "117113": "YOSHIMURA MAHARU",
    "117345": "ZHOU KAI",
    "117357": "LIM JONGHOON",
    "117463": "ADINA DIACONU",
    "117820": "MIU HIRANO",
    "117821": "MIMA ITO",
    "118762": "YING HAN",
    "118777": "YUAN WAN",
    "118851": "KOZUL DENI",
    "118893": "MISCHEK KAROLINE",
    "118994": "JEON JIHEE",
    "119089": "YOON HYOBIN",
    "119529": "FAN SIQI",
    "119565": "LIAO CHENG TING",
    "119588": "LIANG JINGKUN",
    "119730": "HE ZHUOJIA",
    "119797": "QIAN TIANYI",
    "120125": "BERGAND FILIPPA",
    "120850": "OIKAWA MIZUKI",
    "121041": "WEGRZYN KATARZYNA",
    "121074": "SURJAN SABINA",
    "121222": "LIANG JISHAN",
    "121404": "FAN ZHENDONG",
    "121558": "WANG CHUQIN",
    "121582": "LIN YUN JU",
    "121690": "PICARD VINCENT",
    "121869": "ZELINKA JAKUB",
    "121940": "NAVID SHAMS",
    "122002": "BARDET LILIAN",
    "122064": "KOLODZIEJCZYK MACIEJ",
    "122428": "ANDREEA DRAGOMAN",
    "123113": "KIM HAYEONG",
    "123505": "ZHU CHENGZHU",
    "123575": "WONG XIN RU",
    "123672": "HINA HAYATA",
    "123680": "SURAVAJJULA SNEHIT",
    "123682": "THAKKAR MANAV VIKASH",
    "123690": "HO KWAN KIT",
    "123977": "SHIN YUBIN",
    "123980": "TOMOKAZU HARIMOTO",
    "124110": "WANG YIDI",
    "124162": "LI YU JHUN",
    "124165": "CHIEN TUNG CHUAN",
    "124591": "NAGASAKI MIYU",
    "131086": "FRANZISKA SCHREINER",
    "131100": "PRITHIKA PAVADE",
    "131148": "XU YINGBIN",
    "131163": "SUN YINGSHA",
    "131211": "HAUG BORGAR",
    "131342": "ZENG JIAN",
    "131401": "CHOI HYOJOO",
    "131446": "NARANJO ANGEL",
    "131480": "HURSEY ANNA",
    "131581": "ARLIA NICOLE",
    "131846": "COK ISA",
    "131879": "SHAH MANUSH UTPALBHAI",
    "131912": "PANG YEW EN KOEN",
    "131965": "ZHOU JINGYI",
    "132144": "SER LIN QIAN",
    "132312": "PAU YIK MAN",
    "132318": "NG WING LAM",
    "132320": "LEE HOI MAN KAREN",
    "132325": "YIU KWAN TO",
    "132724": "CHANG LI SIAN ALICE",
    "132756": "ARAPOVIC HANA",
    "132824": "KAO CHENG JUI",
    "132995": "BELIK SIMON",
    "133694": "SHUNSUKE TOGAMI",
    "133713": "QUEK IZAAC",
    "133813": "PARK GYUHYEON",
    "133814": "KIM NAYEONG",
    "133867": "CHAN BALDWIN",
    "133893": "XU HAIDONG",
    "134790": "SASAO ASUKA",
    "134906": "ABBEY WEBB",
    "135023": "IONESCU EDUARD",
    "135039": "WIELGOS ZUZANNA",
    "135050": "CHEN YUANYU",
    "135179": "HARUNA OJIO",
    "135187": "KONG TSZ LAM",
    "135190": "WONG HOI TUNG",
    "135367": "OH JUNSUNG",
    "135373": "KIM SEOYUN",
    "135391": "LEE DAEUN",
    "135745": "MA JINBAO",
    "135922": "YANG YIYUN",
    "136613": "LI YAKE",
    "136711": "HARIMOTO MIWA",
    "136757": "YOSHIYAMA RYOICHI",
    "137237": "LIN SHIDONG",
    "144705": "HUNG JING KAI",
    "144777": "EDWARDS LAILAA",
    "145016": "MARKHABO MAGDIEVA",
    "200413": "JOO CHEONHUI",
    "200907": "ZONG GEMAN",
    "201504": "ADITYA SAREEN",
    "206911": "AFZALKHON MAHMUDOV | UBAYDULLO SULTONOV",
    "206912": "FAN ZHENDONG | WANG CHUQIN",
    "206913": "LAM SIU HANG | NG PAK NAM",
    "206914": "ABDULLAH ABDULHUSSEIN | MOHAMMED ABDULHUSSEIN",
    "206916": "CHEONG CHI HUN | HE CHON FAI",
    "206920": "DOO HOI KEM | ZHU CHENGZHU",
    "206921": "CHA SU YONG | PYON SONG GYONG",
    "206922": "HO KWAN KIT | WONG CHUN TING",
    "206924": "KALLBERG CHRISTINA | MOREGARD TRULS",
    "206925": "FRANZISKA PATRICK | OVTCHAROV DIMITRIJ",
    "206927": "JANG WOOJIN | JEON JIHEE",
    "206928": "CHEN SZU YU | LIN YUN JU",
    "206929": "ASSAR OMAR | MESHREF DINA",
    "206930": "HARIMOTO TOMOKAZU | HAYATA HINA",
    "206931": "MITTELHAM NINA | QIU DANG",
    "206934": "TOGAMI SHUNSUKE | UDA YUKIYA",
    "206935": "CHAN BALDWIN HO WAH | WONG CHUN TING",
    "206936": "LIAO CHENG TING | LIN YUN JU",
    "206937": "WANG CHUQIN | WANG MANYU",
    "206938": "DUDA BENEDIKT | QIU DANG",
    "206939": "KALLBERG ANTON | MOREGARD TRULS",
    "206940": "JANG WOOJIN | LIM JONGHOON",
    "206941": "CHAN BALDWIN | LEE HOI MAN KAREN",
    "206942": "CHAN BALDWIN | YIU KWAN TO",
    "206943": "HARIMOTO TOMOKAZU | SHINOZUKA HIROTO",
    "206944": "CHENG I CHING | LIN YUN JU",
    "206946": "LEBESSON EMMANUEL | LEBRUN FELIX",
    "206947": "LAM SIU HANG | ZHU CHENGZHU",
    "206948": "HARIMOTO MIWA | TOGAMI SHUNSUKE",
    "206949": "CHENG I CHING | LI YU JHUN",
    "206950": "LUTZ CAMILLE | PAVADE PRITHIKA",
    "206952": "ZENG JIAN | ZHOU JINGYI",
    "206953": "SUN YINGSHA | WANG MANYU",
    "206954": "CHEN SZU YU | HUANG YI HUA",
    "206955": "JEON JIHEE | SHIN YUBIN",
    "206957": "KIM HAYEONG | SHIN YUBIN",
    "206959": "LI CHING WAN | ZHU CHENGZHU",
    "206960": "JOO CHEONHUI | LEE ZION",
    "206961": "WONG XIN RU | ZHOU JINGYI",
    "206962": "LI CHING WAN | NG WING LAM",
    "206963": "NG WING LAM | ZHU CHENGZHU",
    "206964": "CHEN SZU YU | LEE HO CHING",
    "206965": "GOI RUI XUAN | WONG XIN RU",
    "206967": "LEE HO CHING | SHAO JIENI",
    "206969": "CHOI HYOJOO | LEE ZION",
    "206970": "MITTELHAM NINA | WINTER SABINE",
    "206971": "DOO HOI KEM | LEE HO CHING",
    "206972": "CHENG HSIEN TZU | LIU HSING YIN",
    "206973": "HAYATA HINA | ITO MIMA",
    "206976": "LAM YEE LOK | LEE HOI MAN KAREN",
    "206978": "CIOBANU IRINA | SAMARA ELIZABETA",
    "206979": "ITO MIMA | MIZUTANI JUN",
    "206982": "LIN GAOYUAN | WANG YIDI",
    "206983": "LEE EUNHYE | YANG HAEUN",
    "206984": "NG WING LAM | WONG CHUN TING",
    "206985": "SUN YINGSHA | WANG CHUQIN",
    "206986": "LEBRUN ALEXIS | LEBRUN FELIX",
    "206989": "HAN YING | MITTELHAM NINA",
    "206991": "JANG WOOJIN | SHIN YUBIN",
    "206992": "HO KWAN KIT | LEE HO CHING",
    "206993": "LEBESSON EMMANUEL | YUAN JIA NAN",
    "206995": "HO KWAN KIT | MITTELHAM NINA",
    "207000": "HAYATA HINA | TOGAMI SHUNSUKE",
    "207002": "GAUZY SIMON | PAVADE PRITHIKA",
    "207004": "NG WING LAM | YIU KWAN TO",
    "207005": "IONESCU OVIDIU | SZOCS BERNADETTE",
    "207006": "LEE HO CHING | SOO WAI YAM MINNIE",
    "207007": "CHEN SZU YU | CHENG HSIEN TZU",
    "207008": "_",
    "207010": "ROBLES ALVARO | XIAO MARIA",
    "207022": "KUAI MAN | LIN SHIDONG",
    "207023": "DOO HOI KEM | WONG CHUN TING",
    "207024": "CHO DAE SEONG | KIM NAYEONG",
    "207026": "LIM JEONG HOON | SHIN YU BIN",
    "207027": "KUKULKOVA TATIANA | WANG YANG",
    "207028": "LIN GAOYUAN | WANG YIDI",
    "207029": "GAUZY SIMON | PAVADE PRITHIKA",
    "207030": "QUEK IZAAC | ZHOU JING YI",
    "207031": "FAN ZHENDONG | WANG MANYU",
    "207032": "HANAGODA | HO KWAN KIT",
    "207036": "JEON JIHEE | YI SANG SOO",
    "207037": "GIORGIA PICCOLIN | STOYANOV",
    "207038": "POLCANOVA SOFIA | SZOCS BERNADETTE",
    "207039": "SABINE | SHAN XIAONA",
    "207040": "WU YANGCHEN | XU HAIDONG",
    "207042": "JIA NAN YUAN | LE BESSON",
    "207043": "ECHEKI | MADARASZ DORA",
    "207044": "HARIMOTO MIWA | TOGAMI SHUNSUKE",
    "207045": "MATSUSHIMA KISORA | MIWA HARIMOTO",
    "207046": "CHITALE | SHAH",
    "207047": "MIXED DOUBLES 1ST ROUND ROBLES ALVARO | XIAO MARIA",
    "207048": "ABDULWAHHAB.M | FARAMARZI.M",
    "207049": "MIXED DOUBLES 1ST ROUND IONESCU OVIDIU | SZOCS BERNADETTE",
    "207050": "FRANZISKA PATRICK | SORYA",
    "207052": "SZU YU | ZHONG JIAN",
    "207053": "KIHARA MIYUU | YUKIYA UDA",
    "207056": "CHU | ZENG JIAN",
    "207057": "LIANG JINGKUN | QIAN TIANYI",
    "207058": "SUN YINGSHA | WANG MANYU",
    "207059": "GARDOS | POLCANOVA SOFIA",
    "207061": "MA LONG | WANG CHUQIN",
    "207062": "LIAO ZHEN JU | LIN YUN JU",
    "207063": "LIN GAOYUAN | WANG MANYU",
    "207064": "FAN ZHENDONG | KUAI MAN",
    "207065": "MA LONG | QIAN TIANYI",
    "207066": "HO KWAN KIT | WONG CHUN TING",
    "207067": "MIXED DOUBLES QUARTERFINALS SUDI | PELGEL",
    "207068": "LIM JEONGHUN | SHIN YUBIN",
    "207069": "BADOWSKI | BAJOR NATALIA",
    "207070": "ITO MIMA | YUKIYA UDA",
    "207071": "HARIMOTO TOMOKAZU | HAYATA HINA",
    "207072": "JEON JIHEE | YANG HAEUN",
    "207073": "HIRANO MIU | ISHIKAWA KASUMI",
    "207074": "HAYATA HINA | KIHARA MIYUU",
    "207075": "HAYATA_HINA | ITO_MIMA",
    "207076": "HAYATA HINA | ITO MIMA",
    "207077": "CHAN YI | KUAI MAN",
    "207078": "ITO MIMA | UDA YUKIYA",
    "207079": "MADARASZ DORA | POTA GEORGINA",
    "207080": "CHEN SZU YU | CHUANG CHIH YUAN",
    "207081": "BAJOR | NATALIA MOLE",
    "207082": "CHEN SZU YU | _FENG YI HSIN",
    "207083": "KIHARA MIYU | NAGASAKI MIYU",
    "207084": "P.SORYA | SHAN XIAONA",
    "207085": "ITO MIMA | JUN MIZUTANI",
    "207086": "CHEN SZU YU | CHENG SZU YU",
    "207087": "CHEN MENG | WANG MANYU",
    "207088": "HO KWAN KIT | LEE HO CHING",
    "207089": "CHO | KIM HAYEONG",
    "207091": "KALLBERG CHRISTINA | KARLSSON KRISTIAN",
    "207092": "NAGASAKI MIYU | TANAKA YUTA",
    "207093": "KIM KUM YONG | RI JONG SIK",
    "207096": "PYON SONGGYONG",
    "207097": "KIM KUM YONG | RI JONG SIK",
    "207100": "BALUSHI NASSER SAIF NAS AL",
    "207101": "CHAN BALDWIN | YIU KWAN TO",
    "207102": "HO KWAN KIT | WONG CHUN TING",
    "207103": "CHAN BALDWIN | ZHU CHENGZHU",
    "207104": "FONG JAY SHERN | HO YING",
    "207105": "SHI XUNYAO | YUAN LICEN",
    "207106": "CHEN YI | XU YI",
    "207107": "NG WING LAM | ZHU CHENGZHU",
    "207108": "CHITALE DIYA PARAG | GHORPADE YASHASWINI",
    "207109": "KONG TSZ LAM | LEE HOI MAN KAREN",
    "207110": "KIM SEOYUN | LEE DAEUN",
    "207111": "GOI RUI XUAN | ZHANG WANLING",
    "207112": "KONG TSZ LAM | WONG HOI TUNG",
    "207113": "HO KWAN KIT | YIU KWAN TO",
    "207114": "CHOY CHUN KIT | KWAN MAN HO",
    "207115": "GHORPADE YASHASWINI | JAIN PAYAS",
    "207116": "DOO HOI KEM | WONG CHUN TING",
    "207117": "HUANG YAN CHENG | KAO CHENG JUI",
    "207118": "LAM YEE LOK | LEE HOI MAN KAREN",
    "207119": "CHEN XINGTONG | QIAN TIANYI",
    "207120": "BAISYA POYMANTEE",
    "207121": "KUAI MAN | LIN SHIDONG",
    "207122": "MUKHERJEE AYHIKA | MUKHERJEE SUTIRTHA",
    "207123": "CHEN SZU YU | LEE HO CHING",
    "207124": "KIM SEOYUN | YOON HYOBIN",
    "207125": "PARANANG ORAWAN | SAWETTABUT SUTHASINI",
    "207126": "CHITALE DIYA PARAG | SHAH MANUSH UTPALBHAI",
    "207127": "XIANG PENG | YUAN LICEN",
    "207128": "NG WING LAM | YIU KWAN TO",
    "207129": "HUANG YOUZHENG | ZONG GEMAN",
    "207130": "CHAN BALDWIN HO WAH",
    "207131": "LAM SIU HANG | MA JINBAO",
    "207132": "CHAN BALDWIN | LAM YEE LOK",
    "207133": "PANG KOEN",
    "207134": "AUEAWIRIYAYOTHIN WANWISA | SAWETTABUT JINNIPA",
    "207135": "AKIMALI BAKDAULET | ZAKHAROV VLADISLAV",
    "207137": "ALLEGRO MARTIN | RASSENFOSSE ADRIEN",
    "207138": "ALMUTAIRI TURKI",
    "207139": "ARAPOVIC HANA | RAKOVAC LEA",
    "207140": "LAM YEE LOK | NG WING LAM",
    "207141": "ASSAR KHALID | HELMY YOUSRA",
    "207142": "AUEAWIRIYAYOTHIN WANWISA | TAYAPITAK WIRAKARN",
    "207143": "LEE HOI MAN KAREN | NG WING LAM",
    "207144": "BAJOR NATALIA | KUKULKOVA TATIANA",
    "207145": "LEE HO CHING | ZHU CHENGZHU",
    "207146": "BAKHYT ANEL",
    "207147": "BALAZOVA BARBORA | MATELOVA HANA",
    "207148": "BARANI ARIANNA | RONCALLO VALENTINA",
    "207149": "HRIBAR PETER | TOKIC SARA",
    "207150": "CHAN BALDWIN | LEE HOI MAN KAREN",
    "207151": "CHEN SZU YU | LIN YUN JU",
    "207152": "ABDULWAHHAB ABDULLAH | ABDULWAHHAB MOHAMMED",
    "207153": "KALLBERG ANTON | KARLSSON KRISTIAN",
    "207154": "IONESCU EDUARD | SAMARA ELIZABETA",
    "207155": "CHANG LI SIAN ALICE | LYNE KAREN",
    "207156": "CHEW ZHE YU CLARENCE | CHUA JOSH SHAO HAN",
    "207157": "HO KWAN KIT | LAM SIU HANG",
    "207158": "CHO DAESEONG | JOO CHEONHUI",
    "207159": "LAM SIU HANG | ZHU CHENGZHU",
    "207160": "CHOONG JAVEN | LYNE KAREN",
    "207161": "DIACONU ADINA | XIAO MARIA",
    "207162": "DOO HOI KEM | LIU YANGZI",
    "207163": "CHEN SZU YU | HUANG YI HUA",
    "207164": "DYJAS JAKUB | NUYTINCK CEDRIC",
    "207165": "ABDEL AZIZ YOUSSEF | EL BEIALI MOHAMED",
    "207166": "FAN ZHENDONG | WANG CHUQIN",
    "207167": "FENG YI HSIN | HUANG YAN CHENG",
    "207168": "FERRER DAKOTA",
    "207169": "GOMBOS LUDOVIC | LEE HOI MAN KAREN",
    "207170": "CASTILLO AROCHA CESAR AUGUSTO | FERRER DAKOTA",
    "207171": "HARAC ECE | YILMAZ OZGE",
    "207172": "ANDO MINAMI | HARIMOTO MIWA",
    "207173": "LAM YEE LOK | LI CHING WAN",
    "207174": "HASHIMOTO HONOKA | SATO HITOMI",
    "207175": "HE ZHUOJIA | LIU WEISHAN",
    "207176": "HO JEONGMUN | YOO SIWOO",
    "207177": "HO KWAN KIT | LAM YEE LOK",
    "207178": "PANG YEW EN KOEN | WONG XIN RU",
    "207179": "KUKULKOVA TATIANA | WANG YANG",
    "207180": "CHO DAESEONG | KIM NAYEONG",
    "207181": "JANG WOOJIN | JEON JIHEE",
    "207182": "AN JAEHYUN | JANG WOOJIN",
    "207183": "HURSEY ANNA | JEE MINHYUNG",
    "207184": "JEON JIHEE | JOO CHEONHUI",
    "207185": "JOO CHEONHUI | LEE ZION",
    "207186": "JOUTI ERIC | NG WING LAM",
    "207187": "GARDOS ROBERT | POLCANOVA SOFIA",
    "207188": "KALAM MUSFIQUH",
    "207189": "KUAI MAN | ZONG GEMAN",
    "207190": "HUNG JING KAI | LI YAN JUN",
    "207191": "CHEW ZHE YU CLARENCE | POH SHAO FENG ETHAN",
    "207192": "LEE SANG SU | LIM JONGHOON",
    "207193": "LAM SIU HANG | LI HON MING",
    "207194": "XUE FEI | ZHOU QIHAO",
    "207195": "XU YINGBIN | YUAN LICEN",
    "207196": "LIM JONGHOON | SHIN YUBIN",
    "207197": "ROBLES ALVARO | XIAO MARIA",
    "207198": "PICCOLIN GIORGIA | STOYANOV NIAGOL",
    "207199": "HARIMOTO MIWA | TOGAMI SHUNSUKE",
    "207200": "KIM NAYEONG | RYU HANNA",
    "207201": "CHO DAESEONG | LEE SANG SU",
    "207202": "LEE SANG SU | OH JUNSUNG",
    "207203": "LIN GAOYUAN | WANG YIDI",
    "207204": "LIU JIA",
    "207205": "LUTZ CAMILLE | PAVADE PRITHIKA",
    "207206": "COK ISA | LY EDWARD",
    "207207": "LYNE KAREN | TEE AI XIN",
    "207208": "MA JINBAO | ZHANG LILY",
    "207209": "LIN GAOYUAN | MA LONG",
    "207210": "JAZBEC IDA | MALOBABIC IVANA",
    "207211": "HARIMOTO MIWA | MATSUSHIMA SORA",
    "207212": "KE TIFFANY | NARESH SID",
    "207213": "LYNE KAREN",
    "207214": "OH JUNSUNG | PARK GYUHYEON",
    "207215": "CHAN BALDWIN | WONG CHUN TING",
    "207216": "OPEKA LARA",
    "207217": "PARK GYUHYEON | YOON HYOBIN",
    "207218": "GOMBOS LUDOVIC",
    "207219": "RIYAMI AHMED AL",
    "207220": "PAVLOVIC ANDREA | PETEK BORNA",
    "207221": "PETO ZSOLT",
    "207222": "ARLIA NICOLE | PICCOLIN JORDY",
    "207223": "PLETEA CRISTIAN",
    "207224": "LAVROVA ANASTASSIYA | PUTUNTICA ANDREI",
    "207225": "QUEK IZAAC | ZHOU JINGYI",
    "207226": "KUKULKOVA TATIANA | LAM SIU HANG",
    "207227": "ROMANOVSKAYA ANGELINA",
    "207228": "SHAH MANUSH UTPALBHAI | SURAVAJJULA SNEHIT",
    "207229": "SAWETTABUT SUTHASINI | TANVIRIYAVECHAKUL PADASAK",
    "207230": "SUN YINGSHA | WANG YIDI",
    "207231": "PANG KOEN | QUEK IZAAC",
    "207232": "GODA HANA | HO KWAN KIT",
    "207233": "PARANANG ORAWAN | SANGUANSIN PHAKPOOM",
    "207234": "LIU WEISHAN | XIANG PENG",
    "207235": "CHUANG CHIH YUAN | KAO CHENG JUI",
    "207236": "FRANZISKA PATRICK | OVTCHAROV DIMITRIJ",
    "207237": "LIN GAOYUAN | LIN SHIDONG",
    "207238": "NG WING LAM | WONG CHUN TING",
    "207239": "DESCHAMPS HUGO | LUTZ CHARLOTTE",
    "207240": "HO TIN TIN | JARVIS TOM",
    "207241": "UDA YUKIYA | YOSHIMURA MAHARU",
    "207242": "ZENG JIAN | ZHOU JINGYI",
    "207243": "WONG XIN RU | ZHOU JINGYI",
    "207244": "ARAPOVIC HANA | MALOBABIC IVANA",
    "207245": "SHAN XIAONA | WINTER SABINE",
  },
  COM_multi_player: {
    "206911": [70],
    "206912": [73, 578, 131],
    "206913": [74, 212],
    "206914": [74],
    "206916": [75],
    "206920": [
      788, 797, 937, 172, 173, 174, 175, 176, 177, 178, 179, 181, 566, 182, 835,
      582, 456, 79, 81, 978, 979,
    ],
    "206921": [79],
    "206922": [160, 162, 131, 70, 167, 168, 73, 139, 140, 147, 150, 153, 157],
    "206924": [134],
    "206925": [168, 140],
    "206927": [170, 141, 389],
    "206928": [
      547, 548, 520, 553, 555, 556, 525, 622, 559, 494, 142, 237, 531, 623, 239,
      411, 540,
    ],
    "206929": [143],
    "206930": [
      439, 68, 455, 488, 524, 621, 77, 623, 430, 628, 630, 567, 440, 541, 446,
      159,
    ],
    "206931": [644, 645, 455],
    "206934": [139, 151],
    "206935": [151],
    "206936": [153],
    "206937": [154, 451],
    "206938": [169, 157, 150],
    "206939": [160, 147],
    "206940": [162],
    "206941": [239],
    "206942": [164],
    "206943": [164],
    "206944": [200, 580, 165, 551],
    "206946": [167],
    "206947": [224, 545, 171, 225],
    "206948": [171],
    "206949": [448, 172, 179, 788],
    "206950": [201, 173],
    "206952": [175],
    "206953": [193, 484, 582, 176, 575],
    "206954": [184, 177, 412, 191],
    "206955": [834, 419, 835, 178, 183, 188],
    "206957": [182],
    "206959": [184, 185, 186, 838],
    "206960": [185],
    "206961": [186, 174],
    "206962": [188],
    "206963": [191, 183],
    "206964": [192, 193, 195, 452, 453, 199],
    "206965": [192],
    "206967": [444],
    "206969": [195],
    "206970": [198],
    "206971": [834, 932, 198, 205, 699, 220],
    "206972": [453],
    "206973": [457, 450, 451, 199],
    "206976": [201],
    "206978": [205],
    "206979": [206, 494],
    "206982": [68],
    "206983": [81],
    "206984": [236, 190],
    "206985": [
      133, 518, 135, 136, 137, 529, 531, 542, 553, 554, 942, 560, 561, 562, 563,
      564, 565, 567, 568, 573, 574, 190, 576, 577, 450, 579, 580, 581, 583, 203,
      76, 480, 486,
    ],
    "206986": [212],
    "206989": [220],
    "206991": [204],
    "206992": [
      229, 165, 389, 200, 170, 204, 77, 942, 206, 916, 824, 825, 826, 222,
    ],
    "206993": [640, 224, 869, 222, 223],
    "206995": [223],
    "207000": [158],
    "207002": [227, 229],
    "207004": [230, 597, 622, 727],
    "207005": [546, 517, 230, 521, 522, 524, 557, 532, 411, 541, 479],
    "207006": [496, 231],
    "207007": [231],
    "207010": [
      544, 545, 519, 521, 554, 523, 555, 233, 234, 236, 528, 624, 597, 538, 539,
      637, 543,
    ],
    "207022": [517, 528, 560, 562, 564],
    "207023": [
      640, 133, 518, 135, 136, 134, 141, 526, 143, 142, 529, 527, 533, 154, 542,
      158, 159, 169, 558, 430, 180, 75, 716, 76, 203, 480, 226, 227, 486, 488,
      233, 234, 237, 628, 633, 634, 637, 639,
    ],
    "207024": [519],
    "207026": [225, 226, 644, 645, 520, 522, 624, 568, 633],
    "207027": [523],
    "207028": [525],
    "207029": [526],
    "207030": [527],
    "207031": [532],
    "207032": [533],
    "207036": [538],
    "207037": [539, 559],
    "207038": [485, 456, 402, 181, 444, 575],
    "207039": [402, 429],
    "207040": [540],
    "207042": [551, 543],
    "207043": [544, 561, 558, 639],
    "207044": [546],
    "207045": [547],
    "207046": [548],
    "207047": [549],
    "207048": [549],
    "207049": [550],
    "207050": [550],
    "207052": [556],
    "207053": [557],
    "207056": [563],
    "207057": [565],
    "207058": [937, 566],
    "207059": [440],
    "207061": [572],
    "207062": [572],
    "207063": [573],
    "207064": [576],
    "207065": [577],
    "207066": [578],
    "207067": [579],
    "207068": [581],
    "207069": [583],
    "207070": [408],
    "207071": [408, 969, 949],
    "207072": [412],
    "207073": [416, 419, 495],
    "207074": [416],
    "207075": [429],
    "207076": [484, 485, 452, 433, 441, 443, 445],
    "207077": [433],
    "207078": [439],
    "207079": [441],
    "207080": [443],
    "207081": [445],
    "207082": [446],
    "207083": [448, 843],
    "207084": [457],
    "207085": [479],
    "207086": [495],
    "207087": [496, 786],
    "207088": [509, 574],
    "207089": [509],
    "207091": [625, 629, 621],
    "207092": [625],
    "207093": [629, 630],
    "207097": [634],
    "207101": [705, 771, 772, 732, 809, 974, 751, 883, 692, 731, 988],
    "207102": [955, 808, 754, 692, 954, 731, 956, 957, 958, 895],
    "207103": [773, 774, 775, 776, 777, 693, 694],
    "207104": [693],
    "207105": [694, 727],
    "207106": [695],
    "207107": [713, 714, 812, 813, 749, 814, 815, 816, 818, 819, 695, 892],
    "207108": [697],
    "207109": [713, 697],
    "207110": [699],
    "207111": [700],
    "207112": [715, 700],
    "207113": [702, 718],
    "207114": [702, 847],
    "207115": [704],
    "207116": [704, 711, 941, 792, 946, 947, 948, 949, 950, 951, 952, 953],
    "207117": [705],
    "207118": [706, 890, 766, 863],
    "207119": [706, 863],
    "207121": [826, 711],
    "207122": [712],
    "207123": [712],
    "207124": [714],
    "207125": [715, 932],
    "207126": [716],
    "207127": [958, 718],
    "207128": [968, 969, 970, 971, 972, 893, 973, 719, 821, 921, 733, 830],
    "207129": [719],
    "207131": [732],
    "207132": [769, 901, 946, 761, 733],
    "207134": [749],
    "207135": [751],
    "207137": [754],
    "207139": [758],
    "207140": [864, 836, 843, 785, 758],
    "207141": [761],
    "207142": [762],
    "207143": [896, 762, 845, 846],
    "207144": [763],
    "207145": [837, 786, 981, 982, 793, 763, 765],
    "207147": [796, 765],
    "207148": [766],
    "207149": [769],
    "207150": [770],
    "207151": [770, 972],
    "207152": [771],
    "207153": [772],
    "207154": [773, 774, 775, 776, 777, 830],
    "207155": [785],
    "207156": [789, 790],
    "207157": [832, 804, 933, 806, 807, 850, 851, 884, 789, 790],
    "207158": [947, 948, 791],
    "207159": [897, 869, 855, 887, 854, 791, 856, 857, 927, 959],
    "207160": [792],
    "207161": [793],
    "207162": [944, 833, 796],
    "207163": [797],
    "207164": [804],
    "207165": [806],
    "207166": [808, 807],
    "207167": [809],
    "207169": [811],
    "207170": [811],
    "207171": [812, 813, 814, 815, 816],
    "207172": [817],
    "207173": [817],
    "207174": [818],
    "207175": [819],
    "207176": [821],
    "207177": [891, 923, 822, 823],
    "207178": [822],
    "207179": [823],
    "207180": [824],
    "207181": [825],
    "207182": [832],
    "207183": [833],
    "207184": [836, 837],
    "207185": [838],
    "207186": [840],
    "207187": [840],
    "207189": [845, 846],
    "207190": [847],
    "207191": [850],
    "207192": [851, 988],
    "207193": [852, 853],
    "207194": [852],
    "207195": [853],
    "207196": [854],
    "207197": [973, 855],
    "207198": [856],
    "207199": [857],
    "207200": [864],
    "207201": [883],
    "207202": [884],
    "207203": [971, 887],
    "207205": [890],
    "207206": [891],
    "207207": [892],
    "207208": [893],
    "207209": [895],
    "207210": [896],
    "207211": [897],
    "207212": [901],
    "207214": [957, 910],
    "207215": [945, 910],
    "207217": [916],
    "207220": [921],
    "207222": [923],
    "207224": [927],
    "207225": [928],
    "207226": [928],
    "207228": [933],
    "207229": [941],
    "207230": [944],
    "207231": [945],
    "207232": [950],
    "207233": [952, 951],
    "207234": [953],
    "207235": [954],
    "207236": [955],
    "207237": [956],
    "207238": [959],
    "207239": [968],
    "207240": [970],
    "207241": [974],
    "207242": [978],
    "207243": [979],
    "207244": [981],
    "207245": [982],
  },
  COM_single_player: {
    "100439": [217, 218],
    "101000": [871],
    "101820": [848],
    "102380": [858],
    "102832": [310],
    "102863": [976],
    "102968": [779],
    "103752": [880, 904, 829],
    "103903": [831],
    "104314": [960],
    "104379": [842],
    "105159": [
      794, 929, 805, 935, 442, 710, 477, 121, 483, 870, 871, 872, 873, 874, 875,
      876, 487, 498, 119, 889, 510,
    ],
    "107321": [907],
    "107477": [925],
    "108226": [449, 386, 930, 388, 357, 427, 492, 398, 470, 347, 380, 349, 414],
    "108470": [936],
    "109272": [802],
    "110673": [750],
    "110797": [
      800, 385, 386, 387, 382, 514, 449, 424, 362, 333, 461, 403, 500, 501, 436,
      474, 349, 478,
    ],
    "110995": [748],
    "111012": [384, 391, 426, 458, 940, 397, 465, 435, 409],
    "111066": [321, 482, 437, 394],
    "111849": [481, 417, 935, 395, 398, 431, 338, 434, 468],
    "112019": [648, 95, 208],
    "112035": [325],
    "112074": [961],
    "112092": [207],
    "112221": [646],
    "112280": [865],
    "112463": [118],
    "112620": [
      641, 911, 912, 926, 47, 310, 55, 960, 961, 962, 963, 964, 965, 71, 328,
      72, 207, 210, 213, 217, 99, 101, 102, 359, 103, 105, 106, 107, 108, 109,
      110, 111, 888, 505, 894,
    ],
    "112735": [943],
    "112868": [967],
    "113681": [311],
    "114105": [513, 516, 391, 489, 114, 787, 469, 405, 121, 508, 799],
    "114706": [635],
    "114726": [418, 467, 116, 504, 413],
    "114848": [778, 755, 756],
    "114897": [356],
    "114936": [107, 963, 781],
    "115009": [113, 866, 130],
    "115396": [112, 110],
    "115454": [798],
    "115543": [
      128, 898, 130, 516, 404, 925, 798, 799, 800, 801, 802, 803, 930, 420, 423,
      940, 46, 208, 976, 80, 470, 474, 476, 478, 95, 123, 124, 500, 502, 632,
      507, 764, 125, 638, 127,
    ],
    "115545": [848, 737, 746, 849],
    "115546": [
      736, 739, 740, 934, 966, 938, 861, 112, 915, 214, 215, 506, 859, 860, 858,
      862,
    ],
    "115548": [738],
    "115641": [216, 213, 214, 215],
    "115910": [888, 964],
    "116152": [865, 866, 867, 707, 708, 868, 874, 939, 748, 722, 759, 760, 315],
    "116532": [926],
    "116874": [708],
    "116921": [872],
    "117003": [768],
    "117113": [975],
    "117345": [980, 724],
    "117357": [210, 99],
    "117463": [707, 209, 507, 698, 794, 795],
    "117820": [
      385, 647, 396, 404, 406, 407, 415, 425, 428, 431, 438, 442, 454, 873, 491,
      492, 498, 499, 379, 510,
    ],
    "117821": [
      513, 515, 413, 418, 428, 432, 436, 325, 458, 333, 461, 464, 465, 468, 469,
      475, 482, 356, 357, 489, 490, 491, 497, 501, 502, 503,
    ],
    "118762": [
      512, 471, 514, 421, 390, 490, 396, 432, 497, 466, 499, 435, 405, 503, 473,
    ],
    "118777": [397],
    "118851": [860],
    "118893": [898],
    "118994": [515, 390, 459, 464, 475, 984, 472, 473, 379, 477, 414, 383],
    "119089": [721],
    "119529": [903],
    "119565": [740, 886],
    "119588": [739],
    "119730": [820, 701],
    "119797": [908],
    "120125": [877, 878],
    "120850": [912],
    "121041": [803],
    "121074": [939],
    "121222": [885],
    "121404": [641, 109, 55],
    "121558": [102, 71],
    "121582": [72, 101],
    "121690": [782],
    "121869": [977],
    "121940": [934, 742],
    "122002": [730, 767],
    "122064": [844],
    "122428": [508, 406],
    "123113": [362],
    "123505": [
      642, 899, 787, 795, 936, 701, 721, 82, 209, 983, 984, 985, 986, 987, 113,
      114, 115, 116, 117, 635, 511,
    ],
    "123575": [137],
    "123672": [
      384, 643, 387, 383, 395, 403, 407, 409, 410, 415, 417, 420, 423, 424, 425,
      426, 437, 438, 321, 454, 459, 467, 471, 472, 476, 481, 483, 487, 504, 123,
      380, 511,
    ],
    "123680": [938],
    "123682": [862],
    "123690": [
      768, 737, 924, 842, 746, 844, 943, 977, 735, 216, 218, 827, 828, 767,
    ],
    "123977": [643, 388, 646, 647, 648, 394, 410, 466, 986, 347],
    "123980": [962, 359, 328, 780, 108, 111, 505, 506],
    "124110": [125, 882, 117, 82],
    "124162": [875],
    "124165": [642, 983],
    "124591": [899],
    "131086": [309],
    "131100": [128],
    "131148": [966, 743],
    "131163": [632, 127],
    "131211": [736],
    "131342": [722, 119],
    "131401": [46],
    "131446": [900],
    "131480": [905],
    "131581": [870],
    "131846": [879],
    "131879": [861, 989],
    "131912": [914, 915],
    "131965": [868, 909],
    "132144": [747],
    "132312": [975, 919, 755, 724, 756, 886, 980, 917, 729, 918, 920, 828],
    "132318": [
      902, 839, 903, 841, 904, 905, 906, 907, 908, 909, 820, 309, 118, 985, 829,
    ],
    "132320": [931, 709, 967, 810, 877, 878, 879, 880, 881, 882, 913, 698],
    "132325": [900, 742, 743],
    "132724": [315],
    "132756": [760, 759],
    "132824": [103],
    "132995": [827],
    "133694": [105, 106, 47],
    "133713": [783],
    "133813": [965],
    "133814": [434, 338, 427],
    "133867": [
      738, 741, 779, 780, 781, 750, 782, 783, 784, 757, 885, 311, 922, 989, 734,
    ],
    "133893": [920],
    "134790": [931],
    "134906": [674],
    "135023": [859],
    "135039": [876],
    "135050": [735],
    "135179": [180],
    "135187": [725],
    "135190": [747],
    "135367": [911],
    "135373": [881],
    "135391": [867],
    "135745": [894],
    "135922": [987],
    "136613": [710],
    "136711": [801, 382],
    "136757": [784],
    "137237": [741],
    "144705": [849],
    "144777": [805],
    "145016": [80],
    "200413": [115, 124, 421, 839],
    "200907": [725],
    "201504": [674],
    "207008": [365],
    "207096": [638],
    "207100": [729],
    "207120": [709],
    "207130": [730, 778, 914, 831],
    "207133": [734],
    "207138": [757],
    "207146": [764, 902],
    "207168": [810],
    "207188": [841],
    "207204": [889],
    "207213": [906],
    "207216": [913],
    "207218": [917],
    "207219": [918, 919],
    "207221": [922],
    "207223": [924],
    "207227": [929],
  },
  COM_FSingle: {
    "108226": [449, 386, 930, 388, 357, 427, 492, 398, 470, 347, 380, 349, 414],
    "110995": [748],
    "111012": [384, 391, 426, 458, 940, 397, 465, 435, 409],
    "111066": [321, 482, 437, 394],
    "111849": [481, 417, 935, 395, 398, 431, 338, 434, 468],
    "112019": [648, 95, 208],
    "112035": [325],
    "112221": [646],
    "112463": [118],
    "114105": [513, 516, 391, 489, 114, 787, 469, 405, 121, 508, 799],
    "114706": [635],
    "114726": [418, 467, 116, 504, 413],
    "114897": [356],
    "115009": [113, 866, 130],
    "115543": [
      128, 898, 130, 516, 404, 925, 798, 799, 800, 801, 802, 803, 930, 420, 423,
      940, 46, 208, 976, 80, 470, 474, 476, 478, 95, 123, 124, 500, 502, 632,
      507, 764, 125, 638, 127,
    ],
    "117820": [
      385, 647, 396, 404, 406, 407, 415, 425, 428, 431, 438, 442, 454, 873, 491,
      492, 498, 499, 379, 510,
    ],
    "117821": [
      513, 515, 413, 418, 428, 432, 436, 325, 458, 333, 461, 464, 465, 468, 469,
      475, 482, 356, 357, 489, 490, 491, 497, 501, 502, 503,
    ],
    "118762": [
      512, 471, 514, 421, 390, 490, 396, 432, 497, 466, 499, 435, 405, 503, 473,
    ],
    "118777": [397],
    "118994": [515, 390, 459, 464, 475, 984, 472, 473, 379, 477, 414, 383],
    "122428": [508, 406],
    "123113": [362],
    "123505": [
      642, 899, 787, 795, 936, 701, 721, 82, 209, 983, 984, 985, 986, 987, 113,
      114, 115, 116, 117, 635, 511,
    ],
    "123672": [
      384, 643, 387, 383, 395, 403, 407, 409, 410, 415, 417, 420, 423, 424, 425,
      426, 437, 438, 321, 454, 459, 467, 471, 472, 476, 481, 483, 487, 504, 123,
      380, 511,
    ],
    "123977": [643, 388, 646, 647, 648, 394, 410, 466, 986, 347],
    "124110": [125, 882, 117, 82],
    "124165": [642, 983],
    "131086": [309],
    "131100": [128],
    "131163": [632, 127],
    "131401": [46],
    "132144": [747],
    "132724": [315],
    "133814": [434, 338, 427],
    "135190": [747],
    "145016": [80],
    "200413": [115, 124, 421, 839],
    "207096": [638],
  },
  COM_FDouble: {
    "206921": [79],
    "206950": [201, 173],
    "206952": [175],
    "206953": [193, 484, 582, 176, 575],
    "206954": [184, 177, 412, 191],
    "206957": [182],
    "206959": [184, 185, 186, 838],
    "206960": [185],
    "206961": [186, 174],
    "206962": [188],
    "206963": [191, 183],
    "206964": [192, 193, 195, 452, 453, 199],
    "206965": [192],
    "206967": [444],
    "206969": [195],
    "206970": [198],
    "206972": [453],
    "206973": [457, 450, 451, 199],
    "206976": [201],
    "206978": [205],
    "206983": [81],
    "206989": [220],
    "207006": [496, 231],
    "207038": [485, 456, 402, 181, 444, 575],
    "207039": [402, 429],
    "207072": [412],
    "207073": [416, 419, 495],
    "207074": [416],
    "207075": [429],
    "207076": [484, 485, 452, 433, 441, 443, 445],
    "207081": [445],
    "207084": [457],
    "207086": [495],
    "207087": [496, 786],
  },
  COM_MSingle: {
    "100439": [217, 218],
    "102832": [310],
    "112092": [207],
    "112620": [
      641, 911, 912, 926, 47, 310, 55, 960, 961, 962, 963, 964, 965, 71, 328,
      72, 207, 210, 213, 217, 99, 101, 102, 359, 103, 105, 106, 107, 108, 109,
      110, 111, 888, 505, 894,
    ],
    "113681": [311],
    "114936": [107, 963, 781],
    "115396": [112, 110],
    "115641": [216, 213, 214, 215],
    "117357": [210, 99],
    "121404": [641, 109, 55],
    "121558": [102, 71],
    "121582": [72, 101],
    "132824": [103],
    "133694": [105, 106, 47],
    "137237": [741],
    "207133": [734],
  },
  COM_MDouble: {
    "206911": [70],
    "206912": [73, 578, 131],
    "206913": [74, 212],
    "206914": [74],
    "206922": [160, 162, 131, 70, 167, 168, 73, 139, 140, 147, 150, 153, 157],
    "206925": [168, 140],
    "206934": [139, 151],
    "206935": [151],
    "206936": [153],
    "206939": [160, 147],
    "206940": [162],
    "206942": [164],
    "206943": [164],
    "206946": [167],
    "206986": [212],
    "207052": [556],
  },
  COM_MixedDouble: {
    "206916": [75],
    "206924": [134],
    "206927": [170, 141, 389],
    "206928": [
      547, 548, 520, 553, 555, 556, 525, 622, 559, 494, 142, 237, 531, 623, 239,
      411, 540,
    ],
    "206929": [143],
    "206930": [
      439, 68, 455, 488, 524, 621, 77, 623, 430, 628, 630, 567, 440, 541, 446,
      159,
    ],
    "206931": [644, 645, 455],
    "206937": [154, 451],
    "206938": [169, 157, 150],
    "206941": [239],
    "206944": [200, 580, 165, 551],
    "206947": [224, 545, 171, 225],
    "206948": [171],
    "206949": [448, 172, 179, 788],
    "206979": [206, 494],
    "206982": [68],
    "206984": [236, 190],
    "206985": [
      133, 518, 135, 136, 137, 529, 531, 542, 553, 554, 942, 560, 561, 562, 563,
      564, 565, 567, 568, 573, 574, 190, 576, 577, 450, 579, 580, 581, 583, 203,
      76, 480, 486,
    ],
    "206991": [204],
    "206992": [
      229, 165, 389, 200, 170, 204, 77, 942, 206, 916, 824, 825, 826, 222,
    ],
    "206993": [640, 224, 869, 222, 223],
    "206995": [223],
    "207000": [158],
    "207002": [227, 229],
    "207004": [230, 597, 622, 727],
    "207005": [546, 517, 230, 521, 522, 524, 557, 532, 411, 541, 479],
    "207007": [231],
    "207010": [
      544, 545, 519, 521, 554, 523, 555, 233, 234, 236, 528, 624, 597, 538, 539,
      637, 543,
    ],
    "207022": [517, 528, 560, 562, 564],
    "207023": [
      640, 133, 518, 135, 136, 134, 141, 526, 143, 142, 529, 527, 533, 154, 542,
      158, 159, 169, 558, 430, 180, 75, 716, 76, 203, 480, 226, 227, 486, 488,
      233, 234, 237, 628, 633, 634, 637, 639,
    ],
    "207024": [519],
    "207026": [225, 226, 644, 645, 520, 522, 624, 568, 633],
    "207027": [523],
    "207028": [525],
    "207029": [526],
    "207030": [527],
    "207031": [532],
    "207032": [533],
    "207036": [538],
    "207037": [539, 559],
    "207040": [540],
    "207042": [551, 543],
    "207043": [544, 561, 558, 639],
    "207044": [546],
    "207045": [547],
    "207046": [548],
    "207047": [549],
    "207048": [549],
    "207049": [550],
    "207050": [550],
    "207053": [557],
    "207056": [563],
    "207057": [565],
    "207059": [440],
    "207061": [572],
    "207062": [572],
    "207063": [573],
    "207064": [576],
    "207065": [577],
    "207066": [578],
    "207067": [579],
    "207068": [581],
    "207069": [583],
    "207070": [408],
    "207071": [408, 969, 949],
    "207077": [433],
    "207078": [439],
    "207079": [441],
    "207080": [443],
    "207082": [446],
    "207083": [448, 843],
    "207085": [479],
    "207088": [509, 574],
    "207089": [509],
    "207091": [625, 629, 621],
    "207092": [625],
    "207093": [629, 630],
    "207097": [634],
  },
};

