import { Fragment } from 'react';
import './VerticalTable.scss';

import {
  ballStartTimeIndex,
  ballHomePlayerScoreIndex,
  ballAwayPlayerScoreIndex,
  ballHighlightIndex,
} from 'src/constants';

const VerticalTable = ({
  matchData,
  roundData,
  roundIndex,
  videoRef,
  currentBall,
  setVideoData,
  deuce = false,
  cp = false,
}) => {
  const renderTableRows = () => {
    let HomePlayerScore = 0;
    let AwayPlayerScore = 0;

    const handleColumnClick = (ballResult) => {
      videoRef.current.currentTime = ballResult[ballStartTimeIndex] + 0.01;
      setVideoData((prevStatus) => ({
        ...prevStatus,
        skipped: true,
      }));
    };

    return (
      <>
        <tr className="round-header">
          <td colSpan={2}>回合 {roundIndex + 1}</td>
        </tr>
        <tr className="player-names">
          <td className="home-player">
            {matchData.homePlayer.length <= 15
              ? matchData.homePlayer
              : `${matchData.homePlayer.slice(0, 15)}...`}
          </td>
          <td className="away-player">
            {matchData.awayPlayer.length <= 15
              ? matchData.awayPlayer
              : `${matchData.awayPlayer.slice(0, 15)}...`}
          </td>
        </tr>

        {roundData.map((row, index) => {
          const HomePlayerCurrentScore = row[ballHomePlayerScoreIndex];
          const AwayPlayerCurrentScore = row[ballAwayPlayerScoreIndex];

          if (cp && (HomePlayerCurrentScore < 8 || AwayPlayerCurrentScore < 8))
            return null;
          if (
            deuce &&
            (HomePlayerCurrentScore < 10 || AwayPlayerCurrentScore < 10)
          )
            return null;

          const highlight = row[ballHighlightIndex];
          const HomePlayerScoreIncreased =
            HomePlayerCurrentScore > HomePlayerScore;
          const AwayPlayerScoreIncreased =
            AwayPlayerCurrentScore > AwayPlayerScore;

          HomePlayerScore = HomePlayerCurrentScore;
          AwayPlayerScore = AwayPlayerCurrentScore;

          const isCurrentBall = index === currentBall;
          const scoreClasses = [
            'score-row',
            highlight && 'highlight',
            isCurrentBall && 'current-ball',
            highlight && isCurrentBall && 'highlight-current',
          ]
            .filter(Boolean)
            .join(' ');

          return (
            <Fragment
              key={`${HomePlayerCurrentScore}-${AwayPlayerCurrentScore}`}
            >
              <tr
                className={scoreClasses}
                onClick={() => handleColumnClick(row)}
                title="跳到本球"
              >
                <td
                  className={HomePlayerScoreIncreased ? 'score-increased' : ''}
                >
                  {HomePlayerCurrentScore}
                </td>
                <td
                  className={AwayPlayerScoreIncreased ? 'score-increased' : ''}
                >
                  {AwayPlayerCurrentScore}
                </td>
              </tr>
            </Fragment>
          );
        })}
      </>
    );
  };

  return (
    <div className="score-table-wrapper">
      <table className="vertical-score-table">
        <tbody>{renderTableRows()}</tbody>
      </table>
    </div>
  );
};

export default VerticalTable;
