import React from "react";
import { CircularProgress, Box, Typography, useTheme } from "@mui/material";
import {
  Refresh as RefreshIcon,
  Cloud as CloudIcon,
  Sync as SyncIcon,
  Cached as CachedIcon,
  AutorenewOutlined as AutorenewIcon,
} from "@mui/icons-material";
import { SvgIconProps } from "@mui/material/SvgIcon";

type LoadingIconType =
  | "circular"
  | "refresh"
  | "cloud"
  | "sync"
  | "cached"
  | "autorenew";

interface LoadingSpinnerProps {
  size?: number;
  fullScreen?: boolean;
  message?: string;
  icon?: LoadingIconType;
  color?:
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning"
    | "inherit";
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  size = 40,
  fullScreen = false,
  message = "Loading...",
  icon = "circular",
  color = "primary",
}) => {
  const theme = useTheme();

  const getLoadingIcon = () => {
    const spinAnimation = {
      animation: "spin 1s linear infinite",
      "@keyframes spin": {
        "0%": {
          transform: "rotate(0deg)",
        },
        "100%": {
          transform: "rotate(360deg)",
        },
      },
    };

    // For non-circular icons
    const commonIconProps: SvgIconProps = {
      sx: spinAnimation,
      fontSize: "inherit",
      color: color,
    };

    // Container style for consistent sizing
    const containerStyle = {
      width: size,
      height: size,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: size,
    };

    switch (icon) {
      case "refresh":
        return (
          <Box sx={containerStyle}>
            <RefreshIcon {...commonIconProps} />
          </Box>
        );
      case "cloud":
        return (
          <Box sx={containerStyle}>
            <CloudIcon {...commonIconProps} />
          </Box>
        );
      case "sync":
        return (
          <Box sx={containerStyle}>
            <SyncIcon {...commonIconProps} />
          </Box>
        );
      case "cached":
        return (
          <Box sx={containerStyle}>
            <CachedIcon {...commonIconProps} />
          </Box>
        );
      case "autorenew":
        return (
          <Box sx={containerStyle}>
            <AutorenewIcon {...commonIconProps} />
          </Box>
        );
      case "circular":
      default:
        return <CircularProgress size={size} color={color} />;
    }
  };

  if (fullScreen) {
    return (
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          zIndex: theme.zIndex.modal + 1,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
            bgcolor: "background.paper",
            borderRadius: 1,
            p: 3,
            boxShadow: theme.shadows[4],
          }}
        >
          {getLoadingIcon()}
          <Typography variant="body2" color="text.secondary">
            {message}
          </Typography>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        p: 1,
      }}
    >
      {getLoadingIcon()}
    </Box>
  );
};

export default LoadingSpinner;
