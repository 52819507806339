import React, { useState, useEffect } from 'react';
import { Grid, Typography, Button, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Playlist } from 'src/types/playlist';
import * as playlistAPI from 'src/api/playlistService';
import Padding from 'src/components/padding';
import LoadingScreen from 'src/components/loading/LoadingScreen';
import PlaylistCard from 'src/components/playlistCard/PlaylistCard';
import Cookies from 'js-cookie';

import './Playlist.scss';

interface PlaylistPageProps {
    userType: string;
    userName: string;
}

interface MatchDict {
    [key: string]: string;
}

const StyledHeader = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
}));

const PlaylistPage: React.FC<PlaylistPageProps> = ({ userType, userName }) => {
    const token = Cookies.get('token');

    const [playlists, setPlaylists] = useState<Playlist[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [selectedPlaylist, setSelectedPlaylist] = useState<Playlist | null>(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [matchDict, setMatchDict] = useState<MatchDict>({});
    const [allTags, setAllTags] = useState<string[]>([]);

    const handleOpenDialog = (playlist?: Playlist) => {
        setSelectedPlaylist(playlist || null);
        setIsDialogOpen(true);
    };



    const fetchPlaylists = async () => {
        try {
            const response = await playlistAPI.fetchPlaylistDataAPI(
                { user_name: userName, user_type: userType },
                token as string
            );
            setMatchDict(response[1]);
            setPlaylists(response[0]);

            // Extract all unique tags from the playlists
            const tags = new Set<string>();
            response[0].forEach((playlist: any) => {
                playlist.custom_tags.forEach((tag: string) => tags.add(tag));
            });
            setAllTags(Array.from(tags));
            setLoading(false);
        } catch (api_error) {
            console.error('Error fetching user list:', api_error);
            setError(api_error as string);
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchPlaylists();
    }, [userType, userName]);

    const handleDeletePlaylist = async (playlistId: number): Promise<void> => {
        try {
            await playlistAPI.deletePlaylistAPI(playlistId, token as string);
            setPlaylists(prevPlaylists =>
                prevPlaylists.filter(playlist => playlist.playlist_id !== playlistId)
            );
        } catch (error) {
            console.error('Error deleting playlist:', error);
            throw new Error('刪除播放清單時發生錯誤');
        }
    };

    if (loading) return <LoadingScreen />;
    if (error) return <div className="playlist-error">Error: {error}</div>;

    return (
        <Box className="playlist-management">
            <StyledHeader>
                <Typography variant="h5" component="h2">
                    {userName}的播放清單
                </Typography>
                <Button
                    variant="contained"
                    className='main-add-playlist-button'
                    onClick={() => window.location.href = "/playlist/-1"}
                >
                    添加清單
                </Button>
            </StyledHeader>

            <Grid container spacing={2} className='playlists-grid-container'>
                {playlists.map((playlist) => (
                    <Grid item xs={12} sm={12} md={12} key={playlist.playlist_id} className='playlists-grid'>
                        <PlaylistCard
                            playlist={playlist}
                            onDelete={handleDeletePlaylist}
                            onEdit={() => window.location.href = `/playlist/${playlist.playlist_id}`}
                        />
                    </Grid>
                ))}
            </Grid>
            <Padding />
        </Box>
    );
};

export default PlaylistPage;