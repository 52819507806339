
import React from 'react';
import { lastWttPlayerId } from 'src/constants';
import { countryCodeMapping } from 'src/constants';
import Flag from 'react-world-flags';
import { translateToChinese } from 'src/utils/utils';

import './PlayerProfilePanel.scss';

interface PlayerProfileProps {
  playerData: Record<string, string>;
  playerStats: {
    totalMatches: number;
    wins: number;
    losses: number;
    winRate: string;
    stageStats: Record<string, { wins: number; losses: number }>;
    subEventStats: Record<string, { wins: number; losses: number }>;
    gripStats: Record<string, { wins: number; losses: number }>;
    handednessStats: Record<string, { wins: number; losses: number }>;
    countryStats: Record<string, { wins: number; losses: number }>;
    opponentStats: Record<
      string,
      {
        wins: number;
        losses: number;
        totalMatches: number;
        winPercentage: string;
      }
    >;
  };
  accountType: string;
  opponent: boolean;
}


const mergeOthers = (
  stats: Record<string, { wins: number; losses: number }>,
) => {
  const mergedStats: Record<string, { wins: number; losses: number }> = {};
  let otherWins = 0;
  let otherLosses = 0;

  Object.entries(stats).forEach(([key, { wins, losses }]) => {
    if (
      key === '其他' ||
      key === 'others' ||
      key === 'Others' ||
      key === '_' ||
      key === 'undefined'
    ) {
      otherWins += wins;
      otherLosses += losses;
    } else {
      mergedStats[key] = { wins, losses };
    }
  });

  if (otherWins > 0 || otherLosses > 0) {
    mergedStats['其他'] = { wins: otherWins, losses: otherLosses };
  }

  return mergedStats;
};

const PlayerProfilePanel: React.FC<PlayerProfileProps> = ({
  playerData,
  playerStats,
  accountType,
  opponent = false,
}) => {

  const code =
    countryCodeMapping[
    playerData.country_code as keyof typeof countryCodeMapping
    ] || '';

  const renderStatSection = (
    title: string,
    stats: Record<string, { wins: number; losses: number }> | undefined,
  ) => {
    if (!stats) return null;

    const mergedStats = mergeOthers(stats);

    return (
      <div className="stat-section">
        <h3>{translateToChinese(title)}</h3>
        {Object.entries(mergedStats).map(([key, { wins, losses }]) => (
          <div key={key} className="stat-item">
            <span>{translateToChinese(key)}: </span>
            <span>
              {wins}勝 - {losses}敗
            </span>
          </div>
        ))}
      </div>
    );
  };

  const renderOpponentStats = () => {
    if (!playerStats.opponentStats) return null;

    const mergedStats = mergeOthers(playerStats.opponentStats);

    return (
      <div className="stat-section">
        <h3>{translateToChinese('Opponent Statistics')}</h3>
        {Object.entries(mergedStats)
          .sort(([, a], [, b]) => b.wins + b.losses - (a.wins + a.losses))
          .slice(0, 5) // Show only top 5 opponents
          .map(([opponentName, stats]) => {
            const totalMatches = stats.wins + stats.losses;
            const winPercentage =
              totalMatches > 0
                ? ((stats.wins / totalMatches) * 100).toFixed(2)
                : '0.00';
            return (
              <div key={opponentName} className="stat-item">
                <span>{translateToChinese(opponentName)}: </span>
                <span>
                  {stats.wins}勝 - {stats.losses}敗 ({winPercentage}%)
                </span>
              </div>
            );
          })}
      </div>
    );
  };

  return (
    <div className="player-profile-panel-container">
      <div className="player-photo-container">
        {playerData.gender !== null &&
          (playerData.gender === 'F' || playerData.gender === 'M') && (
            <p className="age" style={opponent ? { right: 0, fontSize: 'small' } : { left: 0, fontSize: 'small' }}>
              年齡 : {playerData.actual_age}
            </p>
          )}
        {playerData.gender === 'MM' ||
          playerData.gender === 'FF' ||
          playerData.gender === 'MF' ? (
          <div className="double-img-container">
            <img
              className="player1-img"
              src={
                playerData.head_shot ||
                'https://uploads.concordia.net/2017/08/30180948/headshot-placeholder.jpg'
              }
              alt="Player Headshot"
              onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                if (
                  e.currentTarget &&
                  e.currentTarget.src !==
                  'https://uploads.concordia.net/2017/08/30180948/headshot-placeholder.jpg'
                ) {
                  e.currentTarget.src =
                    'https://uploads.concordia.net/2017/08/30180948/headshot-placeholder.jpg';
                }
              }}
            />
            <img
              className="player2-img"
              src={
                playerData.player2_head_shot ||
                'https://uploads.concordia.net/2017/08/30180948/headshot-placeholder.jpg'
              }
              alt="Player Headshot"
              onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
                if (
                  e.currentTarget &&
                  e.currentTarget.src !==
                  'https://uploads.concordia.net/2017/08/30180948/headshot-placeholder.jpg'
                ) {
                  e.currentTarget.src =
                    'https://uploads.concordia.net/2017/08/30180948/headshot-placeholder.jpg';
                }
              }}
            />
          </div>
        ) : (
          <img
            className="player-img"
            src={
              playerData.head_shot ||
              'https://uploads.concordia.net/2017/08/30180948/headshot-placeholder.jpg'
            }
            alt="Player Headshot"
            onError={(e: React.SyntheticEvent<HTMLImageElement>) => {
              if (
                e.currentTarget &&
                e.currentTarget.src !==
                'https://uploads.concordia.net/2017/08/30180948/headshot-placeholder.jpg'
              ) {
                e.currentTarget.src =
                  'https://uploads.concordia.net/2017/08/30180948/headshot-placeholder.jpg';
              }
            }}
          />
        )}

        <div className="player-profile-main-info">
          <div className="player-profile-main-info-top">
            <div className="player-profile-name-panel">
              <span>
                {playerData.player_name}
                {code === 'TPE' ? (
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSmBxo_grwIppKabQW2G7J0zwWZwvqWC8DWQg&s"
                    className="player-smallflag"
                  />
                ) : code ? (
                  <Flag code={code} className="player-smallflag" />
                ) : (
                  <img
                    src="https://t4.ftcdn.net/jpg/05/38/56/33/360_F_538563343_1Yb2J773QHkZSdWnIVhVxW4xjfOB8Rhi.jpg"
                    alt="No image"
                    className="player-smallflag"
                  />
                )}
              </span>
              <p className="country">
                {playerData.country ? playerData.country : '_'}
              </p>
            </div>
            <span className="player-last-updated" >
              {playerData.last_updated !== 'Invalid Date'
                ? `最後更新 : ${playerData.last_updated}`
                : ''}
            </span>
          </div>
          <div className="player-profile-main-info-bot">
            <div className="player-additional-info-panel">
              {playerData.handedness && (
                <span className="hand"> {translateToChinese(playerData.handedness)}</span>
              )}
              {playerData.grip && (
                <span className="grip"> {translateToChinese(playerData.grip)}</span>
              )}
              {playerData.gender && (
                <span className="gender">
                  {translateToChinese(playerData.gender)}
                </span>
              )}
              {accountType === 'm' && (
                <div className="plastic">
                  <span>正手膠: {playerData.rubber_type}</span>
                  <span>反手膠: {playerData.flipped_rubber_type}</span>
                </div>
              )}
            </div>
            <div className="wtt-link" >
              {accountType === 'a' &&
                parseInt(playerData.player_id) <= lastWttPlayerId && (
                  <a
                    href={`https://worldtabletennis.com/playerDescription?playerId=${playerData.player_id}`}
                    target="_blank"
                  >
                    球員檔案(WTT)
                  </a>
                )}
            </div>
          </div>
        </div>
      </div>
      <div className="player-additional-info-container">
        {playerStats && (
          <div className="player-stats">
            <div className="overall-stats">
              <h2 className="dashboard-header">
                {translateToChinese('Overall Statistics')}
              </h2>
              <div></div>
              <div className="stat-item">
                <span>{translateToChinese('Total Matches')}</span>
                <span>{playerStats.totalMatches}</span>
              </div>
              <div className="stat-item">
                <span>{translateToChinese('Wins')}</span>
                <span>{playerStats.wins}</span>
              </div>
              <div className="stat-item">
                <span>{translateToChinese('Losses')}</span>
                <span>{playerStats.losses}</span>
              </div>
              <div className="stat-item">
                <span>{translateToChinese('Win Rate')}</span>
                <span>{playerStats.winRate}%</span>
              </div>
            </div>

            {renderStatSection('Stage Statistics', playerStats.stageStats)}
            {renderStatSection('Event Statistics', playerStats.subEventStats)}
            {renderStatSection('Grip Statistics', playerStats.gripStats)}
            {renderStatSection(
              'Handedness Statistics',
              playerStats.handednessStats,
            )}
            {renderStatSection('Country Statistics', playerStats.countryStats)}
            {renderOpponentStats()}
          </div>
        )}
      </div>
    </div>
  );
};

export default PlayerProfilePanel;
