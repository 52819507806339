// graphStore.ts
import { create } from "zustand";
import { produce } from "immer";
import { memoize } from "lodash";
import {
  GraphState,
  GraphNode,
  GraphEdge,
  GraphLayoutSettings,
} from "./graphTypes";
import { ReachableResponse } from "../store/Type";

// constants.ts
export const GRAPH_COLORS = {
  PLAYER: {
    NODE: "#4444ff",
    EDGE: "#999999",
  },
  GAME: {
    NODE: "#cccccc",
    EDGE: "#aaaaaa",
  },
};

export const GRAPH_SIZES = {
  PLAYER: {
    NODE: 10,
    EDGE: 2,
  },
  GAME: {
    NODE: 5,
    EDGE: 1,
  },
};

export const DEFAULT_LAYOUT_SETTINGS: GraphLayoutSettings = {
  gravity: 1.5,
  scalingRatio: 8,
  strongGravityMode: true,
  slowDown: 5,
  barnesHutOptimize: true,
  barnesHutTheta: 0.5,
  iterations: 200,
};

// graphHelpers.ts
export const generateRandomPosition = () => Math.random() * 1000 - 500;

// Updated creation functions
export const createPlayerNode = (playerId: number, player: any): GraphNode => ({
  id: String(playerId),
  label: player.name,
  x: generateRandomPosition(),
  y: generateRandomPosition(),
  size: GRAPH_SIZES.PLAYER.NODE,
  color: GRAPH_COLORS.PLAYER.NODE,
  hidden: false,
  type: "player",
  data: {
    player_id: playerId,
    country_code: player.country_code,
    head_shot: player.head_shot,
    total_connections: player.total_connections,
  },
});

export const createGameNode = (gameId: number): GraphNode => ({
  id: `game-${gameId}`,
  label: `Game ${gameId}`,
  x: generateRandomPosition(),
  y: generateRandomPosition(),
  size: GRAPH_SIZES.GAME.NODE,
  color: GRAPH_COLORS.GAME.NODE,
  hidden: true,
  type: "game",
  data: {
    game_id: gameId,
  },
});

export const createGameEdge = (
  gameId: number,
  playerId: string
): GraphEdge => ({
  id: `edge-${gameId}-${playerId}`,
  source: `game-${gameId}`,
  target: playerId,
  hidden: true,
  size: GRAPH_SIZES.GAME.EDGE,
  color: GRAPH_COLORS.GAME.EDGE,
  data: {
    games: [gameId], // Changed to match interface by using an array
  },
});

export const createPlayerEdge = (
  source: string,
  target: string,
  games: any[]
): GraphEdge => ({
  id: `edge-${source}-${target}`,
  source,
  target,
  hidden: false,
  size: GRAPH_SIZES.PLAYER.EDGE,
  color: GRAPH_COLORS.PLAYER.EDGE,
  data: { games },
});

// Memoized helper functions
const memoizedCreatePlayerNode = memoize(createPlayerNode);
const memoizedCreateGameNode = memoize(createGameNode);
const memoizedCreateGameEdge = memoize(
  (gameId: number, playerId: string) => createGameEdge(gameId, playerId),
  (gameId, playerId) => `${gameId}-${playerId}`
);

const memoizedCreatePlayerEdge = memoize(
  (source: string, target: string, games: any[]) =>
    createPlayerEdge(source, target, games),
  (source, target) => `${source}-${target}`
);

const useGraphStore = create<GraphState>((set, get) => ({
  // Initial state
  nodes: {},
  edges: {},
  selectedNodes: new Set<string>(),
  selectedEdges: new Set<string>(),
  hiddenNodes: new Set<string>(),
  hiddenEdges: new Set<string>(),
  showGames: false,
  layoutRunning: false,
  visibleDepth: 2,
  startPlayer: null,
  targetPlayer: null,
  reachablePlayers: null,
  selectedCategory: null,
  layoutSettings: DEFAULT_LAYOUT_SETTINGS,
  isDebugMode: true,
  isLoading: false,

  // Loading state management
  setIsLoading: (loading) => {
    const { debugLog } = get();
    debugLog("Setting loading state:", loading);
    set(
      produce((state) => {
        state.isLoading = loading;
      })
    );
  },

  // Debug functions
  setDebugMode: (enabled) =>
    set(
      produce((state) => {
        state.isDebugMode = enabled;
      })
    ),

  debugLog: (message, data) => {
    const { isDebugMode } = get();
    if (isDebugMode) {
      console.log(`[Graph Debug] ${message}`, data || "");
    }
  },
  // Graph settings
  setVisibleDepth: (depth) => {
    const { debugLog } = get();
    debugLog("Setting visible depth:", depth);
    set(
      produce((state) => {
        state.visibleDepth = depth;
        state.isLoading = true;
      })
    );

    setTimeout(() => {
      get().updateNodeVisibility();
      set(
        produce((state) => {
          state.isLoading = false;
        })
      );
    }, 0);
  },

  setStartPlayer: (playerId) => {
    const { debugLog } = get();
    debugLog("Setting start player:", playerId);
    set({ startPlayer: playerId });

    if (!playerId) {
      set({
        hiddenNodes: new Set(),
        hiddenEdges: new Set(),
        selectedNodes: new Set(),
        selectedEdges: new Set(),
      });

      const { nodes, edges } = get();
      Object.keys(nodes).forEach((nodeId) => {
        get().updateNode(nodeId, { hidden: false });
      });
      Object.keys(edges).forEach((edgeId) => {
        get().updateEdge(edgeId, { hidden: false });
      });
    } else {
      get().updateNodeVisibility();
    }
  },

  setTargetPlayer: (playerId) => {
    set({ targetPlayer: playerId });
    get().updateNodeVisibility();
  },

  setSelectedCategory: (category) => set({ selectedCategory: category }),
  setReachablePlayers: (data) => {
    set({ reachablePlayers: data });
    get().updateNodeVisibility();
  },
  setShowGames: (show) => {
    set({ showGames: show });
    get().updateNodeVisibility();
  },
  setLayoutSettings: (settings) =>
    set((state) => ({
      layoutSettings: { ...state.layoutSettings, ...settings },
    })),

  // Optimized node operations
  addNode: (node) => {
    const { debugLog } = get();
    debugLog("Adding node:", node);

    set(
      produce((state) => {
        state.nodes[node.id] = {
          ...node,
          x: generateRandomPosition(),
          y: generateRandomPosition(),
          size: GRAPH_SIZES.PLAYER.NODE,
        };
      })
    );
  },

  updateNode: (id, updates, setLoading = false) => {
    const { debugLog } = get();
    debugLog(`Updating node ${id}:`, updates);

    if (setLoading) {
      set(
        produce((state) => {
          state.isLoading = true;
        })
      );
    }

    try {
      set(
        produce((state) => {
          if (state.nodes[id]) {
            Object.assign(state.nodes[id], updates);
          }
        })
      );
    } catch (error) {
      debugLog(`Error updating node ${id}:`, error);
    } finally {
      if (setLoading) {
        set(
          produce((state) => {
            state.isLoading = false;
          })
        );
      }
    }
  },

  removeNode: (id) => {
    const { debugLog } = get();
    debugLog(`Removing node ${id}`);

    set((state) => {
      const { [id]: _, ...remainingNodes } = state.nodes;
      const newEdges = { ...state.edges };

      // Remove connected edges
      Object.entries(state.edges).forEach(([edgeId, edge]) => {
        if (edge.source === id || edge.target === id) {
          debugLog(`Removing connected edge ${edgeId}`);
          delete newEdges[edgeId];
        }
      });

      return { nodes: remainingNodes, edges: newEdges };
    });
  },

  selectNode: (id) =>
    set((state) => ({
      selectedNodes: new Set([...state.selectedNodes, id]),
    })),

  deselectNode: (id) =>
    set((state) => {
      const newSelected = new Set(state.selectedNodes);
      newSelected.delete(id);
      return { selectedNodes: newSelected };
    }),

  hideNode: (id) =>
    set((state) => ({
      hiddenNodes: new Set([...state.hiddenNodes, id]),
    })),

  showNode: (id) =>
    set((state) => {
      const newHidden = new Set(state.hiddenNodes);
      newHidden.delete(id);
      return { hiddenNodes: newHidden };
    }),

  // Edge Operations
  addEdge: (edge) => {
    const { debugLog } = get();
    debugLog("Adding edge:", edge);

    set((state) => ({
      edges: {
        ...state.edges,
        [edge.id]: {
          ...edge,
          size: 1,
        },
      },
    }));
  },

  updateEdge: (id, updates, setLoading = false) => {
    const { debugLog } = get();
    debugLog(`Updating edge ${id}:`, updates);

    if (setLoading) set({ isLoading: true });

    try {
      set((state) => ({
        edges: {
          ...state.edges,
          [id]: { ...state.edges[id], ...updates },
        },
      }));
    } catch (error) {
      debugLog(`Error updating edge ${id}:`, error);
    } finally {
      if (setLoading) set({ isLoading: false });
    }
  },

  removeEdge: (id) => {
    set((state) => {
      const { [id]: _, ...remainingEdges } = state.edges;
      return { edges: remainingEdges };
    });
  },

  selectEdge: (id) =>
    set((state) => ({
      selectedEdges: new Set([...state.selectedEdges, id]),
    })),

  deselectEdge: (id) =>
    set((state) => {
      const newSelected = new Set(state.selectedEdges);
      newSelected.delete(id);
      return { selectedEdges: newSelected };
    }),

  hideEdge: (id) =>
    set((state) => ({
      hiddenEdges: new Set([...state.hiddenEdges, id]),
    })),

  showEdge: (id) =>
    set((state) => {
      const newHidden = new Set(state.hiddenEdges);
      newHidden.delete(id);
      return { hiddenEdges: newHidden };
    }),

  // Optimized bulk operations using Sets
  hideNodes: (ids) =>
    set(
      produce((state) => {
        state.hiddenNodes = new Set([...state.hiddenNodes, ...ids]);
      })
    ),

  showNodes: (ids) =>
    set(
      produce((state) => {
        state.hiddenNodes = new Set(
          [...state.hiddenNodes].filter((id) => !ids.includes(id))
        );
      })
    ),

  hideEdges: (ids) =>
    set((state) => ({
      hiddenEdges: new Set([...state.hiddenEdges, ...ids]),
    })),

  showEdges: (ids) =>
    set((state) => {
      const newHidden = new Set(state.hiddenEdges);
      ids.forEach((id) => newHidden.delete(id));
      return { hiddenEdges: newHidden };
    }),

  clearSelection: () =>
    set({
      selectedNodes: new Set(),
      selectedEdges: new Set(),
    }),

  // Graph Initialization
  initializeFromPlayers: (players, category) => {
    const { debugLog } = get();
    const categoryPlayers = players[category] || {};

    set(
      produce((state) => {
        // Clear existing state
        state.nodes = {};
        state.edges = {};
        state.selectedCategory = category;
        state.hiddenNodes = new Set();
        state.hiddenEdges = new Set();
        state.selectedNodes = new Set();
        state.selectedEdges = new Set();

        // Process players
        Object.entries(categoryPlayers).forEach(([playerId, player]) => {
          // Use memoized functions for node creation
          state.nodes[playerId] = memoizedCreatePlayerNode(
            Number(playerId),
            player
          );
          debugLog(
            `Created player node ${player.name}:`,
            state.nodes[playerId]
          );

          // Process connections efficiently
          player.connections.forEach((connection) => {
            connection.games.forEach((gameId) => {
              const gameNodeId = `game-${gameId}`;

              // Create game node if it doesn't exist
              if (!state.nodes[gameNodeId]) {
                state.nodes[gameNodeId] = memoizedCreateGameNode(gameId);
                debugLog(
                  `Created game node ${gameId}:`,
                  state.nodes[gameNodeId]
                );
              }

              // Create game edges using memoized function
              const gameToPlayerEdgeId = `edge-${gameId}-${connection.from}`;
              if (!state.edges[gameToPlayerEdgeId]) {
                state.edges[gameToPlayerEdgeId] = memoizedCreateGameEdge(
                  gameId,
                  String(connection.from)
                );
                debugLog(
                  `Created edge ${gameToPlayerEdgeId}:`,
                  state.edges[gameToPlayerEdgeId]
                );
              }

              const playerToGameEdgeId = `edge-${gameId}-${connection.to}`;
              if (!state.edges[playerToGameEdgeId]) {
                state.edges[playerToGameEdgeId] = memoizedCreateGameEdge(
                  gameId,
                  String(connection.to)
                );
                debugLog(
                  `Created edge ${playerToGameEdgeId}:`,
                  state.edges[playerToGameEdgeId]
                );
              }
            });

            // Create player-to-player edge
            const [source, target] = [connection.from, connection.to].sort(
              (a, b) => a - b
            );
            const playerEdgeId = `edge-${source}-${target}`;

            if (!state.edges[playerEdgeId]) {
              state.edges[playerEdgeId] = memoizedCreatePlayerEdge(
                String(source),
                String(target),
                connection.games
              );
              debugLog(
                `Created player edge ${playerEdgeId}:`,
                state.edges[playerEdgeId]
              );
            }
          });
        });
      })
    );

    debugLog("Graph initialization complete", {
      nodeCount: Object.keys(get().nodes).length,
      edgeCount: Object.keys(get().edges).length,
    });
  },

  resetGraph: () => {
    set({
      nodes: {},
      edges: {},
      selectedNodes: new Set(),
      selectedEdges: new Set(),
      hiddenNodes: new Set(),
      hiddenEdges: new Set(),
      showGames: false,
      layoutRunning: false,
      startPlayer: null,
      targetPlayer: null,
      reachablePlayers: null,
      selectedCategory: null,
    });
  },

  updateNodeVisibility: () => {
    const {
      nodes,
      edges,
      startPlayer,
      visibleDepth,
      reachablePlayers,
      selectedCategory,
      showGames,
      debugLog,
    } = get();

    // Handle game nodes visibility independently
    Object.keys(nodes).forEach((nodeId) => {
      if (nodeId.startsWith("game-")) {
        get().updateNode(nodeId, { hidden: !showGames });
      }
    });

    Object.values(edges).forEach((edge) => {
      const isGameEdge =
        edge.source.startsWith("game-") || edge.target.startsWith("game-");
      if (isGameEdge) {
        get().updateEdge(edge.id, { hidden: !showGames });
      }
    });

    // Only proceed with player visibility if we have required data
    if (!startPlayer || !reachablePlayers || !selectedCategory) {
      debugLog("Missing required data for visibility update", {
        hasStartPlayer: !!startPlayer,
        hasReachablePlayers: !!reachablePlayers,
        hasSelectedCategory: !!selectedCategory,
      });
      return;
    }

    // Rest of the function for player visibility
    // const playerData = reachablePlayers[startPlayer];
    // console.log("playerData", playerData);
    // if (!playerData) {
    //   debugLog(
    //     "No reachable players found for selected category and start player"
    //   );
    //   return;
    // }

    try {
      const depthPlayers = [parseInt(startPlayer, 10)];
      const playerData = reachablePlayers[String(startPlayer)] as ReachableResponse;
      const depthStructured = playerData.depth_structured?.[selectedCategory];
      if (depthStructured) {
        for (let depth = 1; depth <= visibleDepth; depth++) {
          const playersAtDepth = depthStructured[depth]?.players;
          if (Array.isArray(playersAtDepth)) {
            depthPlayers.push(...playersAtDepth);
          }
        }
      }

      const visiblePlayerSet = new Set(depthPlayers);

      // Update player nodes visibility
      Object.keys(nodes).forEach((nodeId) => {
        if (!nodeId.startsWith("game-")) {
          const isVisible = visiblePlayerSet.has(parseInt(nodeId, 10));
          get().updateNode(nodeId, { hidden: !isVisible });
        }
      });

      // Update player-to-player edges visibility
      Object.values(edges).forEach((edge) => {
        const isGameEdge =
          edge.source.startsWith("game-") || edge.target.startsWith("game-");
        if (!isGameEdge) {
          const sourceVisible = visiblePlayerSet.has(parseInt(edge.source, 10));
          const targetVisible = visiblePlayerSet.has(parseInt(edge.target, 10));
          get().updateEdge(edge.id, {
            hidden: !(sourceVisible && targetVisible),
          });
        }
      });

      debugLog("Node visibility update complete");
    } catch (error) {
      debugLog("Error updating node visibility:", error);
      // Make all nodes visible in case of error
      Object.keys(nodes).forEach((nodeId) => {
        if (!nodeId.startsWith("game-")) {
          get().updateNode(nodeId, { hidden: false });
        }
      });
    }
  },
}));

export default useGraphStore;
