// constants.js
export const matchIdIndex = 0;
export const videoNameIndex = 1;
export const matchDescriptionIndex = 2;
export const stageIndex = 3;
export const dateIndex = 4;
export const locationIndex = 5;
export const homePlayerIndex = 6;
export const awayPlayerIndex = 7;
export const urlIndex = 8;
export const thumbnailIndex = 9;
export const reportIndex = 10;
export const homePlayerIdIndex = 11;
export const awayPlayerIdIndex = 12;
export const ableIndex = 13;
export const intellectualIndex = 14;
export const physicalIndex = 15;
export const parentEventIndex = 16;
export const eventIndex = 17;
export const subEventIndex = 18;
export const categoryIndex = 19;
export const isDoubleIndex = 20;
export const viewCountIndex = 21;
export const uploadUserIndex = 22;
export const customTagIndex = 23;
export const homePlayerMatchScoreIndex = 24;
export const awayPlayerMatchScoreIndex = 25;
export const dpFilenameIndex = 26;

export const roundDataIndex = 0;
export const ballDataIndex = 1;
export const highlightDataIndex = 2;
export const commentsDataIndex = 3;

export const roundIdIndex = 0;
export const roundMatchIdIndex = 1;
export const roundNumberIndex = 2;
export const roundHomePlayerScoreIndex = 3;
export const roundAwayPlayerScoreIndex = 4;
export const roundStartTimeIndex = 5;
export const roundEndTimeIndex = 6;
export const roundDescriptionIndex = 7;
export const roundNumberBallIndex = 8;

export const ballIdIndex = 0;
export const ballRoundIdIndex = 1;
export const ballStartTimeIndex = 2;
export const ballEndTimeIndex = 3;
export const ballHomePlayerScoreIndex = 4;
export const ballAwayPlayerScoreIndex = 5;
export const ballHighlightIndex = 6;
export const criticalPointIndex = 7;
export const deuceIndex = 8;

export const highlightIdIndex = 0;
export const HighlightRoundIdIndex = 1;
export const highlightStartTimeIndex = 2;
export const highlightEndTimeIndex = 3;
export const highlightMatchIdIndex = 4;
export const highlightHomePlayerIdIndex = 5;
export const highlightAwayPlayerIdIndex = 6;
export const highlighthomePlayerIndex = 7;
export const highlightawayPlayerIndex = 8;
export const highlightRatingIndex = 9;
export const highlightCommentIndex = 10;
export const highlightGroupIdIndex = 11;
export const highlightViewCountIndex = 12;
export const highlightDateCreatedIndex = 13;
export const highlightOwnerIndex = 14;

export const transformationIdIndex = 0;
export const transformationHighlightIdIndex = 1;
export const transformationXIndex = 2;
export const transformationYIndex = 3;
export const transformationScaleIndex = 4;
export const transformationStartTimeIndex = 5;
export const transformationEndTimeIndex = 6;

export const commentIdIndex = 0;
export const commentBallIdIndex = 1;
export const commentRatingIndex = 2;
export const ballCommentIndex = 3;
export const commentOwnerIndex = 4;
export const commentDateIndex = 5;

export const lastWttPlayerId = 206909;

export const countryCodeMapping = {
  AFG: 'AF',
  ALA: 'AX',
  ALB: 'AL',
  ALG: 'DZ',
  ASM: 'AS',
  AND: 'AD',
  ANG: 'AO',
  AIA: 'AI',
  ATA: 'AQ',
  ATG: 'AG',
  ARG: 'AR',
  ARM: 'AM',
  ABW: 'AW',
  AUS: 'AU',
  AUT: 'AT',
  AZE: 'AZ',
  BHS: 'BS',
  BHR: 'BH',
  BGD: 'BD',
  BRB: 'BB',
  BLR: 'BY',
  BEL: 'BE',
  BLZ: 'BZ',
  BEN: 'BJ',
  BMU: 'BM',
  BTN: 'BT',
  BOL: 'BO',
  BES: 'BQ',
  BIH: 'BA',
  BOT: 'BW',
  BVT: 'BV',
  BRA: 'BR',
  IOT: 'IO',
  BRN: 'BN',
  BGR: 'BG',
  BFA: 'BF',
  BDI: 'BI',
  CPV: 'CV',
  KHM: 'KH',
  CMR: 'CM',
  CAN: 'CA',
  CYM: 'KY',
  CAF: 'CF',
  TCD: 'TD',
  CIV: 'CI',
  CHI: 'CL',
  CHN: 'CN',
  CXR: 'CX',
  CCK: 'CC',
  COL: 'CO',
  COM: 'KM',
  CGO: 'CG',
  COD: 'CD',
  COK: 'CK',
  CRI: 'CR',
  CRO: 'HR',
  CUB: 'CU',
  CUW: 'CW',
  CYP: 'CY',
  CZE: 'CZ',
  DEN: 'DK',
  DJI: 'DJ',
  DMA: 'DM',
  DOM: 'DO',
  ECU: 'EC',
  ENG: 'GB',
  EGY: 'EG',
  SLV: 'SV',
  GNQ: 'GQ',
  ERI: 'ER',
  EST: 'EE',
  SWZ: 'SZ',
  ETH: 'ET',
  FLK: 'FK',
  FRO: 'FO',
  FIJ: 'FJ',
  FIN: 'FI',
  FRA: 'FR',
  GUF: 'GF',
  PYF: 'PF',
  ATF: 'TF',
  GAB: 'GA',
  GMB: 'GM',
  GEO: 'GE',
  GER: 'DE',
  GHA: 'GH',
  GIB: 'GI',
  GRE: 'GR',
  GRL: 'GL',
  GRD: 'GD',
  GLP: 'GP',
  GUM: 'GU',
  GUA: 'GT',
  GGY: 'GG',
  GIN: 'GN',
  GNB: 'GW',
  GUY: 'GY',
  HTI: 'HT',
  HMD: 'HM',
  VAT: 'VA',
  HND: 'HN',
  HKG: 'HK',
  HUN: 'HU',
  ISL: 'IS',
  IND: 'IN',
  IDN: 'ID',
  IRI: 'IR',
  IRQ: 'IQ',
  IRL: 'IE',
  IMN: 'IM',
  ISR: 'IL',
  ITA: 'IT',
  JAM: 'JM',
  JPN: 'JP',
  JEY: 'JE',
  JOR: 'JO',
  KAZ: 'KZ',
  KEN: 'KE',
  KIR: 'KI',
  PRK: 'KP',
  KOR: 'KR',
  KWT: 'KW',
  KGZ: 'KG',
  LAO: 'LA',
  LVA: 'LV',
  LBN: 'LB',
  LSO: 'LS',
  LBR: 'LR',
  LBY: 'LY',
  LIE: 'LI',
  LTU: 'LT',
  LUX: 'LU',
  MAC: 'MO',
  MKD: 'MK',
  MAD: 'MG',
  MWI: 'MW',
  MAS: 'MY',
  MDV: 'MV',
  MLI: 'ML',
  MLT: 'MT',
  MHL: 'MH',
  MTQ: 'MQ',
  MRT: 'MR',
  MRI: 'MU',
  MYT: 'YT',
  MEX: 'MX',
  FSM: 'FM',
  MDA: 'MD',
  MON: 'MC',
  MNG: 'MN',
  MNE: 'ME',
  MSR: 'MS',
  MAR: 'MA',
  MOZ: 'MZ',
  MMR: 'MM',
  NAM: 'NA',
  NRU: 'NR',
  NPL: 'NP',
  NLD: 'NL',
  NCL: 'NC',
  NZL: 'NZ',
  NIC: 'NI',
  NER: 'NE',
  NGR: 'NG',
  NIU: 'NU',
  NFK: 'NF',
  MNP: 'MP',
  NOR: 'NO',
  OMN: 'OM',
  PAK: 'PK',
  PLW: 'PW',
  PSE: 'PS',
  PAN: 'PA',
  PNG: 'PG',
  PAR: 'PY',
  PER: 'PE',
  PHI: 'PH',
  PCN: 'PN',
  POL: 'PL',
  POR: 'PT',
  PUR: 'PR',
  QAT: 'QA',
  REU: 'RE',
  ROU: 'RO',
  RUS: 'RU',
  RWA: 'RW',
  BLM: 'BL',
  SHN: 'SH',
  KNA: 'KN',
  LCA: 'LC',
  MAF: 'MF',
  SPM: 'PM',
  VCT: 'VC',
  WSM: 'WS',
  SMR: 'SM',
  STP: 'ST',
  KSA: 'SA',
  SEN: 'SN',
  SRB: 'RS',
  SYC: 'SC',
  SLE: 'SL',
  SGP: 'SG',
  SXM: 'SX',
  SVK: 'SK',
  SLO: 'SI',
  SLB: 'SB',
  SOM: 'SO',
  RSA: 'ZA',
  SGS: 'GS',
  SSD: 'SS',
  ESP: 'ES',
  LKA: 'LK',
  SDN: 'SD',
  SUR: 'SR',
  SJM: 'SJ',
  SWE: 'SE',
  SUI: 'CH',
  SYR: 'SY',
  TPE: 'TPE',
  TJK: 'TJ',
  TZA: 'TZ',
  THA: 'TH',
  TLS: 'TL',
  TOG: 'TG',
  TKL: 'TK',
  TON: 'TO',
  TTO: 'TT',
  TUN: 'TN',
  TUR: 'TR',
  TKM: 'TM',
  TCA: 'TC',
  TUV: 'TV',
  UGA: 'UG',
  UKR: 'UA',
  ARE: 'AE',
  GBR: 'GB',
  USA: 'US',
  UMI: 'UM',
  URY: 'UY',
  UZB: 'UZ',
  VUT: 'VU',
  VEN: 'VE',
  VNM: 'VN',
  VGB: 'VG',
  VIR: 'VI',
  WLF: 'WF',
  ESH: 'EH',
  YEM: 'YE',
  ZMB: 'ZM',
  ZWE: 'ZW',
  WAL: 'GB', // added gb to wales
  // Additional mappings where 3-character code is the same as the 2-character code
  AU: 'AU',
  AX: 'AX',
  AL: 'AL',
  DZ: 'DZ',
  AS: 'AS',
  AD: 'AD',
  AO: 'AO',
  AI: 'AI',
  AQ: 'AQ',
  AG: 'AG',
  AR: 'AR',
  AM: 'AM',
  AW: 'AW',
  AT: 'AT',
  AZ: 'AZ',
  BS: 'BS',
  BH: 'BH',
  BD: 'BD',
  BB: 'BB',
  BY: 'BY',
  BE: 'BE',
  BZ: 'BZ',
  BJ: 'BJ',
  BM: 'BM',
  BT: 'BT',
  BO: 'BO',
  BQ: 'BQ',
  BA: 'BA',
  BW: 'BW',
  BV: 'BV',
  BR: 'BR',
  IO: 'IO',
  BN: 'BN',
  BG: 'BG',
  BF: 'BF',
  BI: 'BI',
  CV: 'CV',
  KH: 'KH',
  CM: 'CM',
  CA: 'CA',
  KY: 'KY',
  CF: 'CF',
  TD: 'TD',
  CI: 'CI',
  CL: 'CL',
  CN: 'CN',
  CX: 'CX',
  CC: 'CC',
  CO: 'CO',
  KM: 'KM',
  CG: 'CG',
  CD: 'CD',
  CK: 'CK',
  CR: 'CR',
  HR: 'HR',
  CU: 'CU',
  CW: 'CW',
  CY: 'CY',
  CZ: 'CZ',
  DK: 'DK',
  DJ: 'DJ',
  DM: 'DM',
  DO: 'DO',
  EC: 'EC',
  GB: 'GB',
  EG: 'EG',
  SV: 'SV',
  GQ: 'GQ',
  ER: 'ER',
  EE: 'EE',
  SZ: 'SZ',
  ET: 'ET',
  FK: 'FK',
  FO: 'FO',
  FJ: 'FJ',
  FI: 'FI',
  FR: 'FR',
  GF: 'GF',
  PF: 'PF',
  TF: 'TF',
  GA: 'GA',
  GM: 'GM',
  GE: 'GE',
  DE: 'DE',
  GH: 'GH',
  GI: 'GI',
  GR: 'GR',
  GL: 'GL',
  GD: 'GD',
  GP: 'GP',
  GU: 'GU',
  GT: 'GT',
  GG: 'GG',
  GN: 'GN',
  GW: 'GW',
  GY: 'GY',
  HT: 'HT',
  HM: 'HM',
  VA: 'VA',
  HN: 'HN',
  HK: 'HK',
  HU: 'HU',
  IS: 'IS',
  IN: 'IN',
  ID: 'ID',
  IR: 'IR',
  IQ: 'IQ',
  IE: 'IE',
  IM: 'IM',
  IL: 'IL',
  IT: 'IT',
  JM: 'JM',
  JP: 'JP',
  JE: 'JE',
  JO: 'JO',
  KZ: 'KZ',
  KE: 'KE',
  KI: 'KI',
  KP: 'KP',
  KR: 'KR',
  KW: 'KW',
  KG: 'KG',
  LA: 'LA',
  LV: 'LV',
  LB: 'LB',
  LS: 'LS',
  LR: 'LR',
  LY: 'LY',
  LI: 'LI',
  LT: 'LT',
  LU: 'LU',
  MO: 'MO',
  MK: 'MK',
  MG: 'MG',
  MW: 'MW',
  MY: 'MY',
  MV: 'MV',
  ML: 'ML',
  MT: 'MT',
  MH: 'MH',
  MQ: 'MQ',
  MR: 'MR',
  MU: 'MU',
  YT: 'YT',
  MX: 'MX',
  FM: 'FM',
  MD: 'MD',
  MC: 'MC',
  MN: 'MN',
  ME: 'ME',
  MS: 'MS',
  MA: 'MA',
  MZ: 'MZ',
  MM: 'MM',
  NA: 'NA',
  NR: 'NR',
  NP: 'NP',
  NL: 'NL',
  NC: 'NC',
  NZ: 'NZ',
  NI: 'NI',
  NE: 'NE',
  NG: 'NG',
  NU: 'NU',
  NF: 'NF',
  MP: 'MP',
  NO: 'NO',
  OM: 'OM',
  PK: 'PK',
  PW: 'PW',
  PS: 'PS',
  PA: 'PA',
  PG: 'PG',
  PY: 'PY',
  PE: 'PE',
  PH: 'PH',
  PN: 'PN',
  PL: 'PL',
  PT: 'PT',
  PR: 'PR',
  QA: 'QA',
  RE: 'RE',
  RO: 'RO',
  RU: 'RU',
  RW: 'RW',
  BL: 'BL',
  SH: 'SH',
  KN: 'KN',
  LC: 'LC',
  MF: 'MF',
  PM: 'PM',
  VC: 'VC',
  WS: 'WS',
  SM: 'SM',
  ST: 'ST',
  SA: 'SA',
  SN: 'SN',
  RS: 'RS',
  SC: 'SC',
  SL: 'SL',
  SG: 'SG',
  SX: 'SX',
  SK: 'SK',
  SI: 'SI',
  SB: 'SB',
  SO: 'SO',
  ZA: 'ZA',
  GS: 'GS',
  SS: 'SS',
  ES: 'ES',
  LK: 'LK',
  SD: 'SD',
  SR: 'SR',
  SJ: 'SJ',
  SE: 'SE',
  CH: 'CH',
  SY: 'SY',
  TPE: 'TPE',
  TJ: 'TJ',
  TZ: 'TZ',
  TH: 'TH',
  TL: 'TL',
  TG: 'TG',
  TK: 'TK',
  TO: 'TO',
  TT: 'TT',
  TN: 'TN',
  TR: 'TR',
  TM: 'TM',
  TC: 'TC',
  TV: 'TV',
  UG: 'UG',
  UA: 'UA',
  AE: 'AE',
  GB: 'GB',
  US: 'US',
  UM: 'UM',
  UY: 'UY',
  UZ: 'UZ',
  VU: 'VU',
  VE: 'VE',
  VN: 'VN',
  VG: 'VG',
  VI: 'VI',
  WF: 'WF',
  EH: 'EH',
  YE: 'YE',
  ZM: 'ZM',
  ZW: 'ZW',
  GB: 'GB',
  AU: 'AU',
  BR: 'BR',
  CA: 'CA',
  CN: 'CN',
  FR: 'FR',
  DE: 'DE',
  IN: 'IN',
  JP: 'JP',
  RU: 'RU',
  GB: 'GB',
  ES: 'ES',
  IT: 'IT',
  KR: 'KR',
  MX: 'MX',
  NL: 'NL',
  SA: 'SA',
  TR: 'TR',
};

export const interestedCountries = [
  'Hong Kong, China',
  'China',
  'Chinese Taipei',
  'Japan',
  'Korea DPR',
  'Korea Republic',
  'France',
  'Germany',
  'Sweden',
  'Brazil',
  'Denmark',
  'Spain',
  'India',
];

export const countries = [
  'No Data',
  'Algeria',
  'Angola',
  'Argentina',
  'Australia',
  'Austria',
  'Belgium',
  'Botswana',
  'Brazil',
  'Cameroon',
  'Canada',
  'Chile',
  'China',
  'Chinese Taipei',
  'Colombia',
  'Congo Brazzaville',
  'Congo Democratic',
  'Croatia',
  'Cuba',
  'Czechia',
  'Denmark',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'England',
  'Ethiopia',
  'Fiji',
  'Finland',
  'France',
  'Germany',
  'Greece',
  'Guatemala',
  'Hong Kong, China',
  'Hungary',
  'India',
  'Iran',
  'Italy',
  'Japan',
  'Kazakhstan',
  'Korea DPR',
  'Korea Republic',
  'Lebanon',
  'Luxembourg',
  'Madagascar',
  'Malaysia',
  'Malta',
  'Mauritius',
  'Mexico',
  'Moldova',
  'Monaco',
  'Morocco',
  'Netherlands',
  'New Caledonia',
  'New Zealand',
  'Nigeria',
  'Norway',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Romania',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Singapore',
  'Slovak Republic',
  'Slovenia',
  'South Africa',
  'Spain',
  'Sweden',
  'Switzerland',
  'Tahiti',
  'Thailand',
  'Togo',
  'Tunisia',
  'Türkiye',
  'Ukraine',
  'USA',
  'Uzbekistan',
  'Venezuela',
  'Wales',
];

export const countryToCodeMapping = {
  'No Data': '',
  Algeria: 'DZ',
  Angola: 'AO',
  Argentina: 'AR',
  Australia: 'AU',
  Austria: 'AT',
  Belgium: 'BE',
  Botswana: 'BW',
  Brazil: 'BR',
  Cameroon: 'CM',
  Canada: 'CA',
  Chile: 'CL',
  China: 'CN',
  'Chinese Taipei': 'TPE',
  Colombia: 'CO',
  'Congo Brazzaville': 'CG',
  'Congo Democratic': 'CD',
  Croatia: 'HR',
  Cuba: 'CU',
  Czechia: 'CZ',
  Denmark: 'DK',
  'Dominican Republic': 'DO',
  Ecuador: 'EC',
  Egypt: 'EG',
  England: 'GB',
  Ethiopia: 'ET',
  Fiji: 'FJ',
  Finland: 'FI',
  France: 'FR',
  Germany: 'DE',
  Greece: 'GR',
  Guatemala: 'GT',
  'Hong Kong, China': 'HK',
  Hungary: 'HU',
  India: 'IN',
  Iran: 'IR',
  Italy: 'IT',
  Japan: 'JP',
  Kazakhstan: 'KZ',
  'Korea DPR': 'KP',
  'Korea Republic': 'KR',
  Lebanon: 'LB',
  Luxembourg: 'LU',
  Madagascar: 'MG',
  Malaysia: 'MY',
  Malta: 'MT',
  Mauritius: 'MU',
  Mexico: 'MX',
  Moldova: 'MD',
  Monaco: 'MC',
  Morocco: 'MA',
  Netherlands: 'NL',
  'New Caledonia': 'NC',
  'New Zealand': 'NZ',
  Nigeria: 'NG',
  Norway: 'NO',
  'Papua New Guinea': 'PG',
  Paraguay: 'PY',
  Peru: 'PE',
  Philippines: 'PH',
  Poland: 'PL',
  Portugal: 'PT',
  'Puerto Rico': 'PR',
  Romania: 'RO',
  'Saudi Arabia': 'SA',
  Senegal: 'SN',
  Serbia: 'RS',
  Singapore: 'SG',
  'Slovak Republic': 'SK',
  Slovenia: 'SI',
  'South Africa': 'ZA',
  Spain: 'ES',
  Sweden: 'SE',
  Switzerland: 'CH',
  Tahiti: 'PF',
  Thailand: 'TH',
  Togo: 'TG',
  Tunisia: 'TN',
  Türkiye: 'TR',
  Ukraine: 'UA',
  USA: 'US',
  Uzbekistan: 'UZ',
  Venezuela: 'VE',
  Wales: 'GB',
};
