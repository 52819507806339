import React, { useState } from 'react';
import {
    Box,
    Card,
    Typography,
    IconButton,
    Menu,
    MenuItem,
    Stack,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Alert,
    Snackbar,
    useTheme
} from '@mui/material';
import { styled } from '@mui/material/styles';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Link as RouterLink } from 'react-router-dom';
import PlaylistPlayIcon from '@mui/icons-material/PlaylistPlay';
import { Playlist } from 'src/types/playlist';

import './PlaylistCard.scss';

// Types
interface PlaylistCardProps {
    playlist: Playlist;
    onDelete: (playlistId: number) => Promise<void>;
    onEdit: (playlist: Playlist | undefined) => void;  // Changed to match your handler
}

interface ErrorState {
    open: boolean;
    message: string;
}

const StyledCard = styled(Card)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: theme.palette.background.paper,
    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
        transform: 'translateY(-4px)',
        '& .thumbnail-overlay': {
            opacity: 1,
        }
    },
}));

const ThumbnailWrapper = styled(Box)(({ theme }) => ({
    position: 'relative',
    aspectRatio: '16/9',
    backgroundColor: '#000',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const ThumbnailOverlay = styled(Box)({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0,
    transition: 'opacity 0.2s ease-in-out',
    zIndex: 999,
});


const PlaylistCount = styled(Box)({
    position: 'absolute',
    bottom: 0,
    right: 0,
    margin: '8px',
    color: '#fff',
    backgroundColor: 'rgba(0, 0, 0, 0.6)',
    borderRadius: '4px',
    padding: '1px 4px',
    fontSize: '1.2rem',
    lineHeight: '1.8rem',
    fontWeight: '500',
    fontFamily: 'Roboto, Arial, sans-serif',
    zIndex: 999,
});

const ContentWrapper = styled(Box)(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(1.5),
    flexGrow: 1,
    boxSizing: 'border-box',
    overflow: 'hidden'
}));

const TruncatedTypography = styled(Typography)({
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
});


const PlaylistCard: React.FC<PlaylistCardProps> = ({
    playlist,
    onDelete,
    onEdit,
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false);
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const [error, setError] = useState<ErrorState>({ open: false, message: '' });

    const firstVideoLink = `/video-player/${playlist.match_order[0]}`;

    const TNUrl = playlist.playlist_thumbnail_url
        ? `${process.env.PUBLIC_URL}/videos/${playlist.playlist_thumbnail_url.substring(
            0,
            playlist.playlist_thumbnail_url.lastIndexOf('.')
        )}/${playlist.playlist_thumbnail_url}`
        : null;


    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleDeleteClick = () => {
        handleClose();
        setDeleteDialogOpen(true);
    };

    const handleDeleteConfirm = async () => {
        try {
            setIsDeleting(true);
            await onDelete(playlist.playlist_id);
            setDeleteDialogOpen(false);
        } catch (error) {
            setError({
                open: true,
                message: error instanceof Error ? error.message : 'An error occurred while deleting the playlist'
            });
        } finally {
            setIsDeleting(false);
        }
    };

    const handleErrorClose = () => {
        setError({ ...error, open: false });
    };

    return (
        <div className='playlists-container'>
            <StyledCard elevation={0} className='playlist-card-container'>
                <RouterLink className='thumbnail-wrapper' to={firstVideoLink} style={{ textDecoration: 'none' }}>

                    <ThumbnailWrapper >
                        <div className='thumbnail-stack-first'>
                        </div>
                        <div className='thumbnail-stack-second'>
                        </div>
                        {TNUrl ? (
                            <>
                                <Box
                                    component="img"
                                    src={TNUrl}
                                    sx={{
                                        width: '100%',
                                        zIndex: 99,
                                        borderRadius: '8px'
                                    }}
                                />
                            </>
                        ) : (
                            <Box
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    backgroundColor: '#282828',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    zIndex: 99,
                                    borderRadius: '8px'
                                }}
                            >
                                <PlaylistPlayIcon sx={{ fontSize: 40, color: '#fff' }} />
                            </Box>
                        )}
                        <ThumbnailOverlay className="thumbnail-overlay">
                            <PlaylistPlayIcon sx={{ fontSize: 40, color: '#fff' }} />
                        </ThumbnailOverlay>
                        <PlaylistCount>
                            {playlist.match_order.length} 影片
                        </PlaylistCount>
                    </ThumbnailWrapper>

                </RouterLink>
                <ContentWrapper className='playlist-card-content-wrapper'>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={1}
                        sx={{ height: '100%' }}
                    >
                        <Box className="content-box">
                            <RouterLink to={firstVideoLink} className="playlist-link">
                                <TruncatedTypography
                                    variant="subtitle1"
                                    className="playlist-title"
                                >
                                    {playlist.playlist_name}
                                </TruncatedTypography>
                            </RouterLink>

                            <TruncatedTypography
                                variant="body2"
                                className="playlist-description"
                            >
                                {playlist.description}
                            </TruncatedTypography>

                            <Box className="metadata-container">
                                <Typography
                                    variant="caption"
                                    className="date-created"
                                >
                                    {new Date(playlist.date_created).toLocaleDateString()}
                                </Typography>

                                <Box className="tags-container">
                                    {playlist.custom_tags.map((tag, index) => (
                                        <Typography
                                            key={index}
                                            component="span"
                                            variant="caption"
                                            className="tag"
                                        >
                                            {tag}
                                        </Typography>
                                    ))}
                                </Box>
                            </Box>
                        </Box>

                        <IconButton
                            size="small"
                            onClick={handleClick}
                            sx={{ ml: 'auto', flexShrink: 0, color: 'white' }}
                        >
                            <MoreVertIcon />
                        </IconButton>
                    </Stack>
                </ContentWrapper>
            </StyledCard>

            <Menu
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem
                    onClick={() => {
                        handleClose();
                        onEdit(playlist);
                    }}
                >
                    Edit
                </MenuItem>
                <MenuItem
                    onClick={handleDeleteClick}
                    sx={{ color: 'error.main' }}
                >
                    Delete
                </MenuItem>
            </Menu>

            <Dialog
                open={deleteDialogOpen}
                onClose={() => !isDeleting && setDeleteDialogOpen(false)}
            >
                <DialogTitle>確認刪除</DialogTitle>
                <DialogContent>
                    <Typography>
                        確定要刪除「{playlist.playlist_name}」這個播放清單嗎？
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => setDeleteDialogOpen(false)}
                        disabled={isDeleting}
                    >
                        取消
                    </Button>
                    <Button
                        onClick={handleDeleteConfirm}
                        color="error"
                        disabled={isDeleting}
                        variant="contained"
                    >
                        {isDeleting ? '刪除中...' : '刪除'}
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={error.open}
                autoHideDuration={6000}
                onClose={handleErrorClose}
            >
                <Alert
                    onClose={handleErrorClose}
                    severity="error"
                    sx={{ width: '100%' }}
                >
                    {error.message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default PlaylistCard;