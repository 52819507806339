import { FC, useEffect } from "react";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import useStore from "../../store/store";

interface HeaderControlsProps {
  isMobile: boolean;
}

export const HeaderControls: FC<HeaderControlsProps> = ({ isMobile }) => {
  const { t, i18n } = useTranslation();

  // Get store values and actions
  const categories = useStore((state) => state.categories);
  const currentCategory = useStore((state) => state.currentCategory);
  const { fetchCategories, setCurrentCategory } = useStore();

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        alignItems: "center",
        flexWrap: isMobile ? "wrap" : "nowrap",
        width: "100%",
        justifyContent: "space-between",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: 2,
          alignItems: "center",
          flex: 1,
        }}
      >
        <FormControl
          sx={{
            width: isMobile ? "50%" : 200,
            "& .MuiInputBase-root": {
              fontSize: isMobile ? "0.875rem" : "inherit",
            },
          }}
          size="small"
        >
          <InputLabel
            id="category-select-label"
            sx={{
              fontSize: isMobile ? "0.875rem" : "inherit",
            }}
          >
            {t("common.gameMode")}
          </InputLabel>
          <Select
            labelId="category-select-label"
            id="category-select"
            value={currentCategory || ""}
            label={t("common.gameMode")}
            onChange={(e) => setCurrentCategory(e.target.value)}
          >
            {categories.map((category) => (
              <MenuItem
                key={category}
                value={category}
                sx={{
                  fontSize: isMobile ? "0.875rem" : "inherit",
                  py: isMobile ? 0.75 : 1,
                }}
              >
                {t(`gameModes.${category}`)}{" "}
                {/* Just show the category name directly */}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Button
          variant="outlined"
          size="small"
          href="/player-graph"
          sx={{
            whiteSpace: "nowrap",
            minWidth: "fit-content",
            fontSize: isMobile ? "0.875rem" : "inherit",
          }}
        >
          {t("common.viewOldVersion")}
        </Button>
      </Box>

      <Button
        size="small"
        onClick={() =>
          i18n.changeLanguage(i18n.language === "en" ? "zh_hk" : "en")
        }
        sx={{
          minWidth: "fit-content",
          fontSize: isMobile ? "0.875rem" : "inherit",
        }}
      >
        {i18n.language === "en" ? "中文" : "EN"}
      </Button>
    </Box>
  );
};

export default HeaderControls;
