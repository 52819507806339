import { useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { TransformWrapper, TransformComponent } from 'react-zoom-pan-pinch';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import PauseIcon from '@mui/icons-material/Pause';
import Forward5Icon from '@mui/icons-material/Forward5';
import Replay5Icon from '@mui/icons-material/Replay5';
import FastRewindIcon from '@mui/icons-material/FastRewind';
import FastForwardIcon from '@mui/icons-material/FastForward';
import SkipNextIcon from '@mui/icons-material/SkipNext';
import SkipPreviousIcon from '@mui/icons-material/SkipPrevious';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import EnhancedVideoPlayer2 from './ImprovedVP2';

import Button from '@mui/material/Button';
import { IconButton } from '@mui/material';

import VideoControlPanel from 'src/components/VideoControlPanel/VideoControlPanel';
import VideoControlBar from 'src/components/VideoControlPanel/VideoControlBar';
import {
  handlePlayPause,
  handleSeekBackward,
  handleSeekForward,
  handleSeekBackwardFrame,
  handleSeekForwardFrame,
  handleSeekBackwardBall,
  handleSeekForwardBall,
} from 'src/utils/videoControl';

import './VideoPlayer.scss';

import {
  roundDataIndex,
  roundStartTimeIndex,
  roundEndTimeIndex,
  highlightDataIndex,
  highlightStartTimeIndex,
  highlightEndTimeIndex,
  transformationXIndex,
  transformationYIndex,
  transformationScaleIndex,
  ballStartTimeIndex,
  ballEndTimeIndex,
  ballDataIndex,
  deuceIndex,
  criticalPointIndex,
  ballHomePlayerScoreIndex,
  ballAwayPlayerScoreIndex,
} from 'src/constants';

const tabs = [
  'settingPanel',
  'infoPanel',
  'scorePanel',
  'analysisPanel',
  'highlightPanel',
  'playlistPanel',
];

const VideoPlayer = ({
  source,
  videoRef,
  roundResults,
  replayData,
  commentaryTimes,
  videoData,
  setVideoData,
  userInfo,
  currentTransformation,
  setCurrentTransformation,
  matchData,
  isMobileBrowser,
}) => {
  const [showConversionBlock, setShowConversionBlock] = useState(false);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [transformation, setTransformation] = useState({
    positionX: 0,
    positionY: 0,
    scale: 1,
  });
  const [scale, setScale] = useState(1);
  const [videoStatus, setVideoStatus] = useState({
    currentBallStartTime: 0,
    currentBallEndTime: 0,
    currentHLStartTime: 0,
    currentHLEndTime: 0,
    currentBallPlayedTime: 1,
    currentHighlightPlayedTime: 1,
    isHighlight: false,
  });
  const [roundHighlight, setRoundHighlight] = useState([]);
  const [highlightNotice, setHighlightNotice] = useState(true);
  const [dpOL, setDpOL] = useState(false);
  const [duration, setDuration] = useState(0);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showControls, setShowControls] = useState(true);

  const isIOS =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

  const zoomPanRef = useRef(null);
  const containerRef = useRef(null);

  const onLoadComplete = () => {
    setIsVideoLoaded(true);
    handleVideoLoadedMetadata();
  };

  const handleTransform = () => {
    if (videoRef.current && zoomPanRef.current) {
      const { setTransform } = zoomPanRef.current;
      // Use setTransform function to set the transformation
      const xValue = transformation.positionX * videoRef.current.clientWidth;
      const yValue = transformation.positionY * videoRef.current.clientHeight;
      const scaleValue = transformation.scale;
      const animationTime = 1;
      const animationName = 'easeOut';

      setScale(scaleValue);

      setTransform(xValue, yValue, scaleValue, animationTime, animationName);
    }
  };

  const handleVideoLoadedMetadata = () => {
    setIsVideoLoaded(true);
  };

  // keep track of video time
  const handleTimeUpdate = () => {
    setCurrentTime(videoRef.current.currentTime);
    handleTimeBarUpdate();
  };

  // get value for progress bar
  const handleTimeBarUpdate = () => {
    if (videoRef.current) {
      const { currentTime, duration } = videoRef.current;
      const progressBar = document.getElementById('progress-bar');
      if (progressBar) {
        progressBar.value = (currentTime / duration) * 100;
      }
    }
  };

  const toggleFullscreen = () => {
    if (!videoRef.current) return;

    if (!checkFullscreen()) {
      if (isIOS) {
        requestFullscreen(videoRef.current);
      } else {
        requestFullscreen(containerRef.current);
      }
      setVideoData((prevState) => ({
        ...prevState,
        isFs: true,
      }));
    } else {
      exitFullscreen();
      setVideoData((prevState) => ({
        ...prevState,
        isFs: false,
      }));
    }
  };

  const checkFullscreen = () => {
    return !!(
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement ||
      (videoRef.current && videoRef.current.webkitDisplayingFullscreen)
    );
  };

  const requestFullscreen = (element) => {
    if (isIOS && videoRef.current) {
      videoRef.current.webkitEnterFullScreen();
    } else if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
  };

  const exitFullscreen = () => {
    if (isIOS && videoRef.current) {
      videoRef.current.webkitExitFullscreen();
    } else if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  };

  const renderOverlay = () => {
    return (
      <div className="button-overlay">
        {userInfo && userInfo.accountType !== 'm' && (
          <>
            <IconButton
              className="overlay-button"
              title="上一个球"
              onClick={() =>
                handleSeekBackwardBall(
                  videoRef,
                  commentaryTimes,
                  roundResults,
                  videoData.currentRound,
                  videoData.currentBall,
                  setVideoData,
                )
              }
            >
              <SkipPreviousIcon />
            </IconButton>
            <IconButton
              className="overlay-button"
              title="按帧快退"
              onClick={() => handleSeekBackwardFrame(videoRef, setVideoData)}
            >
              <FastRewindIcon />
            </IconButton>
          </>
        )}
        <IconButton
          className="overlay-button"
          title="退5秒"
          onClick={() => handleSeekBackward(videoRef, setVideoData)}
        >
          <Replay5Icon />
        </IconButton>
        <IconButton
          className="overlay-button"
          onClick={() => handlePlayPause(videoRef, videoData, setVideoData)}
        >
          {videoData.isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
        </IconButton>
        <IconButton
          className="overlay-button"
          title="进5秒"
          onClick={() => handleSeekForward(videoRef, setVideoData)}
        >
          <Forward5Icon />
        </IconButton>
        {userInfo && userInfo.accountType !== 'm' && (
          <>
            <IconButton
              className="overlay-button"
              title="按帧快进"
              onClick={() => handleSeekForwardFrame(videoRef, setVideoData)}
            >
              <FastForwardIcon />
            </IconButton>
            <IconButton
              className="overlay-button"
              title="下一个球"
              onClick={() =>
                handleSeekForwardBall(
                  videoRef,
                  commentaryTimes,
                  roundResults,
                  videoData.currentRound,
                  videoData.currentBall,
                  setVideoData,
                )
              }
            >
              <SkipNextIcon />
            </IconButton>
          </>
        )}
      </div>
    );
  };

  const renderVideoControl = () => {
    return (
      <>
        <VideoControlPanel
          videoRef={videoRef}
          roundResults={roundResults}
          videoData={videoData}
          setVideoData={setVideoData}
          commentaryTimes={commentaryTimes}
          setScale={setScale}
          userInfo={userInfo}
          matchData={matchData}
          currentTransformation={currentTransformation}
          onZoomChange={(newScale) => {
            if (zoomPanRef.current) {
              zoomPanRef.current.setTransform(0, 0, newScale);
            }
          }}
          setDpOL={setDpOL}
          dpOL={dpOL}
        />
      </>
    );
  };

  const renderVideoBar = () => {
    return (
      <>
        <VideoControlBar
          videoRef={videoRef}
          videoData={videoData}
          setVideoData={setVideoData}
          duration={duration}
          currentTime={currentTime}
          setCurrentTime={setCurrentTime}
          isFullscreen={isFullscreen}
          showControls={showControls}
          roundResults={roundResults}
          handlePlayPause={handlePlayPause}
          handleSeekForward={handleSeekForward}
          handleSeekBackward={handleSeekBackward}
          handleSeekForwardFrame={handleSeekForwardFrame}
          handleSeekBackwardFrame={handleSeekBackwardFrame}
          toggleFullscreen={toggleFullscreen}
          showPreview={!isIOS && !isMobileBrowser}
        />
      </>
    );
  };

  const renderHighlightNotice = () => {
    const handleDisablePov = () => {
      setVideoData((prevState) => ({
        ...prevState,
        isCoachPOV: false,
      }));
      setHighlightNotice(false);
    };

    const shouldRender = () => {
      for (const timePair of roundHighlight) {
        const startTime = timePair[0];
        const timeDiff = (startTime - currentTime).toFixed(1);

        if (timeDiff > 0 && timeDiff < 10) {
          return (
            <Snackbar
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
              open={highlightNotice}
            >
              <Alert
                severity="success"
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                {timeDiff}秒後，教練亮點。
                <Button onClick={handleDisablePov}>停用教練亮點</Button>
              </Alert>
            </Snackbar>
          );
        }
      }

      return null;
    };

    return shouldRender();
  };

  useEffect(() => {
    let interval;
    const FIVE_MINUTES_IN_MS = 5 * 60 * 1000; // 5 minutes in milliseconds
    const timer = setTimeout(() => {
      if (!isVideoLoaded) {
        setShowConversionBlock(true);
      }
    }, FIVE_MINUTES_IN_MS);

    if (isVideoLoaded) {
      interval = setInterval(handleTimeUpdate, 50);
    }

    return () => {
      clearInterval(interval);
      clearTimeout(timer);
    };
  }, [isVideoLoaded]);

  // get current ball
  useEffect(() => {
    let matchedRoundIndex;
    var shouldUpdate = false;
    var shouldLoopBall = false;
    var shouldLoopHL = false;
    const videoUpdateInterval = 0.15;

    const updateRoundHighlightTime = (matchedRoundIndex) => {
      let allRoundHighlight = [];
      if (roundResults[matchedRoundIndex]) {
        for (
          let hIndex = 0;
          hIndex < roundResults[matchedRoundIndex][highlightDataIndex].length;
          hIndex++
        ) {
          const highlightStartTime =
            roundResults[matchedRoundIndex][highlightDataIndex][hIndex][0][
              highlightStartTimeIndex
            ];
          const highlightEndTime =
            roundResults[matchedRoundIndex][highlightDataIndex][hIndex][0][
              highlightEndTimeIndex
            ];
          allRoundHighlight.push([highlightStartTime, highlightEndTime]);
        }
        setRoundHighlight(allRoundHighlight);
      }
    };

    //set video first played
    if (videoData.played === false && currentTime > 0.001) {
      setVideoData((prevData) => ({
        ...prevData,
        played: true,
      }));
    }

    if (
      currentTime > videoStatus.currentBallEndTime ||
      currentTime < videoStatus.currentHLStartTime ||
      currentTime > videoStatus.currentHLEndTime ||
      currentTime < videoStatus.currentBallStartTime
    ) {
      shouldUpdate = true;
    }

    if (
      videoStatus.currentBallEndTime !== 0 &&
      replayData.ballReplayTimes != 1 &&
      videoStatus.currentBallEndTime - videoRef.current.currentTime >= 0 &&
      videoStatus.currentBallEndTime - videoRef.current.currentTime <=
        videoUpdateInterval &&
      videoStatus.isHighlight === false
    ) {
      shouldLoopBall = true;
    }

    if (
      videoStatus.isHighlight === true &&
      replayData.highlightReplayTimes != 1 &&
      videoStatus.currentHLEndTime - videoRef.current.currentTime >= 0 &&
      videoStatus.currentHLEndTime - videoRef.current.currentTime <=
        videoUpdateInterval
    ) {
      shouldLoopHL = true;
    }

    if (videoData.skipped === true) {
      setVideoData((prevState) => ({
        ...prevState,
        skipped: false,
      }));
      setVideoStatus((prevStatus) => ({
        ...prevStatus,
        currentBallPlayedTime: 1,
      }));
    }

    const updateRound = () => {
      return new Promise((resolve, reject) => {
        if (roundResults && roundResults.length > 0) {
          if (
            currentTime <= roundResults[0][roundDataIndex][roundStartTimeIndex]
          ) {
            matchedRoundIndex = 0;
          } else {
            for (
              let roundIndex = 0;
              roundIndex < roundResults.length;
              roundIndex++
            ) {
              const roundStartTime =
                roundResults[roundIndex][roundDataIndex][roundStartTimeIndex];
              const roundEndTime =
                roundResults[roundIndex][roundDataIndex][roundEndTimeIndex];
              if (currentTime >= roundStartTime) {
                matchedRoundIndex = roundIndex;
                if (currentTime <= roundEndTime) {
                  break;
                }
              }
            }
          }
        }

        if (matchedRoundIndex !== -1) {
          resolve(matchedRoundIndex);
        } else {
          reject(new Error('No matching round found.'));
        }
      });
    };

    const updateHighlight = (matchedRoundIndex) => {
      return new Promise((resolve, reject) => {
        if (roundResults[matchedRoundIndex] && matchedRoundIndex !== -1) {
          let matchHighlightIndex = -1;

          for (
            let hIndex = 0;
            hIndex < roundResults[matchedRoundIndex][highlightDataIndex].length;
            hIndex++
          ) {
            const highlightStartTime =
              roundResults[matchedRoundIndex][highlightDataIndex][hIndex][0][
                highlightStartTimeIndex
              ];
            const highlightEndTime =
              roundResults[matchedRoundIndex][highlightDataIndex][hIndex][0][
                highlightEndTimeIndex
              ];
            if (
              currentTime >= highlightStartTime &&
              currentTime <= highlightEndTime
            ) {
              matchHighlightIndex = hIndex;

              const x =
                roundResults[matchedRoundIndex][highlightDataIndex][
                  matchHighlightIndex
                ][1][0][transformationXIndex];
              const y =
                roundResults[matchedRoundIndex][highlightDataIndex][
                  matchHighlightIndex
                ][1][0][transformationYIndex];
              const scale =
                roundResults[matchedRoundIndex][highlightDataIndex][
                  matchHighlightIndex
                ][1][0][transformationScaleIndex];
              setTransformation({ positionX: x, positionY: y, scale: scale });
            }
          }
          if (
            matchHighlightIndex === -1 &&
            !(
              transformation.positionX === 0 &&
              transformation.positionY === 0 &&
              transformation.scale === 1
            )
          ) {
            setTransformation({ positionX: 0, positionY: 0, scale: 1 });
          } else if (
            matchHighlightIndex === -1 &&
            videoStatus.isHighlight === true
          ) {
            setVideoStatus((prevStatus) => ({
              ...prevStatus,
              isHighlight: false,
            }));
          } else if (matchHighlightIndex !== -1) {
            setVideoStatus((prevStatus) => ({
              ...prevStatus,
              isHighlight: true,
              currentHLStartTime:
                roundResults[matchedRoundIndex][highlightDataIndex][
                  matchHighlightIndex
                ][0][highlightStartTimeIndex],
              currentHLEndTime:
                roundResults[matchedRoundIndex][highlightDataIndex][
                  matchHighlightIndex
                ][0][highlightEndTimeIndex],
            }));
          }

          resolve();
        } else {
          reject(new Error('No matching highlight found.'));
        }
      });
    };

    const updateBall = (matchedRoundIndex) => {
      return new Promise((resolve, reject) => {
        const updateRoundBall = () => {
          let matchBallIndex = -1;
          let matchedScore = -1;
          for (
            let ballIndex = 0;
            ballIndex < roundResults[matchedRoundIndex][ballDataIndex].length;
            ballIndex++
          ) {
            const ballStartTime =
              roundResults[matchedRoundIndex][ballDataIndex][ballIndex][
                ballStartTimeIndex
              ];
            const ballEndTime =
              roundResults[matchedRoundIndex][ballDataIndex][ballIndex][
                ballEndTimeIndex
              ];
            if (currentTime >= ballStartTime) {
              matchBallIndex = ballIndex;
              matchedScore =
                roundResults[matchedRoundIndex][ballDataIndex][ballIndex][
                  ballHomePlayerScoreIndex
                ] +
                roundResults[matchedRoundIndex][ballDataIndex][ballIndex][
                  ballAwayPlayerScoreIndex
                ];
              if (currentTime < ballEndTime) {
                break;
              }
            }
          }
          if (videoData.currentRound !== matchedRoundIndex) {
            setVideoData((prevState) => ({
              ...prevState,
              currentRound: matchedRoundIndex,
            }));
          }
          if (videoData.matchedScore !== matchedScore) {
            setVideoData((prevState) => ({
              ...prevState,
              currentTotalScore: matchedScore,
            }));
          }

          if (videoData.currentBall !== matchBallIndex) {
            setVideoData((prevState) => ({
              ...prevState,
              currentBall: matchBallIndex,
            }));

            if (
              roundResults[matchedRoundIndex][ballDataIndex][matchBallIndex]
            ) {
              setVideoStatus((prevStatus) => ({
                ...prevStatus,
                currentBallStartTime:
                  roundResults[matchedRoundIndex][ballDataIndex][
                    matchBallIndex
                  ][ballStartTimeIndex],
                currentBallEndTime:
                  roundResults[matchedRoundIndex][ballDataIndex][
                    matchBallIndex
                  ][ballEndTimeIndex],
              }));
            }
          }
        };

        if (matchedRoundIndex !== -1) {
          if (
            shouldUpdate === true &&
            shouldLoopBall === false &&
            shouldLoopHL === false
          ) {
            updateRoundBall();
          } else {
            if (shouldLoopHL === true) {
              // if user skip to 1s after end or 1s before start or loop times finished
              if (
                videoStatus.currentHighlightPlayedTime ==
                  replayData.highlightReplayTimes &&
                replayData != 1
              ) {
                setVideoStatus((prevStatus) => ({
                  ...prevStatus,
                  currentHighlightPlayedTime: 1,
                }));
                videoRef.current.currentTime =
                  videoStatus.currentHLEndTime + 0.01;
              } else {
                videoRef.current.currentTime =
                  videoStatus.currentHLStartTime + 0.01;
                setVideoStatus((prevStatus) => ({
                  ...prevStatus,
                  currentHighlightPlayedTime:
                    videoStatus.currentHighlightPlayedTime + 1,
                }));
              }
            } else if (shouldLoopBall === true) {
              // check if current ball us deuce or critical point
              const matchedDeuce =
                roundResults[videoData.currentRound]?.[ballDataIndex]?.[
                  videoData.currentBall
                ]?.[deuceIndex] || false;
              const matchedCP =
                roundResults[videoData.currentRound]?.[ballDataIndex]?.[
                  videoData.currentBall
                ]?.[criticalPointIndex] || false;

              if (
                videoStatus.currentBallPlayedTime == replayData.ballReplayTimes
              ) {
                setVideoStatus((prevStatus) => ({
                  ...prevStatus,
                  currentBallPlayedTime: 1,
                }));
                videoRef.current.currentTime =
                  videoStatus.currentBallEndTime + 0.01;
              } else if (
                (replayData.replayDeuce && matchedDeuce) ||
                (replayData.replayCriticalPoint && matchedCP) ||
                (!replayData.replayDeuce && !replayData.replayCriticalPoint)
              ) {
                videoRef.current.currentTime =
                  videoStatus.currentBallStartTime + 0.01;
                setVideoStatus((prevStatus) => ({
                  ...prevStatus,
                  currentBallPlayedTime: videoStatus.currentBallPlayedTime + 1,
                }));
              }
            }
          }
          resolve();
        } else {
          reject(new Error('No matching ball found.'));
        }
      });
    };

    updateRound()
      .then(() => {
        updateRoundHighlightTime(matchedRoundIndex);
        return updateHighlight(matchedRoundIndex);
      })
      .then(() => {
        return updateBall(matchedRoundIndex);
      })
      .catch((error) => {
        console.error('Error:', error);
        // Handle any errors that occurred during the update process
      });
  }, [currentTime]);
  //transform
  useEffect(() => {
    if (videoData.isCoachPOV === true) {
      handleTransform();
    }
  }, [transformation]);
  // init video
  useEffect(() => {
    if (!videoRef.current || !source) return;
    const video = videoRef.current;
    video.src = source;
    video.load();

    const handleCanPlay = () => {
      setDuration(video.duration);
      if (onLoadComplete) onLoadComplete();
    };

    const handleTimeUpdate = () => {
      setCurrentTime(video.currentTime);
    };

    const handleFullscreenChange = () => {
      setIsFullscreen(checkFullscreen());
    };

    video.addEventListener('canplay', handleCanPlay);
    video.addEventListener('timeupdate', handleTimeUpdate);

    if (isIOS) {
      video.addEventListener('webkitbeginfullscreen', () =>
        setIsFullscreen(true),
      );
      video.addEventListener('webkitendfullscreen', () =>
        setIsFullscreen(false),
      );
    } else {
      document.addEventListener('fullscreenchange', handleFullscreenChange);
      document.addEventListener(
        'webkitfullscreenchange',
        handleFullscreenChange,
      );
      document.addEventListener('mozfullscreenchange', handleFullscreenChange);
      document.addEventListener('MSFullscreenChange', handleFullscreenChange);
    }

    return () => {
      video.removeEventListener('canplay', handleCanPlay);
      video.removeEventListener('timeupdate', handleTimeUpdate);

      if (isIOS) {
        video.removeEventListener('webkitbeginfullscreen', () =>
          setIsFullscreen(true),
        );
        video.removeEventListener('webkitendfullscreen', () =>
          setIsFullscreen(false),
        );
      } else {
        document.removeEventListener(
          'fullscreenchange',
          handleFullscreenChange,
        );
        document.removeEventListener(
          'webkitfullscreenchange',
          handleFullscreenChange,
        );
        document.removeEventListener(
          'mozfullscreenchange',
          handleFullscreenChange,
        );
        document.removeEventListener(
          'MSFullscreenChange',
          handleFullscreenChange,
        );
      }
    };
  }, [source]);
  // init transform
  useMemo(() => {
    if (videoData.isCoachPOV === false && zoomPanRef.current) {
      const animationTime = 1;
      const animationName = 'easeOut';
      const { setTransform } = zoomPanRef.current;

      setTransform(0, 0, 1, animationTime, animationName);
    }
  }, [videoData.isCoachPOV]);

  const onTransform = useCallback((_, state) => {
    if (videoRef.current) {
      const width = videoRef.current.clientWidth;
      const height = videoRef.current.clientHeight;
      // Calculate normalized positions
      const normalizedX = state.positionX / width;
      const normalizedY = state.positionY / height;

      setCurrentTransformation({
        positionX: normalizedX,
        positionY: normalizedY,
        scale: state.scale,
      });
    }
  }, []);

  return (
    <div
      className={
        videoData.isFs
          ? 'video-panel fs'
          : `video-panel ${tabs[videoData.tabIndex]}`
      }
    >
      <div className="pov-video-player-container" ref={containerRef}>
        {!isVideoLoaded &&
          (showConversionBlock ? (
            // <ConversionBlock />
            <div className="convert-button">
              <p>無法下載視頻...</p>
            </div>
          ) : (
            <div className="convert-button">
              <p>視頻下載中...</p>
            </div>
          ))}
        <TransformWrapper
          disablePadding={true}
          ref={zoomPanRef}
          onTransformed={onTransform}
          doubleClick={{ disabled: true }}
        >
          <TransformComponent>
            <EnhancedVideoPlayer2
              videoRef={videoRef}
              setVideoData={setVideoData}
              dpOL={dpOL}
              matchId={matchData.matchId}
              videoData={videoData}
              setShowControls={setShowControls}
            />
          </TransformComponent>
        </TransformWrapper>
        {videoData.isCoachPOV && renderHighlightNotice()}
        {dpOL === false && renderOverlay()}
        {renderVideoControl()}
        {renderVideoBar()}
      </div>
    </div>
  );
};

export default VideoPlayer;
